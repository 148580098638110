import React, { useEffect, useState } from "react";
import { RefreshTimerProps } from "components/atoms/RefreshTimer/types";
import { Renew16 } from "../IconsCreateFactory";
import { useTrans } from "system/translations/hooks";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";

const RefreshTimer: React.FC<RefreshTimerProps> = ({ startDate, onClick }) => {
  const { _t } = useTrans();
  const [timeFrom, setTimeFrom] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      if (startDate) {
        const val = formatDistanceToNowStrict(fromUnixTime(startDate / 1000), {
          addSuffix: false,
        });
        setTimeFrom(val);
      }
    }, 1000);
    if (!startDate) {
      setTimeFrom("");
    }

    return () => {
      clearInterval(interval);
    };
  }, [startDate]);
  if (!timeFrom) {
    return null;
  }

  return (
    <>
      {_t("last_updated_{time}_at", {
        time: timeFrom,
      })}
      <Renew16 onClick={onClick} />
    </>
  );
};

export default RefreshTimer;
