import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_MERCHANT_CREATE_PAYMENT_DEPOSIT_METHOD,
  API_MERCHANT_CREATE_PAYMENT_WITHDRAWAL_METHOD,
  API_MERCHANT_AVAILABLE_DEPOSIT_METHODS,
  API_MERCHANT_AVAILABLE_WITHDRAWAL_METHODS,
  API_MERCHANT_PAYMENT_METHODS,
  API_MERCHANT_PAYMENT_WITHDRAWAL_METHODS,
  API_MERCHANT_EDIT_PAYMENT_DEPOSIT_METHOD,
  API_MERCHANT_EDIT_PAYMENT_WITHDRAWAL_METHOD,
  API_MERCHANT_PAYMENT_METHOD_BY_ID,
  API_MERCHANT_PAYMENT_WITHDRAWAL_METHOD_BY_ID,
  API_MERCHANT_GET_PAYMENT_METHOD_CREDENTIALS,
  API_MERCHANT_DELETE_PAYMENT_DEPOSIT_METHOD,
  API_MERCHANT_DELETE_PAYMENT_WITHDRAWAL_METHOD,
  API_MERCHANT_GET_PAYMENT_METHOD_PROVIDERS,
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  AvailableMethodsDataType,
  PaymentMethodCredentialDataType,
  PaymentMethodsDataByIdType,
  PaymentMethodsDataType,
  ProvidersDataType,
} from "modules/merchantPayments/types";

const getMerchantPaymentMethods = "getMerchantPaymentMethods";
const getMerchantPaymentMethodById = "getMerchantPaymentMethodById";
const getMerchantPaymentWithdrawalMethods =
  "getMerchantPaymentWithdrawalMethods";
const getMerchantAvailableDepositMethods = "getMerchantAvailableDepositMethods";
const getMerchantAvailableWithdrawalMethods =
  "getMerchantAvailableWithdrawalMethods";

export const useGetMerchantPaymentMethods = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantPaymentMethods, id],
    (): Promise<PaymentMethodsDataType> =>
      apiRequest({
        url: generatePath(API_MERCHANT_PAYMENT_METHODS, { id: `${id}` }),
        method: "get",
      })
  );
};
export const useGetMerchantPaymentMethodById = ({
  merchantId,
  id,
  paymentType,
}: {
  merchantId: number;
  id: number;
  paymentType: string;
}) => {
  const [apiRequest] = useApiRequest();
  const url =
    paymentType === "deposit"
      ? generatePath(API_MERCHANT_PAYMENT_METHOD_BY_ID, {
          merchantId: `${merchantId}`,
          id: `${id}`,
        })
      : generatePath(API_MERCHANT_PAYMENT_WITHDRAWAL_METHOD_BY_ID, {
          merchantId: `${merchantId}`,
          id: `${id}`,
        });
  return useQuery(
    [getMerchantPaymentMethodById, id, merchantId, paymentType],
    (): Promise<PaymentMethodsDataByIdType> =>
      apiRequest({
        url: url,
        method: "get",
      })
  );
};
export const useGetMerchantProvidersById = ({
  paymentId,
}: {
  paymentId: any;
}) => {
  const [apiRequest] = useApiRequest();
  const url =
    paymentId &&
    generatePath(API_MERCHANT_GET_PAYMENT_METHOD_PROVIDERS, {
      paymentId,
    });
  return useQuery(
    [getMerchantPaymentMethodById, paymentId],
    (): Promise<ProvidersDataType> =>
      apiRequest({
        url: url,
        method: "get",
      }),
    {
      enabled: !!paymentId,
    }
  );
};
export const useGetMerchantPaymentWithdrawalMethods = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantPaymentWithdrawalMethods, id],
    (): Promise<PaymentMethodsDataType> =>
      apiRequest({
        url: generatePath(API_MERCHANT_PAYMENT_WITHDRAWAL_METHODS, { id: `${id}` }),
        method: "get",
      })
  );
};
export const useGetMerchantAvailableDepositMethods = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantAvailableDepositMethods, id],
    (): Promise<AvailableMethodsDataType> =>
      apiRequest({
        url: generatePath(API_MERCHANT_AVAILABLE_DEPOSIT_METHODS, { id: `${id}` }),
        method: "get",
      })
  );
};
export const useGetMerchantAvailableWithdrawalMethods = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantAvailableWithdrawalMethods, id],
    (): Promise<AvailableMethodsDataType> =>
      apiRequest({
        url: generatePath(API_MERCHANT_AVAILABLE_WITHDRAWAL_METHODS, { id: `${id}` }),
        method: "get",
      })
  );
};

export const useMerchantCreatePaymentMethod = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => {
      const { paymentType, merchantId, ...resData } = data;
      return apiRequest({
        url:
          paymentType === "deposit"
            ? generatePath(API_MERCHANT_CREATE_PAYMENT_DEPOSIT_METHOD, {
                merchantId,
              })
            : generatePath(API_MERCHANT_CREATE_PAYMENT_WITHDRAWAL_METHOD, {
                merchantId,
              }),
        method: "post",
        data: resData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPaymentMethods);
        queryClient.invalidateQueries(getMerchantPaymentWithdrawalMethods);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useMerchantEditPaymentMethod = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => {
      const { paymentType, merchantId, id, ...resData } = data;
      return apiRequest({
        url:
          paymentType === "deposit"
            ? generatePath(API_MERCHANT_EDIT_PAYMENT_DEPOSIT_METHOD, {
                merchantId,
                id,
              })
            : generatePath(API_MERCHANT_EDIT_PAYMENT_WITHDRAWAL_METHOD, {
                merchantId,
                id,
              }),
        method: "put",
        data: resData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPaymentMethods);
        queryClient.invalidateQueries(getMerchantPaymentWithdrawalMethods);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useMerchantDeletePaymentMethod = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) => {
      const { paymentType, merchantId, id }: any = data;
      return apiRequest({
        url:
          paymentType === "deposit"
            ? generatePath(API_MERCHANT_DELETE_PAYMENT_DEPOSIT_METHOD, {
                merchantId,
                id,
              })
            : generatePath(API_MERCHANT_DELETE_PAYMENT_WITHDRAWAL_METHOD, {
                merchantId,
                id,
              }),
        method: "delete",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPaymentMethods);
        queryClient.invalidateQueries(getMerchantPaymentWithdrawalMethods);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useGetPaymentMethodCredentials = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  return useMutation(
    (methodId: number): Promise<PaymentMethodCredentialDataType> => {
      return apiRequest({
        url: generatePath(API_MERCHANT_GET_PAYMENT_METHOD_CREDENTIALS, {
          methodId: `${methodId}`
        }),
        method: "get",
      });
    },
    {
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
