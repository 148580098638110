import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useGetPaymentGatewaysCodes } from "modules/payment/apiHooks";
import {
  NewStatusConnectionType,
  PartialNormalizedNewStatusConnectionType,
  StatusCallbackResponsePullCreateUpdateItemType,
  StatusCallbackResponsePullExpandType,
  StatusCallbackResponsePullItemType,
  StatusConnectionConfigurationFieldsNames,
  StatusConnectionItemType,
  StatusConnectionItemWithStatusesType,
  StatusName,
  UseModuleDataRes
} from "./types";
import { useGetStatusReasons } from "modules/statusReasons/apiHooks";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { Checkmark16 } from "components/atoms/IconsCreateFactory";
import Btn from "components/atoms/Btn";
import ConfirmModalBody from "components/atoms/ConfirmModalBody";

export const useModuleData = (): UseModuleDataRes => {
  const { data: paymentGateways = [] } = useGetPaymentGatewaysCodes();
  const { data: statusReasons = [] } = useGetStatusReasons();

  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "code",
    valueKey: "code",
  });

  const statusReasonsOptions = statusReasons.map((item) => ({
    label: `${item.code} ( ${item.title} )`,
    value: item,
  }));


  return { paymentGatewaysOptions, statusReasonsOptions };
};

export const useGetHelperFunctions = () => {
  const getExpandData = (data: StatusConnectionItemType[] | undefined): StatusConnectionItemWithStatusesType[] => {
    if (!data) {
      return []
    }
    let statuses: NewStatusConnectionType[] = [];
    const addStatus = (initialStatus: NewStatusConnectionType[], statusName: StatusName) => {
      initialStatus.forEach(item => {
        item.statusName = statusName;
        const {
          statusReasonId = null,
          statusReasonCode = null,
          statusReasonTitle = null,
          statusReasonDescription = null,
          statusReasonIsCascadingEnabled = null
        } = item

        item.statusReasonOption = {
          id: statusReasonId,
          code: statusReasonCode,
          title: statusReasonTitle,
          description: statusReasonDescription,
          cascadingEnabled: statusReasonIsCascadingEnabled,
        }
        statuses.push(item);
      });
    };

    return data.map((item) => {
      statuses = []
      addStatus(item.pull, "pull")
      addStatus(item.callback, "callback")
      addStatus(item.response, "response")

      return {
        ...item,
        statuses: statuses
      }

    })
  }

  const sortStatusesIntoUpdateCreateData = (data: StatusConnectionItemType, statuses: NewStatusConnectionType[]) => {
    const normalizeData: StatusConnectionItemType = {
      ...data,
      callback: [],
      pull: [],
      response: [],
    }
    
    statuses.forEach((status) => {
      if (!status.statusName) {
        return
      }
      const statusName = status.statusName
      const {
        id,
        code,
        title,
        description,
        cascadingEnabled,
      } = status.statusReasonOption || {};

      const normalizeStatus: PartialNormalizedNewStatusConnectionType = {
        ...status,
        statusReasonId: id,
        statusReasonCode: code,
        statusReasonTitle: title,
        statusReasonDescription: description,
        statusReasonIsCascadingEnabled: cascadingEnabled,
      };

      delete normalizeStatus.statusName
      delete normalizeStatus.statusReasonOption;

      normalizeData[statusName].push(normalizeStatus as NewStatusConnectionType)
    })

    return normalizeData
  }

  return { getExpandData, sortStatusesIntoUpdateCreateData }
}

export const useGetCheckNewStatusConnections = () => {
  const { _t } = useTrans()
  const { showModal, hideModal } = useModal()

  const fieldNamesStatusConnection: StatusConnectionConfigurationFieldsNames = {
    statusName: _t("status"),
    codePriority: _t("code_priority"),
    errorValue: _t("error_value"),
    sequenceNumber: _t("sequence_number"),
    statusReasonOption: _t("status_reason"),
    errorDetailKey: _t("error_detail_key"),
  }

  const openWarningForNotFilledFields = ({
    hasStatusConnectionItem,
    newStatusConnectionItem,
}: {
    hasStatusConnectionItem: boolean;
    newStatusConnectionItem: NewStatusConnectionType;
}) => {
    const getModalConfig = (text: string) => ({
        modalHeading: _t('warning'),
        isShowScale: false,
        component: ConfirmModalBody,
        size: 'sm',
        smallModal: true,
        componentProps: { text },
        footer: Btn,
        footerProps: {
            onClick: () => hideModal(),
            renderIcon: Checkmark16,
            hasIconOnly: true,
        },
    });

    if (hasStatusConnectionItem) {
        const lostFields = (Object.keys(newStatusConnectionItem) as (keyof NewStatusConnectionType)[])
            .filter((key) => !newStatusConnectionItem[key])
            .map((item) => fieldNamesStatusConnection[item as keyof StatusConnectionConfigurationFieldsNames] || '')
            .join(', ');

        showModal(getModalConfig(_t("status_connection_not_filled_fields_warning_{lostFields}", { lostFields })));
    } else {
        showModal(getModalConfig(_t('to_save_status_connection_add_configuration_settings')));
    }
};

  const checkNewStatusConnectionForUpdate = (
    {
      newStatusConnectionItem,
      onOk
    }: {
      newStatusConnectionItem: NewStatusConnectionType, 
      onOk : () => void
    }) => {

      const hasStatusConnectionItem: boolean= !!newStatusConnectionItem
      if (hasStatusConnectionItem && Object.values(newStatusConnectionItem).every((item: any) => item != null)) {
        onOk()
      } else {
        openWarningForNotFilledFields({
          newStatusConnectionItem,
          hasStatusConnectionItem
        })
      }
    }

  return { checkNewStatusConnectionForUpdate }
}
