import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditOrchestratorValidation = (
  _t: TransType,
) => {
  return Yup.object().shape({
    ruleName: Yup.string().required(_t("required")),
    ruleType: Yup.string().required(_t("required")),
    merchant: Yup.object().required(_t("required")),
    paymentMethod: Yup.string().required(_t("required")),
    templateType: Yup.mixed().when('ruleType', {
      is: (ruleType: any) => ruleType === 'onSubmit',
      then: (schema) => Yup.string().required(_t("required")),
    }),
  });
};

export const createEditAttributeValidation = (
  _t: TransType,
  type: string,
) => {
  const timeFormat = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/
  switch (type) {
    case 'condition':
      return Yup.object().shape({
        property: Yup.string().required(_t("required")),
        operator: Yup.string().required(_t("required")),
        value: Yup.mixed().when('property', {
          is: (property: string) => property === 'transactionAmount',
          then: (schema) => Yup.mixed().when('operator', {
            is: (operator: string)  => operator === 'between',
            then: (schema) => Yup.array()
              .of(Yup.string()
              .test(
                'is-strict-integer', 
                _t('value_must_be_integer'), 
                (value: any) => !value?.includes('.') || !value)
              .test(
                'is-strict-integer', 
                _t("must_be_a_number"), 
                (value: any) => /^\d*\.?\d+$/.test(value) || !value)
              .typeError(_t("must_be_a_number")).required(_t("required")))
              .test(
                'amount-order', 
                _t('first_amount_must_be_less_than_second'), 
                (array: any) => {
                  if (array?.[0] && array?.[1]) {
                    return (array[1] - array[0]) > 0;
                  }
                  return true;
              }),
            otherwise: (schema) => Yup.string()
            .test(
              'is-strict-integer', 
              _t('value_must_be_integer'), 
              (value: any) => !value?.includes('.') || !value)
            .test(
              'is-strict-integer', 
              _t("must_be_a_number"), 
              (value: any) => /^\d*\.?\d+$/.test(value) || !value)
            .typeError(_t("must_be_a_number")).required(_t("required"))
          }),
        }).when('property', {
          is: (property: string) => property === 'cardNetwork',
          then: (schema) => Yup.array().min(1).required(_t("required")),
        }).when('property', {
          is: (property: string) => property === 'cardBIN',
          then: (schema) => Yup.mixed().when('operator', {
            is: (operator: string) => operator === 'notin' || operator === 'in',
            then: (schema) => Yup.array().of(Yup.string()
              .test(
                'is-6-digit-string',
                 _t('value_must_be_6_digit_number'),
                 (value: any) => /^\d{6}$/.test(value)
              )
              .typeError(_t("must_be_a_number")).required(_t("required")))
              .test(
                'bin-order', 
                _t('first_amount_must_be_less_than_second'), 
                (array: any) => {
                  if (array?.[0] && array?.[1]) {
                    return (array[1] - array[0]) > 0;
                  }
                  return true;
              }),
              otherwise: (schema) => Yup.string()
                .test(
                  'is-6-digit-string',
                  _t('value_must_be_6_digit_number'),
                  (value: any) => /^\d{6}$/.test(value)
                )
                .typeError(_t("must_be_a_number"))
                .required(_t("required")),
          }),
        })
        .when('property', {
          is: (property: string) => property === 'utcTime',
          then: (schema) => Yup.mixed().when('operator', {
            is: (operator: string) => operator === 'between',
            then: (schema) => Yup.array()
              .of(Yup.string()
                .matches(timeFormat, _t('time_must_be_in_format_HH_MM'))
                .test(
                  'is-empty-time', 
                  _t("required"),
                  (item: any) => !!item
                )
              ).test(
                'time-order', 
                _t('first_time_must_be_earliert_than_second'), 
                (array: any) => {
                  if (array?.[0] && array?.[1]) {
                    const [time1, time2] = array;
                    const date1 = new Date(`2021-01-01T${time1}:00`);
                    const date2 = new Date(`2021-01-01T${time2}:00`);
                    return date1 < date2;
                  }
                  return false;
                })
              .required(_t("required")),
            otherwise: (schema) => Yup.string()
              .required(_t("required"))
              .matches(
                timeFormat,
                _t('time_must_be_in_format_HH_MM')
              ).required(_t("required"))
          })
        }).when('property', {
          is: (property: any) => property === "cardFundingType",
          then: (schema) => Yup.mixed().when('operator', {
            is:(operator: any) => operator === 'notin' || operator === 'in',
            then: (schema) => Yup.array().of(Yup.string().required(_t("required"))).min(1, _t('required')).required((_t("required")))
          }).required(_t("required"))
        }).required(_t("required"))
      });
    case 'strategy':
      return Yup.object().shape({
        strategyType: Yup.string().required(_t("required")),
        depositSettings: Yup.array().when('strategyType', {
          is: (strategyType: any) => strategyType === "direct" || strategyType ===  "priority",
          then: (schema) => Yup.array().of(Yup.object().shape({
            paymentMethod: Yup.string().required(_t("required")),
            merchantPaymentMethod: Yup.string().required(_t("required")),
          })),
        }).when('strategyType', {
          is: (strategyType: string) => strategyType === "weight",
          then: (schema) => Yup.array().of(Yup.object().shape({
            paymentMethod: Yup.string().required(_t("required")),
            merchantPaymentMethod: Yup.string().required(_t("required")),
            paymentMethodSettings: Yup.object().shape({
              weightValue: Yup.number().nullable().required(_t("required")).typeError(_t("must_be_a_number"))
            })
          })),
        })
      });
    case 'conditionGroup':
    return Yup.object().shape({
        conditions: Yup.array().of(
          Yup.object().shape({
            property: Yup.string().required(_t("required")),
            operator: Yup.string().required(_t("required")),
            value: Yup.mixed().when('property', {
              is: (property: string) => property === 'transactionAmount',
              then: (schema) =>  Yup.mixed().when('operator', {
                is:  (operator: string) => operator === 'between',
                then: (schema) => Yup.array()
                  .of(Yup.string()
                  .test(
                    'is-strict-integer', 
                    _t('value_must_be_integer'), 
                    (value: any) => !value?.includes('.') || !value)
                  .test(
                    'is-strict-integer', 
                    _t("must_be_a_number"), 
                    (value: any) => /^\d*\.?\d+$/.test(value) || !value)
                  .typeError(_t("must_be_a_number")).required(_t("required")))
                  .test(
                    'amount-order', 
                    _t('first_amount_must_be_less_than_second'), 
                    (array: any) => {
                      if (array?.[0] && array?.[1]) {
                        return (array[1] - array[0]) > 0;
                      }
                      return true;
                  }),
                otherwise: (schema) => Yup.string()
                .test(
                  'is-strict-integer', 
                  _t('value_must_be_integer'), 
                  (value: any) => !value?.includes('.') || !value)
                .test(
                  'is-strict-integer', 
                  _t("must_be_a_number"), 
                  (value: any) => /^\d*\.?\d+$/.test(value) || !value)
                .typeError(_t("must_be_a_number")).required(_t("required"))
              }),
            }).when('property', {
              is: (property: string) => property === 'cardNetwork',
              then: (schema) => Yup.array().min(1).required(_t("required")),
            })
            .when('property', {
              is: (property: string) => property === 'cardBIN',
              then: (schema) => Yup.number().typeError(_t("must_be_a_number")).min(100000, _t('value_must_be_6_digit_number')).max(999999,  _t('value_must_be_6_digit_number')).required(_t("required")),
            })
            .when('property', {
              is: (property: string) => property === 'utcTime',
              then: (schema) => Yup.mixed().when('operator', {
                is:  (operator: string) => operator === 'between',
                then: (schema) => Yup.array()
                  .of(Yup.string()
                    .matches(timeFormat, _t('time_must_be_in_format_HH_MM'))
                    .test(
                      'is-empty-time', 
                      _t("required"),
                      (item: any) => !!item)
                  ).test(
                    'time-order', 
                    _t('first_time_must_be_earliert_than_second'), 
                    (array: any) => {
                      if (array?.[0] && array?.[1]) {
                        const [time1, time2] = array;
                        const date1 = new Date(`2021-01-01T${time1}:00`);
                        const date2 = new Date(`2021-01-01T${time2}:00`);
                        return date1 < date2;
                      }
                      return false;
                    })
                  .required(_t("required")),
                otherwise: (schema) => Yup.string()
                  .required(_t("required"))
                  .matches(
                    timeFormat,
                    _t('time_must_be_in_format_HH_MM')
                  ).required(_t("required"))
              })
            })
            .when('property', {
              is: (property: any) => property === "cardFundingType",
              then: (schema) => Yup.mixed().when('operator', {
                is:(operator: any) => operator === 'notin' || operator === 'in',
                then: (schema) => Yup.array().of(Yup.string().required(_t("required"))).min(1, _t('required')).required((_t("required")))
              }).required(_t("required"))
            }).required(_t("required"))
          })
        )
      })
    default:
      return [];
  }
}
