import React from "react";
import { useTrans } from "system/translations/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { MenuBottomProps } from "./types";
import {
  Menu16
} from "components/atoms/IconsCreateFactory"
import { path } from "system/helpers/pathHelper";
import { useLocation } from "react-router-dom";

import "styles/modules/mobileMenu/menuBottom.scss"

const MenuBottom: React.FC<MenuBottomProps> = ({
  bottomMenuItems,
  linkHandler,
  openFullScreenMenu
}) => {
  
  const { _t } = useTrans();
  const menuItemWidth = `${100/(bottomMenuItems.length + 1)}%`
  const location = useLocation()

  const checkMatch = (url: string) => {
    const route = location?.pathname + location.search;
    return (route === url || (route === "/" && url === path("/:lang/")));
  }

  return (
    <div className="mobile-menu-bottom">
      {bottomMenuItems.map((item, index) => (
        <RoleWrapper key={index} accessTo={item.accessTo}>
          <div
            style = {{width: `${menuItemWidth}`}}
            onClick = {() => linkHandler(item.link)}
          >
            <item.mobileIcon
              color = {checkMatch(item.link) ? "#0077D4" : ""}
            />
            <span
              style = {{color: checkMatch(item.link) ? "#0077D4" : ""}}
            >
              {item.title}
            </span>      
          </div>
        </RoleWrapper>
      ))}
      <div
        style = {{
          width: `${menuItemWidth}`,
        }}
        onClick= {()=> openFullScreenMenu()}
      >
        <Menu16/>
        <span>{_t("menu")}</span>      
      </div>
    </div>
  )
}

export default MenuBottom;