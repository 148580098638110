import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useExpanded,
  useColumnOrder,
  useResizeColumns,
} from "react-table";
import { ReactTableProps } from "./types";
import { Pagination } from "carbon-components-react";
import { defaultPageSize, defaultPageSizes } from "./constants";
import Loader from "components/atoms/Loader";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import classNames from "classnames";
import NoData from "components/atoms/NoData";
import { useTrans } from "system/translations/hooks";
import { ChevronDown16, ChevronUp16 } from "components/atoms/IconsCreateFactory"
import ColumnSettingsMenu from "./ColumnSettings";

const Table: React.FC<ReactTableProps> = ({
  columns,
  data = [],
  pageSize = defaultPageSize,
  pageSizes = defaultPageSizes,
  isPagination = false,
  isLoading,
  onPagination,
  onSort,
  pageIndex = 0,
  filters,
  rowSubComponent: SubComponent,
  cellProps,
  totalRecords = 0,
  tableName = "",
  isColumnSettings = false,
  hiddenColumnsbyDefault = [],
  groupBy = {},
  isMobileExpand = false,
  expandColumns = [],
  sudComponentDataKeySelector = ""
}) => {
  const { _t } = useTrans();
  const tableHeadRef: any = useRef(null);
  const tableRef: any = useRef(null);
  const tableBodyRef: any = useRef(null);
  const periodRef: any = useRef([]);
  const periodRowRef: any = useRef([]);
  const [rowId, setRowId] = useState(0);
  const isMobile = window.matchMedia('(max-width: 671px)').matches;
  const [showColumnSetting, setShowColumnSetting] = useState(false);

  const getTableName = () => {
    const currentLocationArray = window.location.pathname.split("/")
    return !currentLocationArray.some(item => item)
      ? "transactions"
      : (currentLocationArray[currentLocationArray.length - 1]
        ? currentLocationArray[currentLocationArray.length - 1]
        : currentLocationArray[currentLocationArray.length - 2]
      )
  }

  const getStoragedData = (itemName: string, defaultValue: any = {}) => {
    return localStorage.getItem(itemName)
      ? JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem(itemName))))
      : defaultValue;
  };

  const currentTable = useMemo(() => getTableName(), []);

  const updateStorageData = (newStorageData: any, storageKey: string) => {
    const storagedData = getStoragedData(storageKey);
    const result = {
      ...storagedData,
      [currentTable]: newStorageData
    };
    localStorage.setItem(storageKey, JSON.stringify(result));
  }

  const useControlledState = (state: any) => {
    const hiddenColumns = state.hiddenColumns;
    const columnWidths = state.columnResizing.columnWidths;
    const columnOrder = state.columnOrder
    !isLoading && updateStorageData(columnWidths, "cachedColumnWidths");
    !isLoading && updateStorageData(hiddenColumns, "hiddenColumns");
    !isLoading && updateStorageData(columnOrder, "columnOrder");
    useMemo(() => {
      onSort && onSort(state.sortBy);
    }, [state.sortBy]);
    return state;
  };

  const getTableStoragedData = (storageKey: string, defValue: any) =>
    getStoragedData(storageKey)?.[currentTable]
      ? getStoragedData(storageKey)?.[currentTable]
      : defValue;

  const initialState: any = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    columnOrder: getTableStoragedData("columnOrder", []),
    columnResizing: {
      columnWidths: getTableStoragedData("cachedColumnWidths", {})
    },
    hiddenColumns: getTableStoragedData("hiddenColumns", hiddenColumnsbyDefault)
  };
  /*  if (isPagination) {
    initialState.pageSize = pageSize;
  }*/

  let useTableOptions = {
    columns: columns,
    data,
    initialState: initialState,
    manualPagination: !!onPagination,
    /*    pageCount: Math.ceil(totalRecords / pageSize) + 1,*/
    manualSortBy: !!onSort,
    /*   onPagination: !!onPagination,*/
    useControlledState,
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageCount,
    prepareRow,
    state,
    gotoPage,
    setPageSize,
    setAllFilters,
    totalColumnsWidth,
    setColumnOrder,
    allColumns,
    visibleColumns,
    toggleHideColumn,
    setSortBy
  } = useTable(
    useTableOptions,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useColumnOrder,
    useResizeColumns
  );
  
  const onPageChange = (e: any) => {
    if (onPagination) {
      onPagination({ page: e.page, perPage: e.pageSize });
      setPageSize(e.pageSize);
    } else {
      gotoPage(e.page - 1);
      setPageSize(e.pageSize);
    }
  };

  useEffect(() => {
    filters && Array.isArray(filters) && setAllFilters(filters);
  }, [filters]);
  const onExpand = (event: any, row: any) => {
    const toggleRowExpandedProps = row.getToggleRowExpandedProps();
    toggleRowExpandedProps.onClick(event);
  };
  const renderRowSubComponent = React.useCallback(({ row }: { row: any} ) => {
    const props = tableName === 'TableWithSubComponentTable'
      ? expandColumns.length > 0 && !!sudComponentDataKeySelector
        ? { data: row?.original?.[sudComponentDataKeySelector] || [], columns: expandColumns }   
        : { data: [row?.original ] } 
      : { rowData: row?.original || {} }

    const Component: any = React.cloneElement(SubComponent, props);
    return SubComponent ? Component : "No sub component";
  }, []);
  const selectedPage = !!onPagination ? state.pageIndex : state.pageIndex + 1;

  useEffect(() => {
    if (tableRef?.current && data) {
      document.addEventListener("scroll", scrollHandler);
    }
  }, [tableRef?.current]);
  useEffect(
    () => () => {
      document.removeEventListener("scroll", scrollHandler);
    },
    []
  );

  const scrollHandler = () => {
    const headTop = tableRef?.current?.getBoundingClientRect().top;
    if (tableHeadRef?.current?.style && headTop < -5) {
      tableHeadRef.current.style.position = "absolute";
      tableHeadRef.current.style.top = `${Math.abs(headTop) + 48}px`;
    } else if (tableHeadRef?.current?.style) {
      tableHeadRef.current.style.position = "static";
    }

    for (const ref of periodRowRef.current) {
      if (ref) {
        const headTopPeriod = ref.getBoundingClientRect().top;
        
        if (headTopPeriod <= 96 && headTop < -5) {
          ref.style.position = "absolute";
          ref.style.top = `${Math.abs(headTop) + 96}px`
          ref.style.width = `${tableHeadRef?.current?.getBoundingClientRect().width}px` ;
        } else if (ref.style.position === 'absolute' ) {
          ref.style.position = "relative";
          ref.style.top = "0px";
          ref.style.width = "100%";
        }
      }
    }   

  };
  const getItemStyle = (isDragging: any, draggableStyle: any) => {
    let styles = {
      ...draggableStyle,
    };
    if (isDragging) {
      styles.background = "lightgrey";
    }
    return styles;
  };
  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    const result: any[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndHandler = (result: any) => {
    const newList = reorder(
      visibleColumns,
      result.source.index,
      result.destination.index
    );
    const listId = newList.map((item) => item.id);
    setColumnOrder(listId);
  };

  useEffect(() => {
    if (!!groupBy.setColumnOrderGroupBy) {
      groupBy.setColumnOrderGroupBy(setColumnOrder, setSortBy)
    }
    
  }, [groupBy])

  const columnHider = (id: any, value: any) => {
    toggleHideColumn(id, !value);
  };
  if (!data?.length && !isLoading) {
    return <NoData text={_t("no_data_text")} />;
  };

  const onScrollBodyTable = () => {
    for (const ref of periodRef.current) {
      if (ref && tableBodyRef) {
        ref.style.left = 16 + tableBodyRef.current?.scrollLeft + 'px'
      }
    }   
    
   }

  return (
    <div style={{position: 'relative'}}>
      {/*      <div style={{ height: "310px" }}>
        <DragDropContext onDragEnd={onDragEndHandler}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                role="menu"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {allColumns?.map((item: any, index) => (
                  <Draggable
                    draggableId={item.id}
                    key={item.id + index}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "20px 1fr",
                            alignItems: "center",
                          }}
                        >
                          <div onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                              id={item.id}
                              value={item.isVisible}
                              onChange={(e) => hidder(item.id, e)}
                            />
                          </div>

                          {item.Header}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>*/}
      {!isMobile && <div style={isColumnSettings 
        ? { position: "absolute", right: 0, zIndex: 2 }
        : { position: "absolute", left: '292px', zIndex: 2}
        }
      >
        <ColumnSettingsMenu
          onDragEndHandler={onDragEndHandler}
          allColumns={allColumns}
          getItemStyle={getItemStyle}
          columnHider={columnHider}
          isColumnSettings={isColumnSettings}
          showColumnSetting={showColumnSetting}
          setShowColumnSetting={setShowColumnSetting}
        />
      </div>}
      <div className="ReactTable" ref={tableRef}>
        <div {...getTableProps()} className={"rt-table"} role="grid" ref={tableBodyRef} onScroll={onScrollBodyTable}>
          <div className="header__container">
          <div
            ref={tableHeadRef}
            className="rt-thead -header"
            style={{ minWidth: totalColumnsWidth, width: "100%" }}
          >
            <DragDropContext onDragEnd={onDragEndHandler}>
              <Droppable droppableId="list" direction="horizontal">
                {(provided, snap) => (
                  <div
                    ref={provided.innerRef}
                    className={"rt-tr-group"}
                    {...provided.droppableProps}
                  >
                    {headerGroups.map((headerGroup: any) => {
                        const processedHeaders = headerGroup.headers.filter(
                          (column: any) => column.id !== "ColumnSettingsMenu"
                        );
                        const columnSettingsMenu = headerGroup.headers.find(
                          (column: any) => column.id === "ColumnSettingsMenu"
                        );

                        if (columnSettingsMenu) {
                          processedHeaders.push(columnSettingsMenu);
                        }
                      return (
                      <div
                        key={"headerGroup_" + headerGroup.id}
                        role="row"
                        {...headerGroup.getHeaderGroupProps()}
                        className={"rt-tr"}
                      >
                        {processedHeaders.map((column: any, idx: any) => {
                          const styles: any = {
                            width: column.totalWidth,
                          };
                          if (!snap.isDraggingOver) {
                            styles.maxWidth =
                              column.maxWidth || column.totalWidth;
                            styles.flex = `${column.totalWidth} 0 auto`;
                            styles.minWidth =
                              column.minWidth || column.totalWidth;
                          }
                          if (column.id === "ColumnSettingsMenu") {
                            return (
                              <div style={{ position: "relative" }}>
                                <div
                                  onClick={() => setShowColumnSetting(!showColumnSetting)}
                                  style={{
                                    zIndex: 5,
                                    cursor: "pointer",
                                    padding: "10px",
                                    fontSize: "20px"
                                  }}
                                >
                                  
                                  ...
                                </div>
                              </div>
                            )
                          }
                          return (
                            <div style={{ position: "relative", ...styles }}>
                              <Draggable
                                draggableId={column.id}
                                key={column.id + idx}
                                index={idx}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div
                                      key={"th_" + column?.Header + idx}
                                      role="columnheader"
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                      )}
                                      className={
                                        "rt-th rt-resizable-header -cursor-pointer"
                                      }
                                    >
                                      {/*     {column.render("Header")}*/}
                                      <div
                                        aria-describedby="table-sort-19"
                                        style={{ background: "transparent" }}
                                        className={classNames(
                                          "bx--table-sort",
                                          column.isSorted
                                            ? column.isSortedDesc
                                              ? "bx--table-sort--active bx--table-sort--ascending"
                                              : "bx--table-sort--active"
                                            : ""
                                        )}
                                      >
                                        <>
                                          <span>{column.render("Header")}</span>
                                          {column.canSort ? (
                                            <>
                                              <svg
                                                focusable="false"
                                                preserveAspectRatio="xMidYMid meet"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 32 32"
                                                aria-hidden="true"
                                                className="bx--table-sort__icon"
                                              >
                                                <path d="M16 4L6 14 7.41 15.41 15 7.83 15 28 17 28 17 7.83 24.59 15.41 26 14 16 4z"></path>
                                              </svg>
                                              <svg
                                                focusable="false"
                                                preserveAspectRatio="xMidYMid meet"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 32 32"
                                                aria-hidden="true"
                                                className="bx--table-sort__icon-unsorted"
                                              >
                                                <path d="M27.6 20.6L24 24.2 24 4 22 4 22 24.2 18.4 20.6 17 22 23 28 29 22zM9 4L3 10 4.4 11.4 8 7.8 8 28 10 28 10 7.8 13.6 11.4 15 10z"></path>
                                              </svg>
                                            </>
                                          ) : null}
                                        </>
                                      </div>
                                      {/* <span className="p-column-resizer p-clickable" />*/}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                              <div
                                key={"resizer" + idx}
                                {...column.getResizerProps()}
                                className={`rt-resizer ${column.isResizing ? "isResizing" : ""
                                  }`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )})}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/*            {headerGroups.map((headerGroup: any) => (
              <div
                key={"headerGroup_" + headerGroup.id}
                role="row"
                {...headerGroup.getHeaderGroupProps()}
                className={"rt-tr"}
              >
                {console.log("headerGroup", headerGroup.getHeaderGroupProps())}
                {headerGroup.headers.map((column: any, idx: any) => {
                  const styles = {
                    minWidth: column.width,
                    flex: `${column.width} 0 auto`,
                    width: column.width,
                    maxWidth: column.width,
                  };
                  return (
                    <div
                      key={"th_" + column?.Header + idx}
                      role="columnheader"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={styles}
                      className={"rt-th rt-resizable-header"}
                    >
                      {column.render("Header")}
                      <div
                        key={"resizer" + idx}
                        {...column.getResizerProps()}
                        className={`rt-resizer ${
                          column.isResizing ? "isResizing" : ""
                        }`}
                      />
                       <span className="p-column-resizer p-clickable" />
                    </div>
                  );
                })}
              </div>
            ))}*/}
          </div>
          </div>
          <div
            className="rt-tbody"
            {...getTableBodyProps()}
            style={{ minWidth: totalColumnsWidth }}
          >
            {isLoading && <Loader formOverlay bgOpacity={50} />}
            {page.map((row: any, idx) => {
              prepareRow(row);
              const style = {
                borderLeft: row.id === rowId ? "1px solid #0077D4" : "0px",
              };
              
              return (
                <>
                {row.original.movement === "Opening balance" && 
                  <div className="rt-tr-row-period__container">
                    <div className="rt-tr-row-period" ref={el => periodRowRef.current[row.original.refIdx] = el}>
                    <div className="rt-tr-row-period-text-container" ref={el => periodRef.current[row.original.refIdx] = el}>
                      {_t('period_from')} {row.original?.periodDateFrom} — {_t('to')} {row.original?.periodDateTo} 
                      <div className={classNames(row.original?.isClosed ? "rt-tr-row-period-closed" : 'rt-tr-row-period-opened')}>{row.original?.isClosed ? _t('closed') : _t('opened')}</div>
                    </div>
                    </div>
                  </div>
                  }
                  <div className="rt-tr-group-wrapper" style={isMobile
                    ? {
                      marginBottom: typeof row.original.requestParams === "string" ? "48px" : "8px",
                      borderLeft: row.isExpanded ? "1px solid #0077D4" : "0px"
                    }
                    : {}}>
                    <div className={"rt-tr-group"} style={isMobile ? style : {}} >
                      <div
                        key={"row_" + row.id + state.pageIndex + idx}
                        role="row"
                        className={"rt-tr -odd"}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell: any, index: number) => {
                          const styles = {
                            minWidth: cell.column.minWidth || cell.column.totalWidth,
                            flex: `${cell.column.totalWidth} 0 auto`,
                            width: cell.column.totalWidth,
                            maxWidth: cell.column.maxWidth || cell.column.totalWidth,
                            display: "flex",
                            /* maxWidth: column.maxWidth,*/
                          };

                          
                          return (isMobile ? cell.column?.mobileVisible : true) && (
                            <div
                              className={classNames("rt-td", {
                                "cell-language-hidden": isMobile && cell.column?.isLanguage && row.id !== rowId,
                                "cell-language-open": isMobile && cell.column?.isLanguage && row.id === rowId,
                                "mobile-hidden": isMobile && cell.column?.isMobileHidden && row.id !== rowId
                                  || isMobile && cell.row.cells.length >= 12 && index > 6 && row.id !== rowId,
                                "mobile-table-title": isMobile && cell.column?.isMobileTitle,
                              })}
                              data-name={cell.column.Header || "dots-menu"}
                              style={isMobile ? {} : styles}
                              role="cell"
                              key={"cell_" + cell.row.values.id}
                              {...cell.getCellProps()}
                            >
                              <div className="column-cell-name">{cell.column.Header}</div>
                              <div className={
                                classNames(
                                  {
                                  "column-cell-text-field": isMobile && !cell.column?.isMobileTitle,
                                  "column-cell-text-full-width": cell.column.id === 'totalFees',
                                  "column-cell-text-restrictions-width": tableName === "MerchantBalanceDetails"
                                  },
                                )
                              }>
                                {cell.render("Cell", {
                                  ...cellProps,
                                  isExpanded: state.expanded[row.id],
                                  onExpand: (e: any) => onExpand(e, row),
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {row.isExpanded ? (
                        <div
                          key={"expanded_" + row.id}
                          role="row"
                          {...row.getRowProps()}
                        >
                          <div
                            className={"expanded-row--wrap"}
                            /*colSpan={row.cells.length + 1}*/
                            role="cell"
                          >
                            {renderRowSubComponent({ row })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {isMobile && (isMobileExpand || row.cells.length >= 12) &&
                      <button
                        onClick={() => {
                          setRowId(row.id === rowId ? 0 : row.id);
                        }}
                        className="open-button">
                        {row.id === rowId ? <ChevronUp16 /> : <ChevronDown16 />}
                      </button>
                    }
                  </div>
                  
                </>
              );
            })}
          </div>
        </div>
        {isPagination && data?.length ? (
          <div className={"bx--pagination__wrap"}>
            <Pagination
              pageSize={state.pageSize}
              pageSizes={pageSizes}
              page={selectedPage}
              totalItems={totalRecords || Math.ceil(pageCount * state.pageSize)}
              onChange={onPageChange}
            />
          </div>
        ) : null}

        {/*        {isPagination && totalRecords && (
          <Paginator
            rows={state.pageSize}
            totalRecords={totalRecords}
            first={state.pageIndex * state.pageSize}
            pageLinkSize={Math.ceil(totalRecords / state.pageSize)}
            rowsPerPageOptions={rowsPerPageOptions}
            currentPageReportTemplate={`Total Records - ${totalRecords}`}
            onPageChange={onPageChange}
            template={
              "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
            }
          />
        )}*/}
      </div>
    </div>
  );
};

export default Table;
