import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { TableColumnType } from "modules/table/types";
import { Link } from "carbon-components-react";
import { ChevronUp16, ChevronDown16 } from "components/atoms/IconsCreateFactory";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("transactionUUID"),
        width: 250,
        id: "transactionUUID",
        accessor: "transactionUUID",
        mobileVisible: true,
        Cell: ({ isExpanded, onExpand, row, showDetails, ...rest }: any) => {
          return (
            <span className={"cell-wrapper"}>
              <span className={"cell-wrapper__accordion"} onClick={onExpand}>
                {isExpanded ? <ChevronUp16 /> : <ChevronDown16 />}
              </span>
              <Link
                onClick={onExpand}
              >
                {row?.original?.transactionUUID}
              </Link>
            </span>
          );
        },
      },
      {
        Header: _t("event_type"),
        width: 250,
        id: "eventType",
        accessor: "eventType",
        mobileVisible: true,
      },
      {
        Header: _t('payment_gateway'),
        accessor: "pgwCode",
        id: "pgwCode",
        width: 200,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        mobileVisible: true,
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        mobileVisible: true,
      },
    ],
    []
  );

  return columns;
};
