import React from "react";
import { useGetUsers, useResetUserPassword } from "./apiHooks";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFEFilter, useFormFilters } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Edit16, Password16, CloseOutline16, Add16, Filter16 } from "components/atoms/IconsCreateFactory"
import { useDeleteUserConfig, useSearchFormConfig } from "modules/users/constantHooks";
import { useTableColumns } from "modules/users/constantHooks";
import { useUpdateUser } from "./apiHooks";
import { getUrl } from "system/helpers/helperFunctions";
import { useModal } from "modules/modal/hooks";
import { Button } from "carbon-components-react";
import { useDeleteUserModal } from "./hooks";

const Users = () => {
  const { data = [], isLoading } = useGetUsers();
  const { mutate: onUserPasswordReset } = useResetUserPassword();
  const history = useHistory();
  const { _t } = useTrans();
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const deleteUserFormConfig = useDeleteUserConfig();
  const { hideModal } = useModal();
  const filterInitialValues = {};
  const { 
    isMobile,
    showFilters,
    mobileSearchFormConfig,
    filters,
    setFilters,
    filtersMobile
  } = useFormFilters(
    filterInitialValues,
    searchFormConfig,
    FormBuilder,
  )
  const onFilterSubmit = async (data: any) => {
    isMobile 
      ? setFilters({...data, ...filtersMobile})
      : setFilters(data);
    hideModal('filters');
  };  
  const filteredData = useFEFilter(data, filters);
  const onUpdate = ({ id }: any) => {
    const link = getUrl(APP_ROUTES.users.update, id);
    history.push(link, history.location);
  };
  const onPasswordReset = ({ allData }: any) => {
    onUserPasswordReset({ email: allData.email });
  };

  const { mutate: onUpdateUser, isLoading: onUpdateUserLoading } = useUpdateUser();

  const { OnDeleteWithComment } = useDeleteUserModal(deleteUserFormConfig, onUpdateUser)

  const actionMenuItems = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.users.update, id),
      icon: <Edit16 />,
    },
    {
      title: _t("reset_password"),
      onClick: onPasswordReset,
      icon: <Password16 />,
    },
    {
      title: _t("deactivate"),
      onClick: (allData: any) => OnDeleteWithComment({...allData.allData}),
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];

  const onActionUpdate = ( 
    {id} : any, 
    updateToggleValueState: (value: boolean) => any,
  ) => {
    let updatedUser: any = data.find(user => user.id === id);
    !onUpdateUserLoading &&
      (updatedUser.active 
        ? (() => {
          OnDeleteWithComment(updatedUser, updateToggleValueState);
        })()
        : (() => {
            onUpdateUser({ 
              data: {...updatedUser, active: true}, 
              id: id 
            });
            updateToggleValueState(true);
          })() 
      );
  };
  return (
    <Card
      title={_t("user_management")}
      extra={
        <>
        {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.users.create}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.users.create}
              title={_t("create_user")}
            />
        }
        </>
      }
    >

      {isMobile 
      ?  <>
          <Button
          kind="ghost"
          style={{ color: "#252C32" }}
          onClick={() => showFilters()}
          renderIcon={Filter16}
          >
            {_t("filters")}
          </Button>
          <FormBuilder
            showSubmit={false}
            formItemsConfig={mobileSearchFormConfig}
            formProps={{
              submitBtnLabel: _t("apply_filters"),
              onSubmit: onFilterSubmit,
              initialValues: {},
              // values: filters
            }} 
          />
        </>
      : <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: {},
            btnColumnParams: {
              lg: { span: 8 },
            },
          }}
        />
      }
      <Table
        totalRecords={+filteredData?.length}
        columns={columns}
        data={filteredData || []}
        isPagination
        isLoading={isLoading}
        cellProps={{ 
          actionMenuItems,
          onActionUpdate 
        }}
      />
    </Card>
  );
};

export default Users;
