import React, { useMemo } from "react";
import { useDeleteAlerts, useGetAlerts } from "./apiHooks";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFormFilters, useListPagination } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { Add16, Filter16 } from "components/atoms/IconsCreateFactory"
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Edit16, CloseOutline16 } from "components/atoms/IconsCreateFactory"
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useSearchFormConfig } from "modules/alerts/constantHooks";
import { useTableColumns } from "modules/alerts/constantHooks";
import config from "system/config";
import { getUrl } from "system/helpers/helperFunctions";
import { Button } from "carbon-components-react";
import { useModal } from "modules/modal/hooks";

const Alerts = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const filterInitialValues = {};
  const searchFormConfig = useSearchFormConfig();
  const { hideModal } = useModal();
  const normalizeReqData = (data: any, filtersInitial: any = {}) => {
    const normalizeDate = { ...data };
    if (data.merchantId) {
      normalizeDate.filterValue = data.merchantId[0]
    } else {
      delete normalizeDate.filterValue
    }

    return normalizeDate
  }
  const { 
    isMobile,
    showFilters,
    filters,
    setFilters,
    filtersMobile
  } = useFormFilters(
    filterInitialValues,
    searchFormConfig,
    FormBuilder,
    normalizeReqData
  )
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination }),
    [filters, pagination]
  );
  const { data, isLoading } = useGetAlerts(requestParams);
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteAlerts();
  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.alerts.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };

  const onFilterSubmit = async (data: any) => {
    isMobile 
      ? setFilters({...data, filterValue: data.merchantId[0], ...filtersMobile})
      : setFilters({...data, filterValue: data.merchantId[0]});
      setPagination(config.defaultInitialPagination);
      hideModal('filters');
  };

  const actionMenuItems = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.alerts.update, id),
      icon: <Edit16 /> 
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];

  return (
    <Card
      title={_t("alerts")}
      extra={
        <>
        {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.alerts.create}
              addHref = {true}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.alerts.create}
              title={_t("create")}
              addHref = {true}
            />
        }
        </>
      }
    >
      {isMobile
        ? <>
          <Button
            kind="ghost"
            style={{ color: "#252C32" }}
            onClick={() => showFilters()}
            renderIcon={Filter16}
          >
            {_t("filters")}
          </Button>
        </>
        : <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onFilterSubmit,
            initialValues: {},
          }}
        />
      }
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        data={data?.content || []}
        isPagination
        onPagination={setPagination}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
      />
    </Card>
  );
};

export default Alerts;
