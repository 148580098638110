import React, { useMemo } from "react";
import { useDeleteMerchantPendingTransactionsMonitoring, useGetMerchantPendingTransactionsMonitoring } from "./apiHooks";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFilters, useListPagination } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { useHistory, generatePath } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Edit16, CloseOutline16, Add16 } from "components/atoms/IconsCreateFactory";
import { useConfirmModal } from "modules/modal/predefinedModals";
import config from "system/config";
import { getUrl } from "system/helpers/helperFunctions";
import { useSearchFormConfig, useTableColumns } from "./constantHooks";

const MerchantMerchantPendingTransactionsMonitoring = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { filters, setFilters } = useFilters({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination }),
    [filters, pagination]
  );
  const { data, isLoading } = useGetMerchantPendingTransactionsMonitoring(requestParams);
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteMerchantPendingTransactionsMonitoring();
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchantPendingTransactionsMonitoring.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };

  const onFilterSubmit = async (data: any) => {
    setFilters(data);
    setPagination(config.defaultInitialPagination);
  };

  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.merchantPendingTransactionsMonitoring.update, id),
      icon: <Edit16 /> 
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];
  const isMobile = window.matchMedia('(max-width: 671px)').matches;


  return (
    <Card
      title={_t("merchant_pending_transactions_monitoring")}
      extra={
        <>
         {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              addHref = {true}
              linkTo={APP_ROUTES.merchantPendingTransactionsMonitoring.create}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.merchantPendingTransactionsMonitoring.create}
              addHref = {true}
              title={_t("create")}
            />
        }
        </>
      }
    >
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          onSubmit: onFilterSubmit,
          initialValues: {},
        }}
      />
      <Table
        totalRecords={data?.totalElements}
        columns={columns}
        data={data?.content || []}
        isPagination
        onPagination={setPagination}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
      />
    </Card>
  );
};

export default MerchantMerchantPendingTransactionsMonitoring;
