import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { MultiSelect as MultiSelectCustom } from "react-multi-select-component";
import { ChevronDown16, Close16 } from "../IconsCreateFactory";
import { useOutsideClick } from "system/helpers/hooks";
import { useTrans } from "system/translations/hooks";
import { MultipleSelectItemsType } from "./types";
import { useModal } from "modules/modal/hooks";

const MultiSelect: React.FC<{
  value: any;
  // onChange: ({ selectedItems }: { selectedItems: [] }) => void;
  onChange: (selectedItems: any[] | '*') => void;
  items: any;
  titleText: string;
  labelWithAll?: boolean;
  wrapClassPrefix?: string;
  label?: React.ReactNode | undefined;
  isOpen?: boolean;
  invalidText?: string,
  whiteBackground?: boolean;
  greyBackground?: boolean;
  withSelectAll?: boolean;
  singleSelect?: boolean;
  twoColumns?: boolean;
  openModal?: boolean;
  notSelected?: boolean;
  withTitleText?: boolean;
  previewSelectedItems?: boolean;
  mobileModalFilterName?: 'filter' | 'createUpdateItem',
  withAsteriskOnSelectAll?: boolean
}> = ({
  onChange,
  items = [],
  label,
  titleText,
  value,
  labelWithAll = true,
  wrapClassPrefix,
  isOpen,
  invalidText,
  whiteBackground = false,
  greyBackground = false,
  withSelectAll = true,
  singleSelect = false,
  twoColumns = false,
  openModal = true,
  notSelected = false,
  withTitleText = false,
  previewSelectedItems = false,
  mobileModalFilterName = 'filter',
  withAsteriskOnSelectAll = false,
  ...rest
}) => {
  const [openState, updateOpenState] = useState(isOpen)
  const [firstRenderFinishedStatus, setFirstRenderFinishedStatus] = useState(false)
  const { _t } = useTrans();
  const emptyArray: any = []

  const ref = useRef(null)

  const [clickOutSide] = useOutsideClick(ref);

  useEffect(() => {
    (!isMobile || !openModal) && !isOpen && firstRenderFinishedStatus && updateOpenState(!clickOutSide)
  }, [clickOutSide])

  useEffect(() => {
    setFirstRenderFinishedStatus(true)
  }, [])

  const selectLabel = (labelWithAll && withSelectAll) ? (
    <>
      <span style={{ color: "#5B6871" }}>{label}:</span>
      <span style={{ marginLeft: "8px", color: "#161616" }}>
        {value?.length ? "" : <span style={{ marginRight: "8px" }}>All</span>}
      </span>
    </>
  ) : (
    <span style={{ color: "#5B6871" }}>{label}</span>
  );

  let selected = useMemo(() => {
    if (value && value === '*') {
      return items
    }
    
    return items?.filter((item: any) => {
      return value?.includes(item?.value) && item;
    });
  }, [value, items]);

  const valueRenderer = () => {
    return (
      <div className={"multi-select__header-container"} >
        <div className={"multi-select__label"}>{selectLabel}</div>
        {!twoColumns && !!selected.length && !singleSelect && !previewSelectedItems && (
          <div className={"multi-select__amount"}>{selected.length}</div>
        )}
        {singleSelect && <div style = {{marginLeft: "10px"}}>{selected.map((item: any) => item?.label)}</div>}
        {singleSelect && !selected.length && notSelected && <div style = {{color: "#161616"}}>not selected</div>}
        {previewSelectedItems && <div style = {{marginLeft: "10px"}}>{selected.map((item: any) => item?.label).join(', ')}</div>}
      </div>
    );
  };

  const onClickHandler = (e : any) => {
    // e.target.outerText === _t("select_all") && 
    updateOpenState(true);
  }

  const handleSingleRemove = (value: number) => {
    const valuesArray = selected.map((item: MultipleSelectItemsType) => item.value)
    onChange(valuesArray.filter((item: number) => item !== value))
  }

  const { showModal } = useModal();
  const isMobile = window.matchMedia('(max-width: 671px)').matches;

  const showMultiSelect = () => {
    let resultArray: any[] = [];

    showModal({
      modalHeading: _t("{form_name}", {
            form_name: `${label}`,
          }),
      isShowScale: false,
      componentProps: {
        options: items,
        value: selected,
        isOpen: true,
        ClearSelectedIcon: null,
        onChange: (data: any) => {
          resultArray = singleSelect 
            ? data.length 
              ? [data[data.length -1]]
              : []
            : resultArray.length 
              ? resultArray.some(item => item.value === data[data.length - 1].value) 
                ? resultArray.filter(item => item.value !== data[data.length - 1].value) 
                : [...resultArray, data[data.length - 1]]
              : [...data];
          
          selected = resultArray;
          
          return resultArray.length === items.length && withAsteriskOnSelectAll 
            ? onChange('*') 
            : onChange(resultArray.map(item => item.value)), 
          showMultiSelect();
        },
        labelledBy: titleText,
        hasSelectAll: withSelectAll,
      },
      component: MultiSelectCustom,
    }, mobileModalFilterName);
  }

  return (
    <div 
      onClick = {(e: any) => (!isOpen && openModal && isMobile) ? showMultiSelect() : onClickHandler(e)}
    >
      {isOpen && valueRenderer()}
      {withTitleText && 
        <div className={"multi-select__header-container"} >
          <div className={"multi-select__label-title"}>{titleText}</div>
        </div>
      }
      <div
        className={classNames(
          "multi-select__wrap",
          isOpen && "multi-select__open",
          wrapClassPrefix && `multi-select__wrap--${wrapClassPrefix}`,
          invalidText && "multi-select__required-error",
          whiteBackground && "multi-select--white-background",
          greyBackground && "multi-select--grey-background",
          twoColumns && "multi-select__twoColumns"
        )}
        ref = {ref}
        onClick = {(e: any) => !isMobile && onClickHandler(e)}
      >
        <MultiSelectCustom
          options={items}
          value={selected}
          isOpen={openState}
          ClearSelectedIcon={null}
          valueRenderer={() => isOpen ? <div/> : valueRenderer()}
          ArrowRenderer={() => <ChevronDown16 />}
          onChange={(data: any) => {
            const filteredData = data?.map((item: MultipleSelectItemsType) => item.value);
            const result: any = 
              singleSelect
                ? (
                  filteredData?.length 
                    ? [filteredData[filteredData.length -1]] 
                    : []
                  )
                : filteredData;
            return result.length === items.length && withAsteriskOnSelectAll ? onChange('*') : onChange(result) ;
          }}
          labelledBy={titleText}
          hasSelectAll = {withSelectAll}
        />
        {twoColumns &&
          <>
            <div className = "multi-select___right-column" >
              <div className="multi-select___right-column__counter">{selected.length} {_t("selected_small")}</div>
              <ul>
                <li 
                  className="multi-select___right-column__remover"
                  onClick = {() => onChange(emptyArray)}
                >
                  <Close16/> 
                  <span>{_t("remove")}</span>
                </li>
                {selected.map((item: MultipleSelectItemsType, key: number) => 
                  <li onClick = {() => handleSingleRemove(item.value)} key = {key}>
                    <span>{item.label}</span>
                    <Close16/>
                  </li>
                )}
              </ul>
            </div>
            <div className="multi-select___bottom-counter">{selected.length} {_t("selected_small")}</div>
          </>
        }
      </div>
      {invalidText && <div className="multi-select__required-error__text">{invalidText}</div>}
    </div>
  );
};

export default MultiSelect;
