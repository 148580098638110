import React, { useMemo } from "react";
import { format } from "date-fns";
import Card from "components/atoms/Card";
import ExpandedRow from "components/molecules/ExpandedRow";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import {
  useFormFilters,
  useListPagination,
  useListSort,
} from "system/helpers/hooks";
import {
  useGetTransactions,
  useGetTransactionsExport,
} from "modules/transactions/apiHooks";
import { expandedItems, filterOptions } from "modules/transactions/constants";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/transactions/constantHooks";
import TransactionInfo from "modules/transactions/components/TransactionInfo";
import config from "../../system/config";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { Button } from "carbon-components-react";
import { DocumentExport16, Filter16, Search16 } from "components/atoms/IconsCreateFactory"
import RefreshTimer from "components/atoms/RefreshTimer";
import DotsMenu from "../../components/atoms/DotsMenu"
import { usePermissionAccess } from "modules/admin/helperHooks";
import { useGetExportConfirmationModal } from "./hooks";
import TransactionInfoFooter from "./components/TransactionInfoFooter";

const Transactions = () => {
  const { showModal, hideModal } = useModal();
  const { checkPermission, isSuperAdmin } = usePermissionAccess();
  const { onExportWithDeviceDataModal } = useGetExportConfirmationModal()
  const { _t } = useTrans();
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const filterInitialValues = useMemo(
    () => ({
      filterField: filterByOptions[2].value,
      fromDate: format(new Date(), config.dateFormat),
      toDate: format(new Date(), config.dateFormat),
    }),
    []
  );
  const searchFormConfig = useSearchFormConfig();
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );

  const normalizeReqData = (data: any, filtersInitial: any = {}) => {
    const normalizeDate = {
      ...data,
      updatedDate: `${data.searchFrom !== "created"}`,
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    } else if (isMobile && !data?.date) {
      normalizeDate.fromDate = filtersInitial.fromDate;
      normalizeDate.toDate = filtersInitial.toDate;
    }
    !isMobile && delete normalizeDate.date;
    delete normalizeDate.searchFrom;

    return normalizeDate;
  };
  const { 
    isMobile,
    showFilters,
    mobileSearchFormConfig,
    filters,
    setFilters,
    filtersMobile
  } = useFormFilters(
    filterInitialValues,
    searchFormConfig,
    FormBuilder,
    normalizeReqData,
  )
  const onFilterSubmit = async (data: any) => {    
    const normalizeDate = normalizeReqData(data, filterInitialValues);
    setPagination(config.defaultInitialPagination);
    isMobile 
      ? setFilters({ ...normalizeDate, sendDate: new Date().getTime(), ...filtersMobile })
      : setFilters({ ...normalizeDate, sendDate: new Date().getTime() });
    hideModal('filters');
  };
  
  const { sort, setSort } = useListSort({});
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetTransactions(requestParams);
  const { mutate: onGetExport, isLoading: isLoadingExport } =
    useGetTransactionsExport();
  const columns = useTableColumns();
  const expandedRowItems = useMemo(() => data && expandedItems(_t), [_t, data]);
  const showDetails = (transaction: any, tab: string) => {
    showModal({
      componentProps: {
        transaction: transaction,
        tab: tab,
      },
      modalHeading: _t("merchant_details_for_{merchant}", {
        merchant: transaction?.merchant?.name,
      }),
      isShowScale: !isMobile,
      component: TransactionInfo,
      footer: TransactionInfoFooter,
      footerProps: {
        resendIpnId: transaction?.customUuid,
        transactionUUID: transaction?.transactionUUID, 
      },
    });
  };
 
  const onExport = () => {
    const accessControl = checkPermission(["ROLE_VIEW_REPORT_WITH_DEVICE_DATA", "ROLE_VIEW_TRANSACTION_FEES"]);
    if (!accessControl && !isSuperAdmin) {
      onGetExport(filters);
    } else {
      onExportWithDeviceDataModal((data: any) => onGetExport({...filters, ...data}))
    }
  };
  const onReloadData = () => {
    setFilters({ ...filters, sendDate: new Date().getTime() });
  };
  const actionMenuItems = [
    {
      title: _t("export_CSV"),
      onClick: onExport,
      icon: <DocumentExport16 />,
    }
  ];
  return (
    <Card
      title={
        <>
          <div>
            <div>{_t("transactions")}</div>
            {data?.responseTime && data?.transactions?.content?.length ? (
              <div className={"card__head__subtitle"}>
                <RefreshTimer
                  onClick={onReloadData}
                  startDate={data?.responseTime}
                />
              </div>
            ) : null}
          </div>
        </>
      }
      extra={
        <>
          {!isMobile
            ? <Button
              kind="tertiary"
              onClick={onExport}
              renderIcon={DocumentExport16}
              disabled={isLoadingExport}
            >
              {_t("export_CSV")}
            </Button>
            : <DotsMenu items={actionMenuItems} />
          }
        </>
      }
    >
      {!isMobile
        ? <FormBuilder
          showSubmit={false}
          formItemsConfig={searchFormConfig}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
            // values: filters
          }}
        />
        : <>
          <Button
            kind="ghost"
            style={{ color: "#252C32" }}
            onClick={() => showFilters()}
            renderIcon={Filter16}
          >
            {_t("filters")}
          </Button>
          <div className="mobile__search-form">
            <FormBuilder
              showSubmit={true}
              formItemsConfig={mobileSearchFormConfig}
              // showModal={true}
              formProps={{
                submitBtnLabel: ' ',
                onSubmit: onFilterSubmit,
                initialValues: filterInitialValues,
                renderIcon: Search16,
                hasIconOnly: true,
                values: filters
              }}
            />
          </div>
        </>
      }

      <Table
        tableName={"transaction-table"}
        totalRecords={data?.transactions?.totalElements}
        onPagination={setPagination}
        columns={columns}
        isLoading={isLoading}
        data={data?.transactions?.content || []}
        isPagination
        onSort={setSort}
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        cellProps={{
          showDetails,
        }}
        rowSubComponent={<ExpandedRow items={expandedRowItems} />}
      />
    </Card>
  );
};

export default Transactions;
