import React from "react";
import { Warning32 } from "components/atoms/IconsCreateFactory"

const NotClosedPeriod: React.FC<any> = ({ titleText , secondaryText , data, isClosePeriod = false }) => {
  return (
    
    <div className="not-closed__container">
      <Warning32 className="not-closed__icon"/>
      <div className="not-closed__text-container">
        <span className="not-closed__prompt">{titleText} {
              <span className="not-closed__prompt-provider">{data.join(', ')}</span>
          }
          {isClosePeriod ? '?' : '.'}
        </span>
        <span className="not-closed__prompt-secondary">{secondaryText}</span>
      </div>
    </div>
  );
};

export default NotClosedPeriod;
