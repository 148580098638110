import React from "react";
import {
  Header,
  HeaderContainer,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  SkipToContent,
  SideNavDivider,
} from "carbon-components-react";
import MainSidebar from "../../../modules/layout/MainSidebar";
import { useTrans } from "../../../system/translations/hooks";
import { useConstants, useHandleWindowResize } from "../../../system/helpers/hooks";
import { useGetCurrentAdmin, useLogout } from "modules/auth/apiHooks";
import { useHistory } from 'react-router-dom';
import { Api16, Logout16, Password16 } from "components/atoms/IconsCreateFactory"
import logo from "../../../images/pingwi-logo.svg"
import InfoUser from "../../atoms/InfoUser";
import HeaderMenuContent from "../../atoms/HeaderMenuContent";
import { mobileMenuBreakpoint } from "modules/mobileMenu/constants";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import HeaderMenuForwardRef from "carbon-components-react/lib/components/UIShell/HeaderMenu";

const HeaderWrapper: any = () => {
  const { _t } = useTrans();
  const { headerLinks } = useConstants();
  const { data } = useGetCurrentAdmin();
  const { mutate: onLogout } = useLogout();
  const history = useHistory();
  const linkHandler = (link: string) => history.push(link);
  const windowSize = useHandleWindowResize()

  const focusRef = (element: HTMLElement) => { 
    if (element instanceof HTMLAnchorElement) {
      element.href = 'javascript:void(0);'; 
  }
};

  return (
    <HeaderContainer
      isSideNavExpanded={true}
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <>
          <Header aria-label="IBM Pingwi">
            <SkipToContent />
            {windowSize > mobileMenuBreakpoint 
              ? <HeaderMenuButton
                  aria-label={_t("open_menu")}
                  isCollapsible
                  onClick={onClickSideNavExpand}
                  isActive={isSideNavExpanded}
                />
              : null
            }
            <HeaderName href="javascript:void(0)" prefix="">
              <img
                  src={logo}
              />
            </HeaderName>
            <HeaderNavigation
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: "100%",
              }}
              aria-label="IBM [Pingwi]"
            >
              {headerLinks.map((item: any, index: number) => (
                <HeaderMenuItem key={index} href={item.link}>
                  {item.title}
                </HeaderMenuItem>
              ))}
              <>
                <HeaderMenuForwardRef
                    aria-label="Link 4"
                    renderMenuContent={() => <HeaderMenuContent name={data?.username} />}
                    menuLinkName={""}
                    ref={focusRef}
                >
                  <InfoUser id = {data?.id}/>
                  <SideNavDivider/>
                  <HeaderMenuItem
                    onClick={(e: any) => {
                      e.preventDefault();
                      linkHandler("/change-password");
                    }}
                    href={"/change-password"}
                  >
                    <Password16 className={"bx--header__menu-item__icon"} />
                    {_t("change_password")}
                  </HeaderMenuItem>
                  <RoleWrapper accessTo={"ROLE_SUPERADMIN"}>
                    <SideNavDivider/>
                    <HeaderMenuItem onClick={(e: any) => {
                        e.preventDefault();
                        linkHandler("/documentation");
                      }}
                      href={"/documentation"}>
                      <Api16 className={"bx--header__menu-item__icon"} />
                      {_t("api_documentation")}
                    </HeaderMenuItem>
                  </RoleWrapper>
                  <SideNavDivider/>
                  <HeaderMenuItem onClick={onLogout} href="javascript:void(0)">
                    <Logout16 className={"bx--header__menu-item__icon"} />
                    {_t('logout')}
                  </HeaderMenuItem>
                </HeaderMenuForwardRef>
              </>
            </HeaderNavigation>
          </Header>
          {windowSize > mobileMenuBreakpoint 
            ? <MainSidebar
                isSideNavExpanded={isSideNavExpanded}
                onClickSideNavExpand={onClickSideNavExpand}
              />
            : null
          }
        </>
      )}
    />
  );
};

export default HeaderWrapper;
