import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useGetMerchant, useUpdateMerchant } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { useParams } from "react-router-dom";
import { createEditValidation } from "../validation";
import { useCreateEditConfig } from "../constantHooks";
import Loader from "components/atoms/Loader";
import { usePrepareDataForMerchantIpn } from "../hooks";


const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutate: onUpdate } = useUpdateMerchant();
  const { data: merchantData, isLoading } = useGetMerchant(routeParams?.id);
  const { getIpnsUrl, normalizeDataBeforeSave } = usePrepareDataForMerchantIpn()
  const normalizeMerchantData = getIpnsUrl(merchantData)
  const formConfig = useCreateEditConfig(normalizeMerchantData);
  const onSubmit = async (data: any) => {
    onUpdate({ data: normalizeDataBeforeSave(data), id: routeParams?.id })
  };

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("edit")}
            extra={<Button kind={"tertiary"}>{_t("deactivate")}</Button>}
          >
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("save_changes"),
                submitBtnKind: "primary",
                enableReinitialize: true,
                onSubmit: onSubmit,
                initialValues: { ...normalizeMerchantData },
                validationSchema: createEditValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;
