import React from "react";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFormFilters } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { Delete16, Filter16 } from "components/atoms/IconsCreateFactory"
import { useModal } from "modules/modal/hooks";
import { Button } from "carbon-components-react";
import { useGetPaymentInstruments } from "./apiHooks";
import { useSearchFormConfig, useTableColumns } from "./constantHooks";
import { useGetOnActionStatusUpdate } from "./hooks";

const PaymentInstrumentManagement = () => {
  const { _t } = useTrans();
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const { hideModal } = useModal();
  const filterInitialValues = {};
  const { 
    isMobile,
    showFilters,
    mobileSearchFormConfig,
    filters,
    setFilters,
    filtersMobile
  } = useFormFilters(
    filterInitialValues,
    searchFormConfig,
    FormBuilder,
  )
  const { data = [], isLoading } = useGetPaymentInstruments(filters, {
    enabled: !!filters?.filter
  });
  const { onActionUpdate } = useGetOnActionStatusUpdate(data, _t)

  const onFilterSubmit = async (data: any) => {
    isMobile 
      ? setFilters({...data, ...filtersMobile})
      : setFilters(data);
    hideModal('filters');
  };  

  const actionMenuItems = [
    {
      title: _t("remove"),
      onClick: ({allData}: any) => onActionUpdate({
        id: allData?.id,
        deletedStatus: "DELETED"
      }),
      icon: <Delete16 />,
      type: "danger",
    },
  ];

  return (
    <Card title={_t("payment_instrument_management")}>
      {isMobile 
      ?  <>
          <Button
          kind="ghost"
          style={{ color: "#252C32" }}
          onClick={() => showFilters()}
          renderIcon={Filter16}
          >
            {_t("filters")}
          </Button>
          <FormBuilder
            showSubmit={false}
            formItemsConfig={mobileSearchFormConfig}
            formProps={{
              submitBtnLabel: _t("apply_filters"),
              onSubmit: onFilterSubmit,
              initialValues: {},
            }} 
          />
        </>
      : <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: { filter : "transactionUUID"},
            btnColumnParams: {
              lg: { span: 8 },
            },
          }}
        />
      }
      <Table
        totalRecords={+data?.length}
        columns={columns}
        data={data}
        isPagination
        isLoading={isLoading}
        cellProps={{ 
          onActionUpdate,
          actionMenuItems
        }}
      />
    </Card>
  );
};

export default PaymentInstrumentManagement;
