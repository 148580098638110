import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { Link } from "carbon-components-react";
import { getPreparedData } from "system/helpers/helperFunctions";
import config from "system/config";
import Select from "components/atoms/Select";
import Btn from "components/atoms/Btn";
import { useParams } from "react-router-dom";
import { useGetHandleSubmitWithConfirmationPage, useModuleData } from "./hooks";
import CommentTooltip from "components/atoms/CommentTooltip";
import { format } from "date-fns";
import { Checkmark16, Download16 } from "components/atoms/IconsCreateFactory"
import ChangeValueTooltip from "components/molecules/ChangeValueTooltip";
import { createManualAdjustmentValidation, createTransferAdjustmentValidation } from "./validation";
import FileUploader from "components/atoms/FileUploader";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { TransactionsType } from "./types";
import fileUploaderConfig from "./constants";
import Input from "components/atoms/Input";

export const useTableColumns = (data: any): TableColumnType[] => {
  const { _t } = useTrans();
  const formConfig = useEditFieldConfig()
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("movement"),
        accessor: "movement",
        id: "movement",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return !!row.original?.movement 
          ? row.original?.movement === 'Total' 
            ? <strong>{row.original?.movement}</strong>
            : <span>{row.original?.movement}</span> 
          : !!row.original?.gatewayName 
            ? <span>{row.original?.gatewayName}</span> 
            : <span>-</span>
        }
      },
      {
        Header: <div className="mb--cell">{_t("deposits_movements")}</div>,
        accessor: "deposits",
        id: "deposits",
        mobileVisible: true,
        isMobileTitle: true,
        disableSortBy: true,
        Cell: ({ row, showDetails }: any) => {
          const displayData = row.original?.deposits + ' ' + row.original?.currency
          return (row.original?.deposits
            ? row.original?.movement === 'Total' 
              ? <strong>{displayData}</strong>
              : <Link
                  onClick={() => {
                    showDetails({
                      gate: row.original?.gatewayCode,
                      type: "DEPOSIT",
                      fromDate: row.original?.dateFrom,
                      toDate: row.original?.dateTo
                    });
                  }}
                >
                  {displayData}
                </Link>
            : <span>-</span>
          )
        }
      },
      {
        Header: <div className="mb--cell">{_t("withdrawals_movements")}</div>,
        accessor: "withdrawals",
        id: "withdrawals",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, showDetails }: any) => {
        const displayData = row.original?.withdrawals + ' ' + row.original?.currency

          return (row.original?.withdrawals
            ? row.original?.movement === 'Total' 
              ? <strong>{displayData}</strong> 
              : <Link
                  onClick={() => {
                    showDetails({
                      gate: row.original?.gatewayCode,
                      type: "WITHDRAWAL",
                      fromDate: row.original?.dateFrom,
                      toDate: row.original?.dateTo
                    });
                  }}
                >
                {displayData}
              </Link>
            : <span>-</span>
          )
        }
      },
      {
        Header: <div className="mb--cell">{_t("payment_gateway_name")}</div>,
        accessor: "gatewayNameMainTable",
        id: "gatewayNameMainTable",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return row.original?.movement === 'Total' 
              ? <strong>{row.original?.gatewayName|| '-'}</strong>
              : row.original?.gatewayName || '-'
        }
      },
      {
        Header: <div className="mb--cell">{_t("total_fees")}</div>,
        accessor: "totalFees",
        id: "totalFees",
        mobileVisible: true,
        minWidth: 160,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const feesDeatails: any[] = row.original?.totalFeesDetails?.replaceAll("\\", '') 
          ? Object.entries(JSON.parse(row.original?.totalFeesDetails?.replaceAll("\\", ''))).map(item => [...item,row.original?.currency])
          : []
          const displayData = getPreparedData(row.original, 'totalFees', data.totalGatesBalance)
          return (
            row.original?.movement === 'Total' 
              ? <strong>{displayData}</strong> 
              : <CommentTooltip 
                  comment={feesDeatails} 
                  cellValue={displayData}
                />
          ) 
        }
      },
      {
        Header: <div className="mb--cell">{_t("movements_ajustment")}</div>,
        accessor: "movementsAdjustment",
        id: "movementsAdjustment",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, showMovementAdjustmentDetails, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'movementsAdjustment', data.totalGatesBalance)
          const commentMovementAdjustment = row.original?.manualAdjustmentDetails?.movementsAdjustments
            .map((comment: any) => ({...comment,amount: comment.adjustmentType === "increaseBalance" ? comment.amount : comment.amount * -1 }))
          return (
            row.original?.movement === 'Total' 
              ? <strong>{displayData}</strong> 
              : <CommentTooltip 
                  comment={commentMovementAdjustment || ['']} 
                  cellValue={displayData}
                  showMovementAdjustmentDetails={showMovementAdjustmentDetails}
                  formConfig={formConfig}
                  onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: data.data.operationType})}
                  validation={createManualAdjustmentValidation(_t, true)}
                  accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                  onGetAttachmentHandler={onGetAttachmentHandler}
                />
          )
        }
      },
      {
        Header: _t("on_hold"),
        accessor: "onHoldAmount",
        id: "onHoldAmount",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, showMovementAdjustmentDetails, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'onHoldAmount', data.totalGatesBalance)
          const commentAddOnHold = row.original?.manualAdjustmentDetails?.holdAdjustments.filter((comment: any) => comment.adjustmentType === "addOnHold" )
          
          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : <CommentTooltip 
                comment={commentAddOnHold || ['']} 
                cellValue={displayData}
                showMovementAdjustmentDetails={showMovementAdjustmentDetails}
                formConfig={formConfig}
                onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: data.data.operationType})}
                validation={createManualAdjustmentValidation(_t)}
                accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                onGetAttachmentHandler={onGetAttachmentHandler}
              />
        }
      },
      {
        Header: <div className="mb--cell">{_t('return_on_hold_amount')}</div>,
        accessor: "onHoldReturnAmount",
        id: "onHoldReturnAmount",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, showMovementAdjustmentDetails, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'onHoldReturnAmount', data.totalGatesBalance)
          const commentReturnOnHold = row.original?.manualAdjustmentDetails?.holdAdjustments.filter((comment: any) => comment.adjustmentType === "returnOnHold" )

          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : <CommentTooltip 
                comment={commentReturnOnHold || ['']} 
                cellValue={displayData}
                showMovementAdjustmentDetails={showMovementAdjustmentDetails}
                formConfig={formConfig}
                onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: data.data.operationType})}
                validation={createManualAdjustmentValidation(_t)}
                accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                onGetAttachmentHandler={onGetAttachmentHandler}
              />
        }
      },
      {
        Header: <div className="mb--cell">{_t("rolling_reserve")}</div>,
        accessor: "rollingReserveAmount",
        id: "rollingReserveAmount",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, showMovementAdjustmentDetails, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'rollingReserveAmount', data.totalGatesBalance)
          const commentAddRR = row.original?.manualAdjustmentDetails?.reserveAdjustments.filter((comment: any) => comment.adjustmentType === "addRollingReserve" )
          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : <CommentTooltip 
                comment={commentAddRR || ['']} 
                cellValue={displayData}
                showMovementAdjustmentDetails={showMovementAdjustmentDetails}
                formConfig={formConfig}
                onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: data.data.operationType})}
                validation={createManualAdjustmentValidation(_t)}
                accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                onGetAttachmentHandler={onGetAttachmentHandler}
              />
        }
      },
      {
        Header: <div className="mb--cell">{_t("return_of_RR")}</div>,
        accessor: "rollingReserveReturnAmount",
        id: "rollingReserveReturnAmount",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, showMovementAdjustmentDetails, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'rollingReserveReturnAmount', data.totalGatesBalance)
          const commentReturnRR = row.original?.manualAdjustmentDetails?.reserveAdjustments.filter((comment: any) => comment.adjustmentType === "returnRollingReserve" )
          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : <CommentTooltip 
                comment={commentReturnRR || ['']} 
                cellValue={displayData}
                showMovementAdjustmentDetails={showMovementAdjustmentDetails}
                formConfig={formConfig}
                onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: data.data.operationType})}
                validation={createManualAdjustmentValidation(_t)}
                accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                onGetAttachmentHandler={onGetAttachmentHandler}
              />
        }
      },
      {
        Header: <div className="mb--cell">{_t("transfer_to_merchant")}</div>,
        accessor: "transferToMerchant",
        id: "transferToMerchant",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'transferToMerchant', data.totalGatesBalance)

          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : displayData === '-'
              ? <span>{displayData}</span>
              : <div className="change-value--with-atachment__container">
                <ChangeValueTooltip
                    formConfig={formConfig}
                    dataValue={displayData}
                    initialValues={{...row.original,  amount: row.original.transferToMerchant}}
                    bigTooltipClass="smallTooltipClass"
                    onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: "balanceAdjustment"})}
                    accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                    validation={createTransferAdjustmentValidation(_t, true)}
                  />
                 {row?.original?.fileName && <Download16 className="change-value--with-atachment__icon"  onClick={() => onGetAttachmentHandler({...row?.original, operationType: "balanceAdjustment"})}/>}
                </div>
        }
      },
      {
        Header: <div className="mb--cell">{_t("received_from_merchant")}</div>,
        accessor: "receivedFromMerchant",
        id: "receivedFromMerchant",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = getPreparedData(row.original, 'receivedFromMerchant', data.totalGatesBalance)
          const formConfig = useEditFieldConfig()

          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : displayData === '-'
              ? <span>{displayData}</span> 
              : <div className="change-value--with-atachment__container">
                <ChangeValueTooltip
                 formConfig={formConfig}
                 dataValue={displayData}
                 initialValues={{...row.original,  amount: row.original.receivedFromMerchant}}
                 bigTooltipClass="smallTooltipClass"
                 onUpdate={(data: any) => onFieldDataUpdate({...data, transactionType: "balanceAdjustment"})}
                 accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                 validation={createTransferAdjustmentValidation(_t)}
               />
               {row?.original?.fileName && <Download16 className="change-value--with-atachment__icon" onClick={() => onGetAttachmentHandler({...row?.original, operationType: "balanceAdjustment"})}/>}
                </div>
                
        }
      },
      {
        Header: <div className="mb--cell">{_t("balance_adjustment_amount")}</div>,
        accessor: "balanceAdjustmentAmount",
        id: "balanceAdjustmentAmount",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const displayData = getPreparedData(row.original, 'balanceAdjustmentAmount', data.balanceAdjustmentAmount)
          return row.original?.gatewayCode === 'Total' 
            ? <strong>{displayData}</strong> 
            : <span>{displayData}</span> 
        }
      },
      {
        Header: <div className="mb--cell">{_t("available_balance")}</div>,
        accessor: "availableBalance",
        id: "availableBalance",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const displayData = getPreparedData(row.original, 'availableBalance', data.totalGatesBalance)
          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : <span>{displayData}</span> 
        }
      },
      {
        Header: <div className="mb--cell">{_t("total_balance")}</div>,
        accessor: "totalBalance",
        id: "totalBalance",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const displayData = getPreparedData(row.original, 'totalBalance', data.totalGatesBalance)
          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : <span>{displayData}</span>
        }
      },
      {
        Header: _t("comment"),
        accessor: "comment",
        id: "comment",
        mobileVisible: true,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <div className="mb--cell">
              {row.original?.comment
                ? row.original?.comment
                : '-'}
            </div>)
        }
      },
      {
        Header: <div className="mb--cell">{_t("transfer_fee")}</div>,
        accessor: "transferFee",
        id: "transferFee",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const displayData = row.original?.transferFee
            ? row.original?.transferFee * (-1)
            : '-'
          return row.original?.movement === 'Total' 
            ? <strong>{displayData}</strong> 
            : displayData
        }
      },
      {
        Header: _t("created_by"),
        accessor: "createdBy",
        id: "createdBy",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return row.original?.createdBy
            ? row.original?.createdBy
            : '-'
        }
      },
      {
        Header: _t("for_date"),
        accessor: "forDate",
        id: "forDate",
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return row.original?.forDate
            ? row.original?.forDate
            : '-'
        }
      },
      {
        maxWidth: 40,
        Header: "",
        id: "ColumnSettingsMenu",
        mobileVisible: true,
      },
    ], [data]
  );
  return columns;
};

export const useTableTransactionColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("transactionUUID"),
        accessor: "transactionUUID",
        id: "transactionUUID",
        width: 200,
      },
      {
        Header:<div className="mb--cell">{_t("payment_gateway_name")}</div>,
        accessor: "gatewayName",
        id: "gatewayName",
    
      },
      {
        Header: _t("amount"),
        accessor: "convertedAmount",
        id: "convertedAmount",
        width: 200,
      },
      {
        Header: _t("transaction_currency"),
        accessor: "currency",
        id: "currency",
        Cell: ({ row }: any) => {
          return row?.original?.originalCurrency && row?.original?.originalAmount 
           ? `${row?.original?.currency} ( ${row?.original?.originalCurrency} )`
           : row?.original?.currency 
        }
      },
      {
        Header: _t("transaction_date"),
        accessor: "date",
        id: "date",
        width: 200,
        Cell: ({ row }: any) => {
          return (<span>{format(new Date(row?.original?.date), "dd/MM/yyyy")}</span>)
        }
      }
    ], []
  );
  return columns;
}

export const useTableTransactionColumnsExpandFee = (): TableColumnType[] => {
  const { _t } = useTrans();

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("deposit_fees"),
        accessor: "feesDetails.depositFee",
        id: "depositFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.depositFee
              ? `${row.original.feesDetails.depositFee} %`
              : '-'}
          </span>
        )
      },
      {
        Header: _t("deposit_added_fees"),
        accessor: "feesDetails.depositAddedFee",
        id: "depositAddedFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.depositAddedFee
              ? `${row.original.feesDetails.depositAddedFee} %`
              : '-'}
          </span>
        )
      },
      {
        Header: _t("withdrawal_fees"),
        accessor: "feesDetails.withdrawalFee",
        id: "withdrawalFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.withdrawalFee
              ? `${row.original.feesDetails.withdrawalFee} %`
              : '-'}
          </span>
        )
      },
      {
        Header: _t("withdrawal_added_fees"),
        accessor: "feesDetails.withdrawalAddedFee",
        id: "withdrawalAddedFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.withdrawalAddedFee
              ? `${row.original.feesDetails.withdrawalAddedFee} %`
              : '-'}
          </span>
        )
      },
      {
        Header: _t("deposit_fixed_fee"),
        accessor: "feesDetails.depositFixedFee",
        id: "depositFixedFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.depositFixedFee
              ? `${row.original.feesDetails.depositFixedFee} ${row.original.feesDetails.depositFixedFeeCurrency}`
              : '-'}
          </span>
        )
      },
      {
        Header: _t("withdrawal_fixed_fee"),
        accessor: "feesDetails.withdrawalFixedFee",
        id: "withdrawalFixedFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.withdrawalFixedFee
              ? `${row.original.feesDetails.withdrawalFixedFee} ${row.original.feesDetails.withdrawalFixedFeeCurrency}`
              : '-'}
          </span>
        )
      },
      {
        Header: _t("settlement_fees"),
        accessor: "feesDetails.settlementFee",
        id: "settlementFee",
        Cell: ({ row }: any) => (
          <span>
            {row.original?.feesDetails?.settlementFee
              ? row.original?.feesDetails?.settlementFeeCurrency
                ? `${row.original.feesDetails.settlementFee} ${row.original.feesDetails.settlementFeeCurrency}`
                : `${row.original.feesDetails.settlementFee} %`
              : '-'}
          </span>
        )
      },
    ], []
  );
  return columns;
}

export const useTableColumnsMovementAdjustmentDetails = (): TableColumnType[] => {
  const { _t } = useTrans();
  const formConfig = useEditFieldConfig()

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t('created_by'),
        accessor: "createdBy",
        id: "createdByMovement",
        minWidth: 250,
      },
      {
        Header: _t("amount"),
        accessor: "amount",
        id: "amountMovement",
        Cell: ({ row, onFieldDataUpdate, onGetAttachmentHandler }: any) => {
          const displayData = row.original?.amount
            ? row.original?.amount + ' ' + row.original?.currency
            : '-'

          return (
            <div className="change-value--with-atachment__container">
              <ChangeValueTooltip
                formConfig={formConfig}
                dataValue={displayData}
                initialValues={row.original}
                bigTooltipClass="smallTooltipClass popover-tooltip-modal"
                onUpdate={(data: any) => onFieldDataUpdate({ ...data, transactionType: data.data.operationType })}
                accessTo={"ROLE_MODIFY_MERCHANT_BALANCE_V2_TRANSACTIONS"}
                validation={createManualAdjustmentValidation(_t, row.original.operationType === 'movementAdjustment')}
              />
              {row?.original?.fileName && <Download16 className="change-value--with-atachment__icon" onClick={() => onGetAttachmentHandler(row?.original)} />}
            </div>
          )
        }
      },
      {
        Header: _t("for_date"),
        accessor: "forDate",
        id: "forDateMovement",
        width: 200,
      },
      {
        Header: _t("comment"),
        accessor: "comment",
        id: "commentMovement",
      },
      {
        Header: _t("gateway"),
        accessor: "gatewayCode",
        id: "gatewayCode",
        width: 200,
      },
    ], []
  );
  return columns;
}

export const useCreateEditConfig = (): FormConfigType => {
  const [isMovement, setIsMovement] = useState(true)
  const [ isTransferToAnotherMerchant, setIsTransferToAnotherMerchant] = useState(false)
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const routeParams: any = useParams();
  const { gatewaysOptions, firstDayOfOpenedPeriod, merchantsOptions } = useModuleData(routeParams.merchantId, routeParams.currency)
  const transactionTypes = config.transactionTypesManualTransaction

  const checkIsTransfer = (form: any) => {
    const selectedAdjustmentType = form?.values?.adjustmentType

    return selectedAdjustmentType === "transferToMerchant" || selectedAdjustmentType === "receivedFromMerchant";
  }

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.transactionType({
        componentProps: {
          items: transactionTypes,
          label: _t('type'),
          onFieldChange: (value: any, values: any, form: any) => {
            const fieldsToResetValues: any = ["adjustmentType", "psp","amount","comment", "transferFee", "conterpartMerchantId" ]
            
            fieldsToResetValues.forEach((field: any) => {
              if (field === "amount" || field === "comment" || field === "transferFee" ) {
                form.setFieldValue(field, '')
              } else {
                form.setFieldValue(field, undefined)
              }
              form.setFieldTouched(field, false);
            })
          },
        },
      }),
      fields.general.divider(),
      {
        component: Select,
        name: "adjustmentType",
        componentProps: (form: any) => {
          const type: TransactionsType = form?.values?.transactionType
          return {
            items: config.adjustmentType[type],
            labelText: _t("adjustment_type"),
            placeholderText: _t('select_adjustment_type'),
            onFieldChange: (value: any, values: any, form: any) => {
              if (!isMovement && value === "transferToAnotherMerchant") {
                setIsTransferToAnotherMerchant(true)
              } else {
                setIsTransferToAnotherMerchant(false)
                form.setFieldValue("conterpartMerchantId", undefined)
                form.setFieldTouched("conterpartMerchantId", false)
              }
                  
            }
          }
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: 'small-input__container-right'
        }
      },
      fields.formFields.amount({
        columnParams: {
          className: 'small-input__container-left'
        }
      }),
       {
        component: SelectWithSearch,
        name: "psp",
        componentProps: (form: any) => {
          if (form?.values?.transactionType !== 'balanceAdjustment') {
            setIsMovement(true)
          } else if (form?.values?.transactionType === 'balanceAdjustment' && isMovement) {
            setIsMovement(false)
          }
          return {
            items: gatewaysOptions,
            labelText: _t("psp"),
            placeholderText: _t("select_psp")
          }
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: isMovement ? 'small-input__container-right' : 'small-input__container-right conditional-input'
        }
      },
      {
        component: Input,
        name: "transferFee",
        componentProps: (form: any) => {
          const isMerchantTransfer = checkIsTransfer(form)

          if (!isMerchantTransfer && form?.values?.transferFee) {
            form.setFieldValue("transferFee", "")
            form.setFieldTouched("transferFee", false)
          }
          return {
            labelText: _t('transfer_fee'),
            disabled: !isMerchantTransfer
          }
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: !isMovement  
            ? isTransferToAnotherMerchant 
              ? 'conditional-input'
              : 'small-input__container-right' 
            : 'small-input__container-right conditional-input'
        }
      },
      {
        component: SelectWithSearch,
        name: "conterpartMerchantId",
        componentProps: (form: any) => {
          return {
            items: merchantsOptions,
            labelText: _t('transfer_to_merchant'),
            placeholderText: _t("merchant"),
          }
        },
        columnParams: {
          lg: 8,
          md: 8,
          className: isTransferToAnotherMerchant ? 'small-input__container-right' : 'small-input__container-right conditional-input'
        }
      },
      fields.formFields.dateManual({
        name: 'forDate',
        componentProps: {
          placeholder: _t('select_date'),
          minDate: firstDayOfOpenedPeriod
        },
        columnParams: {
          md: 8,
          lg: 8,
          className: isMovement ? 'small-input__container-left' : 'small-input__container-left'
        },
      }),
      fields.formFields.comment(),
      fields.general.divider(),
      {
        component: FileUploader,
        name: 'file',
        componentProps: {
          acceptFilesFormat: fileUploaderConfig.acceptFilesFormat,
          checkFile: (file: any) => fileUploaderConfig.checkIsValidFile(file)
        },
        columnParams: {
          lg: 16,
          md: 8
        }
      },
      fields.general.divider(),
      {
        component: Btn,
        name: "",
        componentProps: (form: any) => {
          const valuesToCheck = {
            ...form?.values
          }
          const isMerchantTransfer = checkIsTransfer(form)
          const { isValidFile } = fileUploaderConfig.checkIsValidFile(valuesToCheck?.file)
          delete valuesToCheck.file
          isMovement
            ? delete valuesToCheck.transferFee
            : delete valuesToCheck.psp
            
          isTransferToAnotherMerchant 
            ? delete valuesToCheck.transferFee
            : delete valuesToCheck.conterpartMerchantId

          !isMerchantTransfer && delete valuesToCheck.transferFee

            const checkIfAllFieldsFilled = Object.values(valuesToCheck).every((item) => item && item )
            
          return {
            kind: "primary",
            type: "submit",
            label: _t("save_changes"),
            disabled: !checkIfAllFieldsFilled || !(checkIfAllFieldsFilled && isValidFile)
          } 
        },
        columnParams: {
          className: "maxWidthContent",
        }
       },
    ];
  }, [isMovement, gatewaysOptions, firstDayOfOpenedPeriod, merchantsOptions, isTransferToAnotherMerchant]);
  return formConfig;
};

export const useEditFieldConfig = (): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const { handleClick } = useGetHandleSubmitWithConfirmationPage()

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.amount({
        name: 'amount',
        componentProps: {
          labelText: _t("amount"),
        },
        columnParams: {
          className: 'small-input__container-right',
          lg: 10,
          md: 4
        }
      }),
      fields.formFields.amount({
        name: "currency",
        componentProps: {
          disabled: true,
          labelText: ''
        },
        columnParams: {
          className: 'align-input max-width-input',
          lg: 6,
          md: 6,
        }
      }),
      fields.formFields.comment({
        componentProps: {
          rows: 1
        }
      }),
      fields.general.divider(),
      {
        component: Btn,
        name: "",
        componentProps: (form: any) => {
          return {
            kind: "primary",
            type: "button",
            label: '',
            renderIcon: Checkmark16,
            onClick: () => handleClick(form)
          } 
        },
        columnParams: {
          className: 'popover-tooltip__saveBtn',
        }
       },
    ];
  }, []);
  return formConfig;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.date({
        valuePreview: false
      }),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};

