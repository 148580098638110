import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { SelectedDataType, UseModuleDataRes } from "modules/transactions/types";
import {
  useGetPaymentGateways,
  useGetPaymentMethods,
} from "../payment/apiHooks";
import { useTrans } from "system/translations/hooks";
import { useModal } from "modules/modal/hooks";
import { Button } from "carbon-components-react";
import { DocumentExport16 } from "components/atoms/IconsCreateFactory"
import ExportReportTypeChooser from "components/molecules/ExportReportTypeChooser";
import { usePermissionAccess } from "modules/admin/helperHooks";
import { useGetStatusReasons } from "modules/statusReasons/apiHooks";

export const useModuleData = (): UseModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentMethods = [] } = useGetPaymentMethods();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  const { data: statusReasons = [] } = useGetStatusReasons();
  
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const paymentMethodsOptions = arrayOptionsMap(paymentMethods, {
    labelKey: "label",
    valueKey: "value",
  });
  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  const statusReasonsOptions = statusReasons.map((item) => ({
    label: `${item.code} ${item.title}`,
    value: `${item.code}`,
  }));

  return { merchantsOptions, paymentMethodsOptions, paymentGatewaysOptions, statusReasonsOptions };
};

export const useGetExportConfirmationModal = () => {
  const { _t } = useTrans();
  const { showModal, updateModal, hideModal } = useModal();
  const { checkPermission, isSuperAdmin } = usePermissionAccess();

  const deviceDataPermission = checkPermission("ROLE_VIEW_REPORT_WITH_DEVICE_DATA");
  const feesPermission = checkPermission("ROLE_VIEW_TRANSACTION_FEES");
  const isBothPermission = isSuperAdmin || (deviceDataPermission && feesPermission);

  const getModalProps = (selectedData: SelectedDataType, onExport: (data: any) => void) => ({
    size: "sm",
    modalHeading: _t('do_you_want_to_inlude_data_in_report'),
    component: ExportReportTypeChooser,
    componentProps: {
      selectedData,
      onUpdateCheckBox: (newSelectedData: SelectedDataType) => updateModal(getModalProps(newSelectedData, onExport)),
      isBothPermission
    },
    isShowScale: false,
    footer: Button,
    footerProps: {
      onClick: () => {
        onExport(selectedData ? { [selectedData]: true } : {});
        hideModal();
      },
      children: _t("export_CSV"),
      renderIcon: DocumentExport16
    },
  });

  const onExportWithDeviceDataModal = (onExport: (data: any) => void) => {
    const defaultSelection: SelectedDataType = (!deviceDataPermission && feesPermission && !isSuperAdmin) 
      ? 'feesData' 
      : 'deviceData';

    showModal(getModalProps(defaultSelection, onExport));
  };

  return { onExportWithDeviceDataModal };
};

