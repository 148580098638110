import React, { useState } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateEditConfig, useTableStatusReasonsCreateEdit } from "../constantHooks";
import { Add20, TrashCan16 } from "components/atoms/IconsCreateFactory"
import Divider from "components/atoms/Divider";
import Table from "modules/table";
import { NewStatusConnectionType } from "../types";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useGetCheckNewStatusConnections, useGetHelperFunctions } from "../hooks";
import { useCreateStatusReasonConnection } from "../apiHooks";
import { createEditValidation } from "../validation"

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { onConfirm } = useConfirmModal();
  const columns = useTableStatusReasonsCreateEdit()
  const { checkNewStatusConnectionForUpdate } = useGetCheckNewStatusConnections()
  const [ newStatusesConfigurationList, setNewStatusesConfigurationList ] = useState<NewStatusConnectionType[]>([])
  const { sortStatusesIntoUpdateCreateData } = useGetHelperFunctions()
  const { mutate: onCreate } = useCreateStatusReasonConnection();

  const formConfig = useCreateEditConfig({
    isEdit: false
  });
  const onSubmit = (data: any) => {
    checkNewStatusConnectionForUpdate({
      newStatusConnectionItem: newStatusesConfigurationList[0],
      onOk: () => onCreate(sortStatusesIntoUpdateCreateData(data, newStatusesConfigurationList))
    })
  };

  const onUpdateCreatedStatusConfigurationItem = ({data, id}: { data: NewStatusConnectionType, id: number }) => {
    newStatusesConfigurationList[id] = data
    setNewStatusesConfigurationList([...newStatusesConfigurationList])
  }
  const newStatusConfigurationItem: NewStatusConnectionType = {
    statusName: null,
    sequenceNumber: null,
    codePriority: null,
    errorDetailKey: null,
    errorValue: null,
    statusReasonOption: null
}

const addStatusConfiguration = () => {
  checkNewStatusConnectionForUpdate({
    newStatusConnectionItem: newStatusesConfigurationList[0] || {},
    onOk: () => setNewStatusesConfigurationList((prev: any) => [newStatusConfigurationItem, ...prev])
  })
}

  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => setNewStatusesConfigurationList((prevList) => {
      const newList = [...prevList]; 
      newList.splice(id, 1);        
      return newList;               
  })});
  };

  const actionMenuItems = [
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed
      className="update-settings-content">
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_status_reason_connection")}>
           
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                initialValues: {},
                validationSchema: createEditValidation(_t),
              }}
            />
            <div className="form-item__table-header-container">
              {_t("configuration_settings")}
              <div className="form-item__table-header-add-btn" onClick={addStatusConfiguration}>
                <Add20 /> {_t("add_configuration")}
              </div>
            </div>
            <div className="status_reason__table-container">
              <Table
                data={newStatusesConfigurationList}
                columns={columns}
                cellProps={{
                  onUpdateCreatedStatusConfigurationItem,
                  actionMenuItems,
                }}
              />
            </div>
            <Divider />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;
