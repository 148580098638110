import React from "react";
import { ArrowUp16, ArrowDown16 } from "../IconsCreateFactory";
import { ReorderArrowsProps } from "./types";

const ReorderArrows: React.FC<ReorderArrowsProps> = ({
 onMoveUp,
 onMoveDown
}) => {
  return (
    <div className="reorder-arrows__container">
      <ArrowUp16 onClick={onMoveUp}/>
      <ArrowDown16 onClick={onMoveDown} />
    </div>
  );
};

export default ReorderArrows;
