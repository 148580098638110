import React from "react";
import * as Yup from "yup";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useResetPassword } from "modules/auth/apiHooks";
import Loader from "components/atoms/Loader";
import { useResetPasswordFormConfig } from "modules/auth/constantHooks";
import { useGetQuery } from "system/helpers/hooks";
import { isValidPassword } from "system/helpers/validationHelper";

interface ResetPasswordFormProps {
  onSuccess: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSuccess }) => {
  const { mutateAsync, isLoading } = useResetPassword();
  const { _t } = useTrans();
  const query = useGetQuery();
  const token = query.get("token");
  const formConfig = useResetPasswordFormConfig();
  const onSubmit = async (data: any) => {
    const res = await mutateAsync({ ...data, token });

    if (res?.status === "APPROVED") {
      onSuccess && onSuccess();
    }
  };

  const ForgotPasswordSchema = Yup.object().shape({
    password: isValidPassword(_t).required(_t("required")),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], _t("passwords_must_match"))
      .required(_t("required")),
  });

  return (
    <>
      <FormBuilder
        formItemsConfig={formConfig}
        formProps={{
          onSubmit: onSubmit,
          initialValues: { password: "", repeatPassword: "" },
          validationSchema: ForgotPasswordSchema,
          submitBtnKind: "primary",
          submitBtnLabel: _t("change_password"),
        }}
      />
      {isLoading && <Loader formOverlay description={_t("loading")} />}
    </>
  );
};

export default ResetPasswordForm;
