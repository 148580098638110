import React from "react";
import { Button } from "carbon-components-react";
import { Close16 } from "components/atoms/IconsCreateFactory";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useModal } from "modules/modal/hooks";
import { useUpdateAdvancedMerchantsFees } from "modules/MerchantsFees/apiHooks";

const AdvancedSettingsMerchantBalance: React.FC<any> = ({
  formConfig,
  initialValues,
  onUpdate = () => { },
  validation
}) => {
  const { _t } = useTrans();
  const {hideModal} = useModal()

  const onSubmit = async (data: any) => {
    const normalize: any = {
      advancedSettings: data.advancedSettings,
    }

    normalize.fieldsToUpdate = [
      {
        fieldName: data.fieldName,
        value: data[data.fieldName]
      },
      {
        fieldName: data.fieldName + 'ValidFrom',
        value: data[data.fieldName + 'ValidFrom']
      }
    ]

    if (data.fieldName.includes('Fixed')) {
      normalize.fieldsToUpdate = [...normalize.fieldsToUpdate,
        {
          fieldName: data.fieldName + 'Currency',
          value: data[data.fieldName + 'Currency']
        }
      
    ]
    }

    onUpdate(normalize);
    hideModal('advancedSettings')
  };
 
  return (
    <>
    <div >
        <FormBuilder
          formItemsConfig={formConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onSubmit,
            initialValues: initialValues,
            validationSchema: validation,
          }} />
          <Button
            renderIcon={Close16}
            kind='tertiary'
            style={{
              position: 'absolute',
              bottom: '10.5px',
              right: '200px',
            }}
            onClick={() => hideModal('advancedSettings')}
            >{_t('cancel')}</Button>
      </div>
      </>

  );
};

export default AdvancedSettingsMerchantBalance;
