import React from "react";
import FormBuilder from "../../formBuilder/FormBuilder";
import { useTrans } from "../../../system/translations/hooks";
import { useCreateMerchantSlackChannelSetup, useDeleteSlackChannel, useGetMerchantSlackChannels } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { useSetupSlackChannelConfig } from "../constantHooks";
import { useParams } from "react-router-dom";
import Loader from "components/atoms/Loader";
import { createEditSlackChannelsValidation } from "../validation"

const SetupSlackChannel: React.FC = () => {
  const { _t } = useTrans();
  const routeParams = useParams<{ merchantId: string }>();
  const merchantId = +routeParams.merchantId!;
  const { data: slackChannels = [], isLoading } = useGetMerchantSlackChannels(merchantId);
  const { mutate: onSetup } = useCreateMerchantSlackChannelSetup();
  const { mutate: onDelete, isLoading: isDeleteLoading } = useDeleteSlackChannel();
  
  const onSaveNewSlackChannel = async (newChannel: any) => {
    onSetup({...newChannel, merchantId: merchantId })
  }
  
  const onDeleteSlackChannel = async (index: number) => {
    onDelete(slackChannels[index])
  }

  const formConfig = useSetupSlackChannelConfig(slackChannels, {
    onConfirm: onSaveNewSlackChannel,
    onRemove: onDeleteSlackChannel,
    isDeleteLoading
  });
  
  if (isLoading) {
    return <Loader formOverlay />;
  }
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("setup_slack_channel")}>
                <FormBuilder
                showSubmit={false}
                formItemsConfig={formConfig}
                formProps={{
                  enableReinitialize: true,
                  initialValues: {"channels" : [...slackChannels] },
                  validationSchema: createEditSlackChannelsValidation(_t)
                }}
              />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default SetupSlackChannel;
