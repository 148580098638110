import { Draggable16 } from "components/atoms/IconsCreateFactory"
import Checkbox from "components/atoms/Checkbox";
import Divider from "components/atoms/Divider";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useOutsideClick } from "system/helpers/hooks";
import { useTrans } from "system/translations/hooks";
import { ColumnSettingsProps } from "./types";

const ColumnSettingsMenu: React.FC<ColumnSettingsProps> = ({
  onDragEndHandler,
  allColumns,
  getItemStyle,
  columnHider,
  isColumnSettings,
  showColumnSetting,
  setShowColumnSetting,
}) => {
  const { _t } = useTrans()
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);

  useEffect(() => {
    setShowColumnSetting(false);
  }, [clickOutSide]);

  const activeColumns = allColumns.filter((item: any) => item.isVisible)
  const deActivetedColumns = allColumns.filter((item: any) => !item.isVisible)


  return (
    <>
      {!isColumnSettings &&
        <div
          onClick={() => setShowColumnSetting(true)}
          style={{
            position: "absolute",
            zIndex: 1,
            transform: "rotate(90deg)",
            cursor: "pointer",
            left: "-285px",
            top: "17px",
            display: showColumnSetting ? 'none' : 'block'
          }}
        >
          ...
        </div>
      }

      <div style={{ position: "relative" }}>
        {showColumnSetting ? (
          <div
            style={{
              position: "absolute",
              zIndex: 99,
              right: "0px",
              top: "48px",
              background: "#fff",
              padding: "5px",
              border: "1px solid #ddd",
            }}
            className={"overflow-panel__content"}
            ref={ref}
          >
            <div style={{ width: "280px" }}>
              <div style={{ color: "#5B6871", padding: "2px 16px", textAlign: "left" }}>{_t("displayed_information")}</div>
              <DragDropContext onDragEnd={onDragEndHandler}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <ul
                      role="menu"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {activeColumns?.map((item: any, index) => {
                        if (!item.Header) {
                          return false;
                        }
                        return (
                          <Draggable
                            draggableId={item.id}
                            key={item.id + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: '8px'
                                  }}
                                >
                                  <Draggable16 />
                                  <div onClick={(e) => e.stopPropagation()} style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                    <div style={{ transform: 'translateY(-2px)' }}>
                                      <Checkbox
                                        id={item.id}
                                        value={item.isVisible}
                                        onChange={(e) => columnHider(item.id, e)} />
                                    </div>
                                    {item.Header}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              {activeColumns.length !== 0 && deActivetedColumns.length !== 0 && <Divider></Divider>}
              <ul>
                {deActivetedColumns?.map((item: any, index) => {
                  if (!item.Header) {
                    return false;
                  }
                  return (
                    <div key={item.id + index}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "20px 1fr",
                          alignItems: "center"
                        }}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            id={item.id}
                            value={item.isVisible}
                            onChange={(e) => columnHider(item.id, e)} />
                        </div>

                        {item.Header}
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}
      </div></>
  );
};

export default ColumnSettingsMenu;