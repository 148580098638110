import { useMutation, useQuery } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANTS_BALANCE_V2,
  API_GET_MERCHANTS_BALANCE_EXPORT,
  API_GET_MERCHANTS_BALANCE_V2_NOT_CONFIGURET_GATEWAYS,
  API_GET_MERCHANTS_BALANCE_V2_All_GATEWAYS
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { MerchantsBalanceDataType, MerchantsBalanceNotConfiguretGateways, PaymentGatewaysDataType } from "modules/merchantsBalanceV2/types";
import { useToast } from "modules/toast/hooks";
import { useTrans } from "system/translations/hooks";
import { generatePath } from "react-router-dom";

const getMerchantsBalanceV2Key = "getMerchantsBalanceV2Key";
const GetMerchantsBalanceV2NotConfiguretGateways = "GetMerchantsBalanceV2NotConfiguretGateways";
const getPaymentGatewaysKey = "GetPaymentGatewaysKey";
export const useGetMerchantsBalanceV2 = (filter = {}, params: any = {}) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsBalanceV2Key, filter],
    (): Promise<MerchantsBalanceDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_BALANCE_V2, filter),
        method: "get",
      }),
    { enabled: params.enabled }
  );
};

export const useGetMerchantsBalanceV2NotConfiguretGateways = (merchantId: number,  params: any = {}) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [GetMerchantsBalanceV2NotConfiguretGateways, merchantId],
    (): Promise<MerchantsBalanceNotConfiguretGateways> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANTS_BALANCE_V2_NOT_CONFIGURET_GATEWAYS, { merchantId: `${merchantId}` }),
        method: "get",
      }),
      { enabled: params.enabled }
  );
};

export const useGetMerchantsBalanceExport = () => {
  const [apiRequest] = useApiRequest();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_BALANCE_EXPORT, data),
        method: "get",
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("export_csv_notification"),
          kind: "success",
        });
      },
    }
  );
};

export const useGetPaymentGateways = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysKey],
    (): Promise<PaymentGatewaysDataType> =>
      apiRequest({
        url: API_GET_MERCHANTS_BALANCE_V2_All_GATEWAYS,
        method: "get",
      })
  );
};