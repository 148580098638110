import { TransType } from "system/translations/types";
import { FormConfigType } from "modules/formBuilder/types";
import MultiSelect from "components/atoms/MultiSelect";
import Input from "components/atoms/Input";
import Select from "components/atoms/Select";
import InputPassword from "components/atoms/InputPassword";
import {
  CreateEditUserFormConfigParams,
} from "./types";
/*
export const tableColumns = (_t: TransType): ColumnType[] => {
  return [
    {
      Header: _t("name"),
      accessor: "name",
      id: "name1",
    },
    {
      Header: _t("email"),
      accessor: "email",
      id: "email",
      width: 230,
    },
    {
      Header: _t("role"),
      accessor: "roleName",
      id: "roleName",
    },
    {
      Header: _t("active"),
      accessor: "active",
      id: "active1",
      width: 90,
      Cell: (props: any) => {
        const status = props.row.original.active ? "active" : "deactivated";
        return (
          <>
            <InfoTag size={"sm"} status={status} label={status} />
          </>
        );
      },
    },
    {
      Header: _t("tfa"),
      accessor: "tfa",
      id: "tfa1",
      width: 90,
      Cell: (props: any) => {
        const status = props.row.original.tfa ? "active" : "deactivated";
        const label = props.row.original.tfa ? "activated" : "deactivated";
        return (
          <>
            <InfoTag size={"sm"} status={status} label={label} />
          </>
        );
      },
    },
    {
      Header: _t("last_login"),
      accessor: "lastSeen",
      id: "lastSeen",
    },
    {
      Header: _t("last_ip_session"),
      accessor: "ip",
      id: "ip",
    },
    {
      maxWidth: 40,
      Header: "",
      id: "UpdateDeleteMenu",
      Cell: (props: any) => {
        return (
          <DotsMenu
            clickParams={{
              id: props?.row?.original?.id,
              allData: props?.row?.original,
            }}
            items={props?.actionMenuItems}
          />
        );
      },
    },
  ];
};*/
/*
export const searchFormConfig = (
  _t: TransType,
  params: SearchFormConfigParams
): FormConfigType => [
  {
    component: MultiSelect,
    name: "securityGroupId",
    componentProps: {
      items: params.rolesOptions,
      label: _t("roles"),
      light: true,
      wrapClassPrefix: "menu-full-width",
    },
    columnParams: { className: "maxWidthContent" },
  },
  {
    component: MultiSelect,
    name: "merchants",
    componentProps: {
      items: params.merchantsOptions,
      label: _t("merchants"),
      light: true,
      wrapClassPrefix: "menu-full-width",
    },
    columnParams: { className: "maxWidthContent" },
  },
  {
    component: MultiSelect,
    name: "active",
    componentProps: {
      items: params.userStatusesOptions,
      label: _t("active"),
      light: true,
      wrapClassPrefix: "menu-full-width",
    },
    columnParams: { className: "maxWidthContent" },
  },
  {
    component: Search,
    name: "name",
    componentProps: {
      labelText: _t("search_by_name"),
      placeHolderText: _t("search_by_name"),
    },
    emptySpace: {
      sm: { span: 10 },
      lg: { span: 10 },
      md: { span: 10 },
    },
    columnParams: {
      md: 8,
      lg: 8,
    },
  },
  {
    component: Btn,
    name: "",
    componentProps: {
      kind: "secondary",
      type: "submit",
      label: _t("apply_filters"),
    },
    emptySpace: {},
    columnParams: {
      className: "maxWidthContent",
    },
  },
];*/

export const createEditUserFormConfig = (
  _t: TransType,
  params: CreateEditUserFormConfigParams
): FormConfigType => [
  {
    component: Input,
    name: "name",
    componentProps: {
      labelText: _t("name"),
    },
    columnParams: {
      lg: 16,
      md: 16,
    },
  },
  {
    component: InputPassword,
    name: "password",
    hidden: params.isEdit,
    componentProps: {
      labelText: _t("password"),
    },
    columnParams: {
      lg: 16,
      md: 16,
    },
  },
  {
    component: Input,
    name: "email",
    componentProps: {
      labelText: _t("email"),
    },
    columnParams: {
      lg: 16,
      md: 16,
    },
  },
  {
    component: Select,
    name: "securityGroupId",
    componentProps: {
      items: params.rolesOptions,
      labelText: _t("roles"),
      light: true,
    },
    columnParams: {
      lg: 16,
      md: 16,
    },
  },
  {
    component: MultiSelect,
    name: "merchants",
    componentProps: {
      type: "default",
      items: params.merchantsOptions,
      label: _t("merchants"),
      titleText: _t("merchants"),
      light: false,
      labelWithAll: false,
    },
    columnParams: {
      lg: 16,
      md: 16,
    },
  },
];

export const selectFieldsForSearch = [
  {label: "Name", value: "name"}, 
  {label:"Email", value: "email"}
]
