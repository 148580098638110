import { CaretDown16, CaretUp16 } from "components/atoms/IconsCreateFactory"
import React from "react";
import { useTrans } from "system/translations/hooks";
import classNames from "classnames";
import Loader from "components/atoms/Loader";


const AnalyticsTotalVolume: React.FC<any> = ({
 data,
 isLoading,
 filters,
}) => {
  const {_t} = useTrans();  

  if (isLoading) {
    return <Loader formOverlay />;
  }
  
  return (
    <div className="total-volume__container total-volume">
            <div className="total-volume__item">
              <div className="total-volume__item-title">{_t('balance')}</div>
              <div className="total-volume__item-amount">{data.balance ? data.balance.toLocaleString() : 0} {data.currency}</div>
                <div className={classNames("total-volume__item-percantage" ,
                  {
                    "total-volume__item-percantage--positive" : data.balanceChangeRelativeToPreviousPeriod > 0,
                    "total-volume__item-percantage--negative": data.balanceChangeRelativeToPreviousPeriod < 0
                  }
                  )}>
                  {data.balanceChangeRelativeToPreviousPeriod > 0 
                    ? <CaretUp16 />  
                    : <CaretDown16 /> 
                  }
                  {data.balanceChangeRelativeToPreviousPeriod
                    ? data.balanceChangeRelativeToPreviousPeriod.toLocaleString() + ' %'
                    : 0 + ' %'
                    }
                </div>
            </div>
            <div className="total-volume__item">
              <div className="total-volume__item-title">{_t('total_volume')}</div>
              <div className="total-volume__item-amount">{data.totalVolume ? data.totalVolume.toLocaleString() : 0} {data.currency}</div>
                <div className={classNames("total-volume__item-percantage" ,
                {
                  "total-volume__item-percantage--positive" : data.totalChangeRelativeToPreviousPeriod > 0,
                  "total-volume__item-percantage--negative": data.totalChangeRelativeToPreviousPeriod < 0
                }
                )}>
                  {data.totalChangeRelativeToPreviousPeriod > 0 
                    ? <CaretUp16 />  
                    : <CaretDown16 /> 
                  }
                  {data.totalChangeRelativeToPreviousPeriod
                    ? data.totalChangeRelativeToPreviousPeriod.toLocaleString() + ' %'
                    : 0 + ' %'
                  }
                </div>
            </div>
            <div className="total-volume__item">
              <div className="total-volume__item-title">{_t('total_payments')}</div>
              <div className="total-volume__item-amount">{data.depositVolume ? data.depositVolume.toLocaleString() : 0} {data.currency}</div>
                <div className={classNames("total-volume__item-percantage" ,
                {
                  "total-volume__item-percantage--positive" : data.depositChangeRelativeToPreviousPeriod > 0,
                  "total-volume__item-percantage--negative": data.depositChangeRelativeToPreviousPeriod < 0
                }
                )}>
                  {data.depositChangeRelativeToPreviousPeriod > 0 
                    ? <CaretUp16 />  
                    : <CaretDown16 /> 
                  }
                  {data.depositChangeRelativeToPreviousPeriod
                    ? data.depositChangeRelativeToPreviousPeriod.toLocaleString() + ' %'
                    : 0 + ' %'
                  }
                </div>
            </div>
            {(!filters.transactionType || filters.transactionType.includes("WITHDRAWAL")) && 
            <div className="total-volume__item">
              <div className="total-volume__item-title">{_t('total_withdrawal_volume')}</div>
              <div className="total-volume__item-amount">{data.withdrawalVolume ? data.withdrawalVolume.toLocaleString() : 0} {data.currency}</div>
                <div className={classNames("total-volume__item-percantage" ,
                {
                  "total-volume__item-percantage--positive" : data.withdrawalChangeRelativeToPreviousPeriod > 0,
                  "total-volume__item-percantage--negative": data.withdrawalChangeRelativeToPreviousPeriod < 0
                }
                )}>
                  {data.withdrawalChangeRelativeToPreviousPeriod > 0 
                    ? <CaretUp16 />  
                    : <CaretDown16 /> 
                  }
                  {data.withdrawalChangeRelativeToPreviousPeriod
                    ? data.withdrawalChangeRelativeToPreviousPeriod.toLocaleString() + ' %'
                    : 0 + ' %'
                  }
                </div>
            </div>
            }
            {/* <div className="total-volume__item">
              <div className="total-volume__item-title">Total withdrawal payments</div>
              <div className="total-volume__item-amount">169,844 EUR</div>
              <div className="total-volume__item-percantage total-volume__item-percantage--positive"><CaretUp16 /> 26.42 %</div>
            </div> */}
          </div>
  );
};

export default AnalyticsTotalVolume;
