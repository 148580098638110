import React from "react";
import { TextInput as CarbonTextInput } from "carbon-components-react";
import { Calendar16, Checkmark20, Close20 } from "../IconsCreateFactory";
import { InputProps } from "components/atoms/Input/types";
import classNames from "classnames";

const InputWrap = ({ hasWrap, children }: any) =>
  hasWrap ? (
    <div className={"field-with-btn"}>{children}</div>
  ) : (
    <>{children}</>
  );

const Input: React.FC<InputProps> = ({
  id = "input",
  labelText,
  value,
  onChange,
  hideLabel = false,
  style = {},
  placeholder,
  size = "lg",
  onRemove,
  onConfirm,
  autoFocus = false,
  isCalendar = false,
  invalid = false,
  invalidText,
  isDisabledRemove = false,
  isDisabledConfirm = false,
  ...rest
}) => {
  return (
    <InputWrap hasWrap={!!onRemove}>
      <CarbonTextInput
        autoFocus={autoFocus}
        placeholder={placeholder}
        style={style}
        invalid={invalid}
        {...rest}
        id={id}
        onChange={(e) => {
          onChange(e?.target?.value)
        }}
        value={value}
        labelText={labelText}
        size={size}
        hideLabel={hideLabel}
        autoComplete={isCalendar ? 'off': 'on'}
        invalidText={invalidText}
      />
      {onConfirm && (
        <div className={classNames("field-with-btn__btn field-with-btn__btn--confirm", isDisabledConfirm && " disabled-btn")} onClick={() => !isDisabledConfirm && onConfirm()}>
          <Checkmark20 />
        </div>
      )}
      {onRemove && (
        <div className={classNames("field-with-btn__btn field-with-btn__btn--remove", isDisabledRemove && " disabled-btn")} onClick={() => !isDisabledRemove && onRemove()}>
          <Close20 />
        </div>
      )}
      {isCalendar && !invalid && <div className={"field-with-calendar-icon"}><Calendar16/></div> }
    </InputWrap>
  );
};

export default Input;
