import { useState, useRef, useEffect } from "react";
import { Add16 } from "components/atoms/IconsCreateFactory"
import { useOutsideClick } from "system/helpers/hooks";
import classNames from "classnames";

const AddConditionsButton: React.FC<any> = ({ items, clickParams = {}, buttonText = '' }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);
  useEffect(() => {
    setShow(false);
  }, [clickOutSide]);

  return (
      <div className="add-conditions-button__container">
        <div
          onClick={() => !clickOutSide && setShow(!show)}
          className={classNames("add-conditions-button", {"add-conditions-button--with-text": buttonText})}
        >
          <Add16/>
          {buttonText && buttonText}
        </div>
        {show ? (
        <div className="add-conditions-button__overflow-panel" ref={ref}>
            {items.map(({ onClick, title }: any) => {
              return (
                <div className="add-conditions-button__overflow-panel-item" onClick={() => {
                  clickParams.item 
                  ? onClick && onClick(clickParams.item, clickParams.isFirstLevel)
                  : onClick && onClick(clickParams);
                  setShow(false)
                  }}
                >
                  {title}
                  </div>
              );
            })}
          </div>) 
          : null}
      </div>
  );
};
export default AddConditionsButton
