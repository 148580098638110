import { Column as ColumnType } from "react-table";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useUserModuleData } from "modules/users/hooks";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/users/types";
import { selectFieldsForSearch } from "./constants";
import Toggle from "components/atoms/Toggle";
import { TableColumnType } from "modules/table/types";
import { CloseOutline16 } from "components/atoms/IconsCreateFactory"

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const activeStatusColumn = [{
    maxWidth: 140,
    Header: _t("status"),
    mobileVisible: true,
    Cell: (props: any) => {
      const [activationStatus, setAtivationStatus] = useState(props?.row?.original?.active)         
      return (
        <Toggle
          key={"Toggle" + props?.row?.original?.id}
          id={"Toggle" + props?.row?.original?.id}
          value={activationStatus}
          onChange={(e) => {
            props.onActionUpdate(props?.row?.original, setAtivationStatus)
          }}
          labelA = {_t("inactive")}
          labelB = {_t("active")}
          withControlledValue = {true}
        />
      );
    },
  }]

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("name"),
        accessor: "name",
        id: "name1",
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("email"),
        accessor: "email",
        id: "email",
        width: 230,
        mobileVisible: true,
      },
      {
        Header: _t("role"),
        accessor: "roleName",
        id: "roleName",
        mobileVisible: true,
      },
      ...activeStatusColumn,
      {
        Header: _t("tfa"),
        accessor: "tfa",
        id: "tfa1",
        width: 90,
        Cell: (props: any) => {
          const status = props.row.original.tfa ? "active" : "deactivated";
          const label = props.row.original.tfa ? "activated" : "deactivated";
          return (
            <>
              <InfoTag type={"secondary"} status={status} label={label} />
            </>
          );
        },
      },
      {
        Header: _t("last_login"),
        accessor: "lastSeen",
        id: "lastSeen",
        mobileVisible: true,
      },
      {
        Header: _t("device_data"),
        accessor: "deviceData",
        id: "deviceData",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    [activeStatusColumn]
  );

  return columns;
};
const isMobile = window.matchMedia('(max-width: 671px)').matches;
export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const { rolesOptions, merchantsOptions, userStatusesOptions } =
    useUserModuleData();    
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.securityGroupId({
        componentProps: {
          items: rolesOptions,
        }, 
        valuePreview: isMobile,
      }),
      fields.filters.merchants({
        componentProps: {
          items: merchantsOptions,
        },
        valuePreview: isMobile,
      }),
      fields.filters.active({
        componentProps: {
          items: userStatusesOptions,
        },
        valuePreview: isMobile,
      }),
      fields.filters.filterWithSelectFieldForInput({
        componentProps: {
          showSearch: true,
          searchIconDescription: _t("search"),
          selectItems: selectFieldsForSearch,
          selectValue: selectFieldsForSearch[0],
        },
      }),
      fields.general.submitBtn( {emptySpace: {} }),
    ];
  }, [rolesOptions, merchantsOptions, userStatusesOptions]);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { rolesOptions, merchantsOptions } = useUserModuleData();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.name(),
      /*      {
        component: null,
        type: "array",
        name: "paymentM",
        components: [
          {
            component: Input,
            name: "",
            valuePreview: true,
            componentProps: {
              labelText: "payment_methods",
              placeHolderText: "payment_methods",
            },
            columnParams: {
              lg: 15,
              md: 15,
            },
          },
        ],
      },*/
      fields.formFields.password(/*{ hidden: params.isEdit }*/),
      fields.formFields.email(),
      fields.formFields.securityGroupId({
        componentProps: {
          options: rolesOptions,
        },
      }),
      fields.formFields.merchants({
        componentProps: {
          isOpen: true,
          options: merchantsOptions,
        },
      }),
      /*      {
        component: Input,
        name: "name",
        componentProps: {
          labelText: _t("name"),
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },
      {
        component: InputPassword,
        name: "password",
        hidden: params.isEdit,
        componentProps: {
          labelText: _t("password"),
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },
      {
        component: Input,
        name: "email",
        componentProps: {
          labelText: _t("email"),
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },
      {
        component: Select,
        name: "securityGroupId",
        componentProps: {
          items: rolesOptions,
          labelText: _t("roles"),
          light: true,
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },
      {
        component: MultiSelect,
        name: "merchants",
        componentProps: {
          type: "default",
          items: merchantsOptions,
          label: _t("merchants"),
          titleText: _t("merchants"),
          light: false,
          labelWithAll: false,
        },
        columnParams: {
          lg: 16,
          md: 16,
        },
      },*/
    ];
  }, [rolesOptions, merchantsOptions]);
  return formConfig;
};

export const useDeleteUserConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.comment({
        name: 'description',
      }),
      fields.general.saveBtn({
        emptySpace: {},
        componentProps: {
          kind: 'danger',
          label: _t("deactivate"),
          renderIcon: CloseOutline16
        },
        columnParams: {
          className: 'submit-button-modal',
        }
      })
    ];
  }, []);
  return formConfig;
}