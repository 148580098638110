import React, { useEffect } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateEditConfig } from "../constantHooks";
import Loader from "components/atoms/Loader";
import { useGetMerchantsFeesItem, useUpdateMerchantsFees } from "../apiHooks";
import { APP_ROUTES } from "system/router/constants";
import { createEditMerchantFeesValidation } from "../validation";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const history = useHistory();
  const { mutate: onUpdate, data: dataMutate } = useUpdateMerchantsFees();
  const { data, isLoading } = useGetMerchantsFeesItem(routeParams?.id);
  const formConfig = useCreateEditConfig(data);

  const onSubmit = async (data: any) => {  
    const normalize = { ...data };
    if (normalize.settlementFeeType !== _t("fixed_fee")) {
      normalize.settlementFeeCurrency = null;
    }
    delete normalize.settlementFeeType;

    onUpdate({ data: normalize, id: routeParams?.id });
  };

  useEffect(() => {
    if (dataMutate) {
      const link = generatePath(APP_ROUTES.merchantsBalanceV2.merchantsFees.update, {
        merchantId: dataMutate.merchantId,
        id: dataMutate.id,
      });
      history.replace(link);
    }
  }, [dataMutate]);

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("{title_name}", {
            title_name: `${data?.paymentGatewayName}`,
          })}>
            <FormBuilder
              formItemsConfig={formConfig.slice(3)}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("save_changes"),
                onSubmit: onSubmit,
                initialValues: {
                  ...data,
                  settlementFeeType: !!data?.settlementFeeCurrency ? _t("fixed_fee") : _t('percentage'),
                },
                validationSchema: createEditMerchantFeesValidation(_t, {
                  isEdit: true,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;
