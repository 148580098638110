import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { MerchantsConfigurationItemType, UseModuleDataRes, UseModuleDataResCreate } from "./types";
import { useGetSetting } from "modules/settings/apiHooks";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { useCreateManualRate, useDeleteMerchantsConfigurationsManualRateItem, useGetMerchantsConfigurations, useGetPaymentGatewaysByMerchant, useUpdateMerchantsConfigurationsManualRate } from "./apiHooks";
import { TransType } from "system/translations/types";
import { useTrans } from "system/translations/hooks";
import { useModal } from "modules/modal/hooks";
import NotClosedPeriod from "components/molecules/NotClosedPeriod";
import ConfirmModalButtons from "components/atoms/ConfirmModalButtons";
import { Checkmark16, Save16, TrashCan16 } from "components/atoms/IconsCreateFactory"
import { useGetPaymentGateways } from "modules/merchantsBalanceV2/apiHooks";
import { MerchantNameListItemType } from "modules/merchants/types";
import Btn from "components/atoms/Btn";
import { useEffect, useMemo, useState } from "react";
import { TableColumnType } from "modules/table/types";
import { useGetErrorToaster } from "system/helpers/hooks";
import { useEditConfig } from "./constantHooks";
import Table from "modules/table";
import { Button } from "carbon-components-react";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { UseMutateFunction } from "react-query";
import { ErrorType } from "system/helpers/types";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";

export const useModuleData = (): UseModuleDataRes => {
  const { data: currencies = [] } = useGetSetting('currencies');

  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });

  return { currenciesOptions };
};

export const useModuleDataCreate = (merchant?: MerchantNameListItemType): UseModuleDataResCreate => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGatewaysByMerchant = [] } = useGetPaymentGatewaysByMerchant(merchant?.id);
  const { data: paymentGatewaysAll = [] } = useGetPaymentGateways();
  const { data: merchantConfigurationByMerchant = {} } = useGetMerchantsConfigurations({ groupingType: 'merchants' });
  const { data: currencies = [] } = useGetSetting('currencies');

  const currenciesOptions = arrayOptionsMap(currencies, {
    labelKey: "codeLiteral",
    valueKey: "codeLiteral",
  });

  const parentGatewaysWithConfiguration = !!merchant && !!merchantConfigurationByMerchant?.[merchant?.name]
    ? merchantConfigurationByMerchant[merchant?.name]
      .filter(configuration => !configuration?.parentPaymentGatewayCode)
      .map(configuration => ({
        label: configuration.paymentGatewayName,
        value: configuration
      }))
    : []
    
  const paymentGatewaysOptionsByMerchant = paymentGatewaysByMerchant.map((item) => ({
    label: item.value,
    value: item,
  }));

  const paymentGatewaysOptionsAll = paymentGatewaysAll.map((item) => ({
    label: item.label,
    value: {
      label: item.value,
      value: item.label
    },
  }));

  const merchantsOptions = merchantsList.map((item) => ({
    label: item.name,
    value: item,
  }));


  return {
    merchantsOptions,
    paymentGatewaysOptionsByMerchant,
    paymentGatewaysOptionsAll,
    currenciesOptions,
    parentGatewaysWithConfiguration
  };
};

export const useGetFieldNamesManualRate =  (_t: TransType) => {
  return {
    currencyFrom: _t("currency") + ' from',
    currencyTo: _t("currency") + ' to',
    rate: _t("rate_"),
    dateFrom:  _t("start_period"),
    dateTo: _t("end_period"),
  }
}

export const useGetWarningText = () => {
  const { _t } = useTrans()
  const { showModal, hideModal } = useModal()
  const openWarning = (onSubmit: any) => {
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'xs',
        smallModal: true,
        componentProps: {
          data: [],
          titleText:  _t('be_careful'),
          secondaryText:  _t('conversion_can_be_set_once'),
          isClosePeriod: false,
        },
        footer: ConfirmModalButtons,
        footerProps:  {
              onOkText: _t('submit'),
              onCancel: () => hideModal(),
              onOk: () => {
                onSubmit()
                hideModal()
              },
              onOkKind: "primary",
              className: 'small-modal-full-width-buttons',
              renderIcon: Checkmark16
            }
      });
  }

  const openParentGatewayChangeWarning = () => {
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'sm',
        smallModal: true,
        componentProps: {
          data: [],
          titleText:  _t('be_careful'),
          secondaryText:  _t('when_changed_parent_gateway'),
          isClosePeriod: false,
        },
        footer: Btn,
        footerProps:  {
          label: _t('on_ok'),
          onClick: () => hideModal(),
          renderIcon: Checkmark16,
          kind: "primary",
        }
      });
  }

  return { openWarning, openParentGatewayChangeWarning }
}


export const useGetHelpers = ({
  columns,
  data,
  manualRates,
  manualRatesLoading,
  onUpdate
}: {
  columns: TableColumnType[],
  data: MerchantsConfigurationItemType | undefined
  manualRates: any,
  manualRatesLoading: boolean,
  onUpdate: UseMutateFunction<any, ErrorType, any, unknown>
}) => {
  const { _t } = useTrans();
  const { showModal, updateModal } = useModal()
  const { showErrorToastMessage } = useGetErrorToaster()
  const { parentGatewaysWithConfiguration } = useModuleDataCreate({ id: data?.merchantId || 0, name: data?.merchantName || ''})
  const [ configurationType, setConfigurationType ] = useState('rollingReserve')
  const [ initialValues, setInitialValues] = useState({})
  const [ newManualRates, setNewManualRates ] = useState<any>([])
  const { mutate: onCreate } = useCreateManualRate();
  const { mutate: onDelete } = useDeleteMerchantsConfigurationsManualRateItem();
  const { mutate: updateManualRate } = useUpdateMerchantsConfigurationsManualRate()
  const formConfig = useEditConfig(initialValues, configurationType, setConfigurationType, setInitialValues);
  const { openWarning } = useGetWarningText()
  const fieldNamesManualRate: any = useGetFieldNamesManualRate(_t)
  const { onConfirm } = useConfirmModal();

  useEffect(() => {
    if (manualRates && newManualRates && (manualRates.length < newManualRates.length) && !newManualRates[0].id ) {
      setNewManualRates([newManualRates[0],...manualRates])
    } else {
      setNewManualRates(manualRates)
    }
  }, [manualRates])

  const onUpdateCreatedManualRate = async (data: any) => {
    newManualRates[0] = data.data
    setNewManualRates([...newManualRates])
    const requiredValuesTocheck = {
      ...newManualRates[0]
    }
    delete requiredValuesTocheck.rateCorrelation
    
    Object.values(requiredValuesTocheck).every((item: any) => item) && onCreate(newManualRates[0])
  }

  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => !id ? setNewManualRates(newManualRates.slice(1)) : onDelete({ id }) });
  };

  useEffect(() => {
    const selectedGatewayOption = parentGatewaysWithConfiguration.find(parentGateway => parentGateway.value.paymentGatewayCode === data?.parentPaymentGatewayCode)
    const updatetInitialValues = {
      ...data,
      activeConvertCurrency: !!data?.convertCurrency,
      configurationType,
      enableParentGatewayLinkage: !!data?.parentPaymentGatewayCode,
      parentPaymentGatewayCode: selectedGatewayOption?.value,
      parentPaymentGatewayName: selectedGatewayOption?.label
    }
    setInitialValues({
      ...updatetInitialValues,
      initialValues: updatetInitialValues
    })
  }, [data,JSON.stringify(parentGatewaysWithConfiguration) ])

  useEffect(()=> {
    updateModal(
      {
        componentProps: {
          columns,
          isLoading: manualRatesLoading,
          data: newManualRates,
          cellProps: {
            onUpdateCreatedManualRate,
            actionMenuItems,
            onUpdateManualRate
          }
        },
        modalHeading: _t("manual_rates"),
        component: Table,
        footer: Button,
        footerProps: {
          style: {
            display: 'none'
          }
        }
      }, 'manualRates'
    );
  }, [newManualRates])

  const onUpdateManualRate = async (data: any) => {
    updateManualRate(data)
  }

  const showAllManualRates = () => {
    showModal(
      {
        componentProps: {
          columns,
          isLoading: manualRatesLoading,
          data: newManualRates,
          cellProps: {
            onUpdateCreatedManualRate,
            actionMenuItems,
            onUpdateManualRate
          }
        },
        modalHeading: _t("manual_rates"),
        component: Table,
        footer: Button,
        footerProps: {
          style: {
            display: 'none'
          }
        }
      }, 'manualRates'
    );
  };

  const newManualRate = {
    merchantId: data?.merchantId,
    paymentGateway: data?.paymentGatewayCode,
    currencyFrom: null,
    currencyTo: null,
    rate: null,
    rateCorrelation: null,
    dateFrom: null,
    dateTo: null,
  }

  const addManualRate = () => {
    if (manualRates.length === newManualRates.length) {
      setNewManualRates([newManualRate, ...manualRates])
    }
  }


  const showErrorForNotUpdatedForm = (formData: any, isRatesAreNotFilled: boolean = false) => {
    if (JSON.stringify(data) === JSON.stringify(formData) && configurationType !== 'exchangeRate') {
      showErrorToastMessage(_t('no_changes_to_save_try_again'))
      return true
    } else if(JSON.stringify(data) === JSON.stringify(formData) && isRatesAreNotFilled) {
      showErrorToastMessage(_t('no_changes_to_save_try_again'))
      return true
    }
    return false
  }

  const pageTitleName = _t("{title_name}", data?.parentPaymentGatewayName 
  ? { title_name: `${data?.merchantName || ''}: ${data?.paymentGatewayName || ''} depends on ${data?.parentPaymentGatewayName || ''}`}
  : { title_name: `${data?.merchantName || ''}: ${data?.paymentGatewayName || ''}`}
)

const actionMenuItems: DotsMenuItem[] = [
  {
    title: _t("delete"),
    onClick: onDeleteWithConfirm,
    IconComponent: TrashCan16,
    type: "danger",
  },
];


const checkDataBeforeSubmit = (normalize: any) => {
  if (showErrorForNotUpdatedForm(normalize, manualRates && newManualRates && (manualRates.length === newManualRates.length) && configurationType === 'exchangeRate')) {
    return
   }
    if (manualRates && newManualRates && (manualRates.length < newManualRates.length) && configurationType === 'exchangeRate') {
      const fieldsToCheck = {...newManualRates[0]}
      delete fieldsToCheck.rateCorrelation

      const lostFields = Object.keys(fieldsToCheck).filter((key: any) => !fieldsToCheck[key]).map((item: string) => fieldNamesManualRate[item])
      onConfirm({ 
        onOk: () => {
          if (showErrorForNotUpdatedForm(normalize, true)) {
            return
           }
          onUpdate(normalize)
        },
        modalHeading: _t('warning'),
        onOkText: _t('save'),
        onCancelText: _t('back_to_edit'),
        onOkKind: 'primary',
        renderIcon: Save16,
        bodyText: _t("merchant_configuration_exchange_warning_{lostFields}", {
          lostFields: lostFields.join(', ')
        })      
       });
    } else {
      !!normalize.convertCurrency && !data?.convertCurrency
        ? openWarning(() => onUpdate(normalize)) 
        : onUpdate(normalize)
    }
}

const tableCellProps = useMemo(() => ({
  onUpdateCreatedManualRate,
  actionMenuItems,
  onUpdateManualRate
}), [ onUpdateCreatedManualRate, actionMenuItems, onUpdateManualRate])


  return {
    initialValues,
    showAllManualRates,
    pageTitleName,
    formConfig,
    configurationType,
    addManualRate,
    newManualRates,
    checkDataBeforeSubmit,
    tableCellProps
  }
}
