import React, { useEffect, useState } from "react";
import { Search as CarbonSearch, Button } from "carbon-components-react";
import { Search16 } from "../IconsCreateFactory";
import Select from "../Select";
import { SearchWithFieldSelectProps } from "./types";
import "../../../styles/atoms/searchWithFieldSelect.scss"

const SearchWithFieldSelect: React.FC<SearchWithFieldSelectProps> = ({
  id = "inputWithSelect",
  labelText,
  placeHolderText,
  inputValue,
  selectValue,
  onChange,
  showSearch = false,
  disabled = false,
  searchIconDescription,
  formOnSubmit,
  size = "xl",
  selectItems,
  ...rest
}) => {
  const [inputValueState, setInputValueState] = useState(inputValue);
  const [selectValueState, setSelectValueState] = useState(selectValue);

  const handleSubmit = () => {
    selectValueState && 
    (typeof(inputValueState) === "string") && 
    onChange({[selectValueState.value]: inputValueState
    })
  }

  useEffect(() => {
    handleSubmit()
  }, [inputValueState, selectValueState.value])

  return <div className="search-with-field-select__container">
    <CarbonSearch
      id={id}
      onChange={(e) => setInputValueState(e?.target?.value)}
      value={inputValueState}
      labelText={labelText}
      renderIcon={<></>}
      onClear={formOnSubmit}
      size={size}
      placeHolderText={placeHolderText || ""}
      disabled={disabled}
    />
    <Select
      id = "Select"
      onChange ={(val) => 
        setSelectValueState(selectItems.find((item: any) => item.value === val)
      )}
      items = {selectItems}
      value = {selectValueState.value}
      hideLabel = {true}
      disabled = {disabled}
    />
    {showSearch && <Button
      style={{ color: "#000" }}
      className={"bx--search-button"}
      type={"submit"}
      hasIconOnly
      iconDescription={searchIconDescription}
      renderIcon={Search16}
      disabled={disabled}
      onClick = {() => handleSubmit()}
    />}
  </div>
}

export default SearchWithFieldSelect;