import { useGetRoles } from "modules/roles/apiHooks";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useConstants } from "system/helpers/hooks";
import { UseUserModuleDataRes } from "modules/users/types";
import { useTrans } from "system/translations/hooks";
import { useModal } from "modules/modal/hooks";
import ModalBodyWithForm from "components/atoms/ModalBodyWithForm";
import { Button } from "carbon-components-react";
import { Close16 } from "components/atoms/IconsCreateFactory"
import { FormConfigType } from "modules/formBuilder/types";

export const useUserModuleData = (): UseUserModuleDataRes => {
  const { userStatuses } = useConstants();
  const { data: rolesData = [] } = useGetRoles();
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const rolesOptions = arrayOptionsMap(rolesData, {
    labelKey: "name",
    valueKey: "id",
  });
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });
  const userStatusesOptions = arrayOptionsMap(userStatuses, {
    labelKey: "label",
    valueKey: "value",
  });

  return { rolesOptions, merchantsOptions, userStatusesOptions };
};

export const useDeleteUserModal = (deleteUserFormConfig: FormConfigType, onUpdate: any) => {
  const { _t } = useTrans();
  const { showModal, hideModal } = useModal()

  const onDeleteUser = async (data: any, onUpdateToggleState?: any) => {
    onUpdate({ 
      data: {...data, active: false}, 
      id: data.id
    })
    hideModal('deleteWithCommentModal')
    onUpdateToggleState && onUpdateToggleState(false) 
  }
  
  const OnDeleteWithComment = (userData: any, onUpdateToggleState?: any) => {    
    showModal({
      size: "sm",
      modalHeading:  _t("deactivating_of_{user}_header_text", {user: userData.name || ''}),
      component: ModalBodyWithForm,
      componentProps: {
        text: _t("deactivating_of_user_body_text"),
        formBuilderProps: {
          formItemsConfig: deleteUserFormConfig,
          showSubmit: false,
          formProps: {
            onSubmit: onUpdateToggleState ? (data: any) => onDeleteUser(data, onUpdateToggleState) : onDeleteUser,
            initialValues: {
              ...userData,
            }
        },
      }},
      isShowScale: false,
      footer: Button,
      footerProps: {
        onClick: () => hideModal('deleteWithCommentModal'),
        children: _t("cancel"),
        renderIcon: Close16,
        kind: "tertiary",
        className: "cancel-modal-button"
      }
    }, 'deleteWithCommentModal')
  }

  return { OnDeleteWithComment }
}
