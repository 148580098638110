import axiosClient from "./axiosConfig";
import { getToken, removeToken } from "system/helpers/helperFunctions";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import { useEffect } from "react";
import { queryClient } from "App";
import { useModal } from "modules/modal/hooks";

export const useApiRequest = () => {
  const history = useHistory();
  const { hideModal } = useModal();
  const apiRequest = (params: any) =>
    axiosClient
      .request(params)
      .then((r: any) => r.data)
      .catch((err: any) => {
        if (err?.response?.status === 401) {
          history.push(APP_ROUTES.login);
          removeToken();
          queryClient.invalidateQueries();
          hideModal();
        }
        throw {
          errorData: err?.response?.data,
          status: err?.response?.status,
        };
      });

  return [apiRequest];
};

export const useApiInterceptor = () => {
  const history = useHistory();
  useEffect(() => {
    axiosClient.interceptors.request.use((config: any) => {
      const token = getToken();
      if (token) {
        config.headers["authorization"] = token;
      }
      return config;
    });

    axiosClient.interceptors.response.use(
      (response) => {
        const modifiedRes = { ...response };
        if (response.headers.hasOwnProperty("x-total-count")) {
          modifiedRes.data = {
            data: response.data,
            total: response.headers["x-total-count"],
          };
          //perform the manipulation here and change the response object
        }
        if (response.status === 401) {
          history.push(APP_ROUTES.login);
          removeToken();
        }
        return modifiedRes;
      },
      (error) => {
        return Promise.reject(error.message);
      }
    );
  }, []);
};
