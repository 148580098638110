import React from "react";
import { RecentlyViewed16 } from "../IconsCreateFactory";
import { useGetMerchantsFeesHistory, useGetMerchantsFeesHistoryByField } from "modules/MerchantsFees/apiHooks";
import { formatDistanceToNowStrict } from "date-fns";
import Loader from "../Loader";

export const HistoryLink: React.FC<any> = ({showHistory, historyLinkProps }) => {
  const {merchantId , paymentGateway , fieldName} = historyLinkProps
  const {data = [], isLoading} = useGetMerchantsFeesHistoryByField(merchantId , paymentGateway, fieldName)

 if (isLoading) {
  return <Loader />
 }

  const sortData = [...data];
  let timeDiferent = ''
  if (data.length) {
    timeDiferent = formatDistanceToNowStrict(new Date(sortData[0]?.updatedDate))
  }

  return data.length > 0 
    ? (
    <div className="history-link" onClick={() => showHistory({data: sortData, isLoading})}>
      <RecentlyViewed16 style={{color: 'black'}}/>
        <div className="history-link__text"> Last edit was made {timeDiferent} ago by {sortData[0]?.updatedBy || ''} </div>
      </div>
    ) : null
}
