import { Delete16, Edit16 } from "../IconsCreateFactory";
import { Tooltip } from "carbon-components-react";
import classNames from "classnames";
import { OrchestrationFormConfigCallBack } from "modules/routingRules/types";
import { createEditAttributeValidation } from "modules/routingRules/validation";
import { useRef, useState } from "react";
import { useTrans } from "system/translations/hooks";
import ChangeValueDialogWindow from "../ChangeValueDialogWindow";
import EditableTitle from "../EditableTitle";

const PaymentOrchestrationTreeViewItem: React.FC<{
  title?: string;
  isDeleted?: boolean;
  parametrs?: any;
  data?: any;
  isEdit?: boolean;
  isAuthType?: boolean;
  type: string;
  onRemove?: any;
  onUpdate?: any;
  onClearValues?: any;
  getOrchestartionFormConfig: OrchestrationFormConfigCallBack;
  onTitleChange?: (newTitleValue: string) => void;
}> = ({
  title = "Attribute 1: Auth type",
  isDeleted = true,
  parametrs = "",
  data = [],
  isEdit = true,
  isAuthType = false,
  type,
  onRemove,
  onUpdate,
  onClearValues,
  getOrchestartionFormConfig,
  onTitleChange,
}) => {
  const { _t } = useTrans();
  const formConfig = getOrchestartionFormConfig(type, data);
  const validation = createEditAttributeValidation(_t, type);
  
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const editIconRef = useRef<any>(null);
  const titleRef = useRef<any>(null);

  const isEditableTitle = type === "condition" || type === "conditionGroup";

  const handleTitleSubmit = () => {
    if (updatedTitle && updatedTitle !== title && onTitleChange) {
      onTitleChange(updatedTitle);
    }
    if (updatedTitle) {
      setIsEditingTitle(false);
    }
  };

  const handleMouseEnter = () => {
    showEditIcon();
    checkForOverflow();
  };

  const handleMouseLeave = () => {
    hideEditIcon();
    setTooltipOpen(false);
  };

  const showEditIcon = () => {
    if (editIconRef.current) {
      editIconRef.current.style.visibility = "visible";
    }
  };

  const hideEditIcon = () => {
    if (editIconRef.current) {
      editIconRef.current.style.visibility = "hidden";
    }
  };

  const checkForOverflow = () => {
    if (titleRef.current) {
      const { scrollWidth, clientWidth } = titleRef.current;
      if (scrollWidth > clientWidth) {
        setTooltipOpen(true);
      }
    }
  };

  return (
    <div className={classNames("tree-view-item", type === "authType" && "tree-view-item-authType")}>
      <div className="tree-view-item__top" onDoubleClick={() => !isEditingTitle && setIsEditingTitle(true)}>
        {isEditingTitle && isEditableTitle ? (
          <EditableTitle
            title={title}
            changedTitle={updatedTitle}
            onTitleChange={setUpdatedTitle}
            onSave={handleTitleSubmit}
          />
        ) : (
          <>
            {isEditableTitle ? (
              <div
                className="tree-view-item__top-with-edit-icon"
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave}
              >
                <div className="tree-view-item__top-with-edit-icon-text" ref={titleRef}>
                  {updatedTitle}
                </div>
                <Tooltip
                  menuOffset={{ left: -75, top: 10 }}
                  direction={"top"}
                  autoOrientation
                  showIcon={false}
                  open={isTooltipOpen}
                  className="comment-tooltip"
                >
                  {title}
                </Tooltip>
                <div
                  style={{ visibility: "hidden" }}
                  className="tree-view-item__top-with-edit-icon__icon-container"
                  ref={editIconRef}
                  onClick={() => !isEditingTitle && setIsEditingTitle(true)}
                >
                  <Edit16/>
                </div>

              </div>
            ) : (
              <div>{updatedTitle}</div>
            )}
            {isDeleted && <Delete16 className="tree-view-item__top-remove-btn" onClick={onRemove} />}
          </>
        )}
      </div>
      <div className="tree-view-item__bottom">
        {isAuthType ? (
          <div className="tree-view-item__bottom-text">{parametrs}</div>
        ) : (
          <ChangeValueDialogWindow
            formConfig={formConfig}
            text={parametrs}
            initialValues={data}
            onUpdate={onUpdate}
            type={type}
            isEdit={isEdit}
            onClearValues={onClearValues}
            validation={validation}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentOrchestrationTreeViewItem;
