import React from "react";
import { Content } from "carbon-components-react";
import { useTrans } from "../../system/translations/hooks";
import {includesBackgroundRoutes} from "../../modules/auth/constants";
import {useRouteMatch} from "react-router-dom";
import classNames from "classnames";

const NotLoggedTemplate = ({ children }: any) => {
  const { _t } = useTrans();
  const routeInclude = useRouteMatch(includesBackgroundRoutes)?.isExact;
  const currentYear = new Date().getFullYear();

  return (
    <div className={classNames(routeInclude && "auth--bg", "auth")}>
      <Content>{children}</Content>
      <div className={"auth__copyright"}>
        Pingwi Payment Gateway &copy;{currentYear} - {_t("all_rights_reserved")}
      </div>
    </div>
  );
};

export default NotLoggedTemplate;
