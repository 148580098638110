import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createTransactionValidation = (_t: TransType ) => {
  return Yup.object().shape({
    amount: Yup.number().required(_t("required")).moreThan(0).typeError(_t("must_be_a_number")),
  });
};

export const createManualAdjustmentValidation = (_t: TransType, isMovement: boolean = false ) => {
  if (isMovement) {
    return Yup.object().shape({
      amount: Yup.number().when('adjustmentType', {
        is: (adjustmentType: any) =>  adjustmentType === "decreaseBalance",
        then: (schema) =>  Yup.number().required(_t("required")).lessThan(0, _t("less_than_movement_error")).typeError(_t("must_be_a_number")),
        otherwise: (schema) => Yup.number().required(_t("required")).moreThan(0, _t("more_than_movement_error")).typeError(_t("must_be_a_number"))
      }),
      comment: Yup.string().trim().required(_t("can_not_be_empty"))
    });
  }
  return Yup.object().shape({
    amount: Yup.number().required(_t("required")).moreThan(0).typeError(_t("must_be_a_number")),
    comment: Yup.string().trim().required(_t("can_not_be_empty"))
  });
};

export const createTransferAdjustmentValidation = (_t: TransType, isTransfer: boolean = false ) => {
    if (isTransfer) {
      return Yup.object().shape({
        amount: Yup.number().required(_t("required")).lessThan(0).typeError(_t("must_be_a_number")),
        comment: Yup.string().trim().required(_t("can_not_be_empty"))
    });
  }

    return Yup.object().shape({
      amount: Yup.number().required(_t("required")).moreThan(0).typeError(_t("must_be_a_number")),
      comment: Yup.string().trim().required(_t("can_not_be_empty"))
    });
};
