import React, { useMemo } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { useFilters, useListPagination, useFEFilter } from "system/helpers/hooks";

import {
  useGetMerchants,
  useGetSubMerchants,
} from "modules/merchants/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/merchants/constantHooks";
import { CheckMerchantPathArgs, MerchantsProps } from "modules/merchants/types";
import config from "../../system/config";
import Info from "modules/merchants/components/Info";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import {
  Add16,
  Carbon16,
  CloseOutline16,
  Edit16,
  LogoSlack16,
  Purchase16,
  Security16,
  SubtractAlt16,
} from "components/atoms/IconsCreateFactory"
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";
import { useIsSubMerchants } from "modules/merchants/hooks";
import { getUrl } from "system/helpers/helperFunctions";

const Merchants: React.FC<MerchantsProps> = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const isSubMerchants = useIsSubMerchants();
  const checkMerchantPath = ({
    merchantPath,
    subMerchantPath,
  }: CheckMerchantPathArgs) => {
    return isSubMerchants ? subMerchantPath : merchantPath;
  };
  const { showModal } = useModal();
  const { filters, setFilters } = useFilters({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const isMobile = window.matchMedia('(max-width: 671px)').matches;

  const { data: merchantsData = [], isLoading: isLoadingMerchants } = 
    useGetMerchants();
  const { data: subMerchantsData = [], isLoading: isLoadingSubMerchants } = 
    useGetSubMerchants({}, routeParams?.merchantId, isSubMerchants);
  const data = useMemo(
    () => (isSubMerchants ? subMerchantsData : merchantsData),
    [subMerchantsData, merchantsData]
  );
  const filteredData = useFEFilter(data, filters);
  const isLoading = isSubMerchants ? isLoadingSubMerchants : isLoadingMerchants;
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const showDetails = (id: string, label: string) => {
    showModal({
      modalHeading: _t("{form_name}", {
        form_name: `${label}`,
      }),
      isShowScale: !isMobile,
      componentProps: {
        id: id,
      },
      component: Info,
    });
  };

  const getUrlPath = (
    merchantsType: "whitelist" | "payments" | "blacklist", 

  ) => {
    return (id: number) => {
      const path = checkMerchantPath({
        merchantPath: APP_ROUTES.merchants[merchantsType]?.index,
        subMerchantPath: APP_ROUTES.merchants.sub[merchantsType]?.index,
      });
      const link =  generatePath(path, {
        merchantId: id,
      });
      return link
    }
  }

  const goByUrlPath = (merchantsType: "whitelist" | "payments" | "blacklist") => {
    return ({ id }: any) => {
      const link = getUrlPath(merchantsType)(id);
      history.push(link, history.location);
    }
  }
  
  const onFilterSubmit = async (data: any) => {
    setPagination(config.defaultInitialPagination);
    setFilters(data);
  };
  const onUpdate = ({ id }: any) => {
    const path = checkMerchantPath({
      merchantPath: APP_ROUTES.merchants.update,
      subMerchantPath: APP_ROUTES.merchants.sub.update,
    });
    const link = generatePath(path, { id });
    history.push(link, history.location);
  };
  const onCreateSubMerchant = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchants.sub.create, { id });
    history.push(link, history.location);
  };
  const onSubMerchant = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchants.sub.index, {
      merchantId: id,
    });
    history.push(link, history.location);
  };
  const onCreateSlackChannel = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchants.slackChannel.create, {
      merchantId: id,
    });

    history.push(link, history.location);
  };
  const onDeactivate = ({ id, allData }: any) => {
    onUpdate({ id });
  };

  const merchantActions: DotsMenuItem[] = [
    {
      title: _t("sub_merchant"),
      onClick: onSubMerchant,
      getLink: ({id}: any) => generatePath(APP_ROUTES.merchants.sub.index, {
        merchantId: id,
      }),
      icon: <Carbon16 />,
      accessTo: "ROLE_VIEW_SUBMERCHANT",
    },
    {
      title: _t("add_sub_merchant"),
      onClick: onCreateSubMerchant,
      getLink: ({id}: any) => generatePath(APP_ROUTES.merchants.sub.create, { id }),
      icon: <Add16 />,
      type: "secondary",
      accessTo: "ROLE_MODIFY_SUBMERCHANTS",
    },
    {
      title: _t("setup_slack_channel"),
      onClick: onCreateSlackChannel,
      getLink: ({id}: any) => generatePath(APP_ROUTES.merchants.slackChannel.create, {
        merchantId: id,
      }),
      icon: <LogoSlack16 />,
      accessTo: "ROLE_MODIFY_MERCHANT_SLACK_CHANNELS",
    },
  ];
  let actions: DotsMenuItem[] = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.merchants.update, id),
      icon: <Edit16 />,
      accessTo: !isSubMerchants
        ? "ROLE_MODIFY_MERCHANTS"
        : "ROLE_MODIFY_SUBMERCHANTS",
    },
    {
      title: _t("payment_methods"),
      onClick: goByUrlPath("payments"),
      getLink: ({id}: any) => getUrlPath("payments")(id),
      icon: <Purchase16 />,
      accessTo: !isSubMerchants
        ? "ROLE_VIEW_MERCHANT_PM"
        : "ROLE_MODIFY_SUBMERCHANTS",
    },
    {
      title: _t("ip_whitelisting"),
      onClick: goByUrlPath("whitelist"),
      getLink: ({id}: any) => getUrlPath("whitelist")(id),
      icon: <Security16 />,
      accessTo: !isSubMerchants
        ? "ROLE_MODIFY_MERCHANT_WHITELIST"
        : "ROLE_MODIFY_SUBMERCHANTS",
    },
    {
      title: _t("blacklist"),
      onClick:  goByUrlPath("blacklist"),
      getLink: ({id}: any) => getUrlPath("blacklist")(id),
      icon: <SubtractAlt16 />,
      accessTo: !isSubMerchants
        ? "ROLE_MODIFY_MERCHANT_BLACKLIST"
        : "ROLE_MODIFY_SUBMERCHANTS",
    },
    {
      title: _t("deactivate"),
      onClick: onDeactivate,
      icon: <CloseOutline16 />,
      type: "danger",
      accessTo: !isSubMerchants
        ? "ROLE_MODIFY_MERCHANTS"
        : "ROLE_MODIFY_SUBMERCHANTS",
    },
  ];

  const actionMenuItems = useMemo(() => {
    if (!isSubMerchants) {
      return [...actions, ...merchantActions];
    }
    return actions;
  }, [actions, merchantActions]);

  return (
    <Card
      title={_t("merchants")}
      extra={
        <>
          <RoleWrapper
            accessTo={!isSubMerchants ? "ROLE_CREATE_MERCHANTS" : "RESTRICTED"}
          >
            {isMobile
            ? <ButtonLink
                className="button-create__link"
                isExpressive
                renderIcon={Add16}
                linkTo={
                  isSubMerchants
                    ? generatePath(APP_ROUTES.merchants.sub.create, {
                        id: routeParams?.merchantId,
                      })
                    : APP_ROUTES.merchants.create
                }
              />
            : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={
                isSubMerchants
                  ? generatePath(APP_ROUTES.merchants.sub.create, {
                      id: routeParams?.merchantId,
                    })
                  : APP_ROUTES.merchants.create
              }
              title={_t("create_merchant")}
            />
          }
          </RoleWrapper>
        </>
      }
    >
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: {},
        }}
      />
      <Table
        columns={columns}
        isLoading={isLoading}
        data={filteredData || []}
        totalRecords={filteredData.length}
        isPagination
        cellProps={{
          showDetails,
          actionMenuItems,
        }}
      />
    </Card>
  );
};

export default Merchants;
