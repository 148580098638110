import React from "react";
import { Button } from "carbon-components-react";
import { FlightInternational16 } from "components/atoms/IconsCreateFactory"
import { useGetKibanaTransactionUrl } from "modules/transactions/apiHooks";
import { useTrans } from "system/translations/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";

const TransactionLog: React.FC<{ transactionUUID: string }> = ({ transactionUUID }) => {
  const { _t } = useTrans();
  const { mutateAsync: onGetKibanaTransactionUrl, isLoading: isLoading } = useGetKibanaTransactionUrl()

  const onTransactionLogButtonClick = async () => {
    const response = await onGetKibanaTransactionUrl(transactionUUID)
    if (response && typeof response === 'string') {
      window.open(response, "_blank")
    }
  };

  return (
    <Button
      kind="tertiary"
      onClick={onTransactionLogButtonClick}
      renderIcon={FlightInternational16}
      disabled={isLoading}
    >
      {_t("transaction_logs")}
    </Button>
  );
};

const TransactionLogWrapped = (props: any) => {
  return (
    <RoleWrapper accessTo={"ROLE_VIEW_LOG_BUTTONS"}>
      <TransactionLog {...props} />
    </RoleWrapper>
  );
};

export default TransactionLogWrapped;
