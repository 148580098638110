import React from "react";
import { DataBase32 } from "../IconsCreateFactory";

const NoData: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className={"no-data"}>
      <div className={"no-data__icon-wrap"}>
        <DataBase32 color={"#CFD7DD"} />
      </div>
      <div className={"no-data__text"}>{text}</div>
    </div>
  );
};

export default NoData;
