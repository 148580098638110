import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip } from "carbon-components-react";
import { Close16, Edit16 } from "components/atoms/IconsCreateFactory"
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useOutsideClick, useOutsideClickWithoutModal } from "system/helpers/hooks";
import classNames from "classnames";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { Edit } from "@carbon/icons-react";

const ChangeValueTooltip: React.FC<any> = ({
  formConfig,
  dataValue,
  comment = '',
  initialValues,
  isComment = false,
  bigTooltipClass = '',
  onUpdate = () => {},
  accessTo = "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  validation,
  isCommentTooltipChanger = false
}) => {
  const { _t } = useTrans();
  const [adding, setAdding] = useState(false);
  const ref = useRef(null)
  const cellRef = useRef<any>(null)
  const refContainer = useRef<any>(null)
  const [clickOutSide] = useOutsideClickWithoutModal(ref);
  const [ clickOutSideWithModal ] = useOutsideClick(ref);
  
  const openHandler = () => {
    if (!adding && (!document.querySelector('.bx--tooltip--shown') || isCommentTooltipChanger) && !clickOutSideWithModal && !clickOutSide) {
      setAdding(true)
    }
  }

  useEffect(() => {
    const dateRangeBody = document.querySelector('.date-range__body')
    if ((bigTooltipClass.includes("popover-tooltip-modal")  && clickOutSideWithModal || clickOutSide) && !dateRangeBody) {
      setAdding(false);
      iconOnLeave(cellRef)
    } 

  }, [clickOutSide, clickOutSideWithModal])

  const onSubmit = async (data: any) => {
    onUpdate({data: data, id: data.id});
    setAdding(false)
  };

  const iconOnHover = (ref: any) => {
    if (ref.current) {
      ref.current.style.visibility = 'visible'
    }
  }
  const iconOnLeave = (ref: any) => {
      if (ref.current) {
        ref.current.style.visibility = 'hidden'
      }
  }
  return (
    <RoleWrapper 
      defaultComponent={dataValue && 
        <div className="change-value-tooltip--cell__amount">
          {dataValue}
        </div>
      } 
      accessTo={accessTo} 
    >
    <div
      className='change-value-tooltip--cell'
      onMouseOver={() => iconOnHover(cellRef)}
      onMouseOut={() => iconOnLeave(cellRef)}
      onClick={openHandler}
      ref={refContainer}
    >
      <Tooltip
        open={adding}
        showIcon={false}
        direction={(window.innerWidth - refContainer?.current?.getBoundingClientRect()?.left) > 240 ? "top" : 'left'}
        className={classNames(bigTooltipClass, 'popover-tooltip' )}
      >
        <div className='popover-tooltip__title'>
          {isComment ? _t("add_comment") : _t('set_new_value')}
        </div>
        <div ref={ref}>
          <FormBuilder
            formItemsConfig={formConfig}
            showSubmit={false}
            formProps={{
              submitBtnLabel: _t("update"),
              onSubmit: onSubmit,
              initialValues: initialValues,
              validationSchema: validation,
            }}
          />
          <Button
            renderIcon={Close16}
            kind='tertiary'
            style={{
              position: 'absolute',
              bottom: '13px',
              right: '72px',
            }}
            onClick={() => {
              setAdding(false);
              iconOnLeave(cellRef)
              }
            }
          />
        </div>
      </Tooltip>
      {dataValue && <div className="change-value-tooltip--cell__amount">
        {dataValue}
      </div>}
      <div className="change-value-tooltip--cell__comment" >
        <Edit size={16} style={{visibility: 'hidden'}} ref={cellRef} />
        {comment}
      </div>
    </div>
    </RoleWrapper>
  );
};

export default ChangeValueTooltip;
