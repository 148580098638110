import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANTS,
  API_GET_MERCHANT,
  API_GET_MERCHANTS_NAME_LIST,
  API_DELETE_MERCHANT,
  API_CREATE_MERCHANT,
  API_UPDATE_MERCHANT,
  API_GET_SUB_MERCHANTS,
  API_CREATE_MERCHANT_SLACK_CHANNEL_SETUP,
  API_GET_MERCHANT_SLACK_CHANNELS,
  API_DELETE_MERCHANT_SLACK_CHANNEL_SETUP,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  MerchantDataType,
  MerchantNameListDataType,
  MerchantsDataType,
  MerchantSlackChannelType,
} from "modules/merchants/types";

const getMerchantsKey = "getMerchantsKey";
const getSubMerchantsKey = "getSubMerchantsKey";
const getMerchantKey = "getMerchantKey";
const getMerchantsNameListKey = "getMerchantsNameListKey";
const getMerchantSlackChannels = "getMerchantSlackChannelsKey";
export const useGetMerchants = (filter = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantsKey, filter],
    (): Promise<MerchantsDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS, filter),
        method: "get",
      })
  );
};
export const useGetSubMerchants = (
  filter = {},
  merchantId: number = 0,
  enabled = true
) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getSubMerchantsKey, filter, merchantId],
    (): Promise<MerchantsDataType> =>
      apiRequest({
        url: generateUrlQuery(
          generatePath(API_GET_SUB_MERCHANTS, { merchantId: `${merchantId}` }),
          filter
        ),
        method: "get",
      }),
    { enabled: enabled }
  );
};
export const useGetMerchant = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantKey, id],
    (): Promise<MerchantDataType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANT, { id: `${id}` }),
        method: "get",
      })
  );
};
export const useGetMerchantsNameList = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantsNameListKey],
    (): Promise<MerchantNameListDataType> =>
      apiRequest({
        url: API_GET_MERCHANTS_NAME_LIST,
        method: "get",
      })
  );
};

export const useCreateMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANT,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteMerchant = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
    apiRequest({
      url: generatePath(API_DELETE_MERCHANT, {
        id: data.id,
      }),
      method: "delete",
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
    );
  };
  
  export const useUpdateMerchant = () => {
    const [apiRequest] = useApiRequest();
    const queryClient = useQueryClient();
    const { apiErrors } = useApiErrors();
    const { apiSuccessMessage } = useSuccessToast();
    return useMutation(
      (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MERCHANT, {
          id: data.id,
        }),
        method: "put",
        data: data.data,
      }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getMerchantsKey);
          queryClient.invalidateQueries(getMerchantKey);
          apiSuccessMessage();
        },
        onError: (err: ErrorType) => apiErrors(err),
      }
      );
    };
    
    export const useCreateMerchantSlackChannelSetup = () => {
      const [apiRequest] = useApiRequest();
      const queryClient = useQueryClient();
      const { apiErrors } = useApiErrors();
      const { apiSuccessMessage } = useSuccessToast();
      return useMutation(
        (data: any) =>
          apiRequest({
            url: API_CREATE_MERCHANT_SLACK_CHANNEL_SETUP,
            method: "post",
            data: data,
          }),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(getMerchantSlackChannels);
            apiSuccessMessage();
          },
          onError: (err: ErrorType) => apiErrors(err),
        }
      );
    };

    export const useGetMerchantSlackChannels = (merchantId?: number) => {
      const [apiRequest] = useApiRequest();
      return useQuery(
        [getMerchantSlackChannels, merchantId],
        (): Promise<MerchantSlackChannelType[]> =>
          apiRequest({
            url: generatePath(API_GET_MERCHANT_SLACK_CHANNELS, { merchantId: `${merchantId}`}),
            method: "get",
          }),
          {
            enabled: !!merchantId,
          }
      );
    };

    export const useDeleteSlackChannel = () => {
      const [apiRequest] = useApiRequest();
      const queryClient = useQueryClient();
      const { apiErrors } = useApiErrors();
      const { apiSuccessMessage } = useSuccessToast();
      return useMutation(
        (data: any) =>
        apiRequest({
          url: generatePath(API_DELETE_MERCHANT_SLACK_CHANNEL_SETUP, {
            id: data.id,
          }),
          method: "delete",
        }),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(getMerchantSlackChannels);
            apiSuccessMessage();
          },
          onError: (err: ErrorType) => apiErrors(err),
        }
        );
      };