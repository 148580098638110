import * as CarbonIcons from '@carbon/icons-react';
import { FC } from 'react';
import { CarbonIconProps } from '@carbon/icons-react';

type CarbonIconKeys = keyof typeof CarbonIcons;
const sizes = [16, 20, 24, 32, 48] as const;
type IconSizes = typeof sizes[number];

type IconComponentName = `${CarbonIconKeys}${IconSizes}`;

type IconComponentMap = {
  [key in IconComponentName]: FC<CarbonIconProps>;
};

const createIconComponent = (iconName: CarbonIconKeys, size: IconSizes): FC<CarbonIconProps> => {
  return (props: CarbonIconProps) => {
    const IconComponent = CarbonIcons[iconName];

    if (!IconComponent) {
      throw new Error(`Icon "${iconName}" not found.`);
    }

    return <IconComponent size={size} {...props} />;
  };
};

const generateIconComponents = (): IconComponentMap => {
  const components: Partial<IconComponentMap> = {};

  const iconNames: CarbonIconKeys[] = Object.keys(CarbonIcons) as CarbonIconKeys[];

  iconNames.forEach(iconName => {
    sizes.forEach(size => {
      const componentName = `${iconName}${size}` as IconComponentName;
      components[componentName] = createIconComponent(iconName, size);
    });
  });

  return components as IconComponentMap;
};

const iconComponents = generateIconComponents();

export const exportedComponents = iconComponents;

export const {
  Renew16,
  Close16,
  Close24,
  Account16,
  Account24,
  Warning16,
  Warning24,
  ArrowLeft24,
  Checkmark16,
  CloseOutline16,
  Edit16,
  Download16,
  Information16,
  ChevronDown16,
  User20,
  Calendar16,
  Close20,
  Maximize24,
  DataBase32,
  ChevronRight16,
  ChevronUp16, 
  ArrowDown16,
  ArrowUp16,
  Search16,
  Add16,
  RecentlyViewed16,
  CaretDown16,
  CaretUp16,
  TextWrap16,
  Account20,
  ArrowsHorizontal20,
  ChartColumn20,
  ChartPie20,
  Globe20,
  IbmSecurity20,
  Settings20,
  Template20,
  Time20,
  Unknown20,
  UserMultiple20,
  ArrowsHorizontal16,
  IbmSecurity16,
  UserMultiple16,
  ChangeCatalog16,
  WarningOther20,
  Copy16,
  Delete16,
  Password16,
  Filter16,
  ArrowRight16,
  TrashCan16,
  DocumentImport16,
  DocumentExport16,
  CaretLeft24, 
  CaretRight24,
  Warning32,
  Api16,
  Logout16,
  EventSchedule32,
  Carbon16,
  Purchase16,
  Security16,
  SubtractAlt16,
  EventSchedule16,
  Menu16,
  Scale20,
  Add20,
  Draggable16,
  Send16,
  Save16,
  Send20,
  CheckmarkOutline32,
  ArrowRight20,
  ArrowLeft20,
  Network_220,
  Touch_120,
  LogoSlack16,
  FlightInternational16,
  Rocket16,
  Checkmark20
} = exportedComponents;
