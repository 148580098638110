import React from "react";
import { CardProps } from "./types";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../modules/breadcrumbs/components/Breadcrumbs";
import classNames from "classnames";
import { useModifyCrumbsPathnames } from "../../../modules/breadcrumbs/hooks";
import { ArrowLeft24 } from "../IconsCreateFactory";

const Card: React.FC<CardProps> = ({
  children,
  title,
  subtitle,
  extra,
  withoutFilter,
  backLocation,
  titleClassName
}) => {
  const { pathnamesModified, pathnamesOriginal } = useModifyCrumbsPathnames();
  return (
    <div
      className={classNames(
        pathnamesModified.length > 1 && "card-wrap",
        "card"
      )}
    >
      {pathnamesModified.length > 1 && (
        <Breadcrumbs
          pathnamesModified={pathnamesModified}
          pathnamesOriginal={pathnamesOriginal}
        />
      )}
      <div className={classNames(
        "card__head",
        extra && "card-with-extra"
      )}>
        {title && (
          <div className={classNames(
            "card__head__title",
            titleClassName
          )}>
            {backLocation && (
              <Link className={"tx-dec-none"} to={backLocation}>
                <ArrowLeft24 />
              </Link>
            )}
            {title}
            {subtitle &&
              <div className={"card__head__subtitle-text"}>
                {subtitle}
              </div>
            }
          </div>
        )}
        {extra && <div className={"card__head__extra"}>{extra}</div>}
      </div>
      <div
        className={classNames(
          withoutFilter && "card__body--without-filter",
          "card__body"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
