import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_USERS,
  API_CREATE_USER,
  API_GET_USER,
  API_DELETE_USER,
  API_RESET_USER_PASSWORD,
  API_RESEND_EMAIL_CONFIRMATION_LINK,
  API_UPDATE_USER,
  API_ENABLE_USER_TFA,
  API_USER_RESET_TFA,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { UserDataType, UserItemType } from "modules/users/types";

const getUsersKey = "getUsersKey";
const getUserKey = "getUserKey";
export const useGetUsers = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getUsersKey, filters],
    (): Promise<UserDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_USERS, filters),
        method: "get",
      })
  );
};

export const useGetUser = (id: string) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getUserKey, id],
    (): Promise<UserItemType> =>
      apiRequest({
        url: generatePath(API_GET_USER, {
          id,
        }),
        method: "get",
      })
  );
};

export const useCreateUser = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_USER,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUsersKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useResetUserPassword = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_RESET_USER_PASSWORD,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDeleteUser = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_USER, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUsersKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateUser = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_USER, {
          id: data.id,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUserKey);
        queryClient.invalidateQueries(getUsersKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useEnableUserTFa = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_ENABLE_USER_TFA,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUserKey);
        queryClient.invalidateQueries(getUsersKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useResendConfirmationLink = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (id: string | number) =>
      apiRequest({
        url: generatePath(API_RESEND_EMAIL_CONFIRMATION_LINK, {
          id: `${id}`,
        }),
        method: "get",
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useResentTFA = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (id: string | number) =>
      apiRequest({
        url: generatePath(API_USER_RESET_TFA, {
          id: `${id}`,
        }),
        method: "put",
      }),
    {
      onSuccess: () => {
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
