import React, { useMemo } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import {
  useGetMerchantsBalance,
  useGetMerchantsBalanceExport,
} from "modules/merchantsBalance/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
  useTableSummaryColumns,
} from "modules/merchantsBalance/constantHooks";
import Info from "modules/merchantsBalance/components/Info";
import { Accordion, AccordionItem, Button } from "carbon-components-react";
import { format } from "date-fns";
import config from "system/config";
import { DocumentExport16, Filter16 } from "components/atoms/IconsCreateFactory"
import DotsMenu from "components/atoms/DotsMenu";

const MerchantsBalance = () => {
  const { _t } = useTrans();
  const { showModal, hideModal } = useModal();
  const filterInitialValues = useMemo(
    () => ({
      fromDate: format(new Date(), config.dateFormat),
      toDate: format(new Date(), config.dateFormat),
    }),
    []
  );
  const { filters, setFilters } = useFilters(filterInitialValues);
  const requestParams = useMemo(() => ({ ...filters }), [filters]);
  const { data, isLoading } = useGetMerchantsBalance(requestParams);
  const { mutate: onGetExport, isLoading: isLoadingExport } =
    useGetMerchantsBalanceExport();
  const totalData: any = useMemo(() => {
    let res: any = [];
    if (data?.total) {
      Object.keys(data?.total).map((item) => {
        res.push({
          currency: item,
          depositTotal: data.depositTotal[item],
          withdrawalTotal: data.withdrawalTotal[item],
          total: data.total[item],
        });
      });
    }
    return res;
  }, [data]);
  const columns = useTableColumns(data);
  const columnsSummary = useTableSummaryColumns(totalData);
  const searchFormConfig = useSearchFormConfig();
  const onFilterSubmit = (data: any) => {
    const normalizeDate = {
      ...data,
      updatedDate: `${data.searchFrom !== "created"}`,
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    }
    delete normalizeDate.date;
    delete normalizeDate.searchFrom;
    setFilters(normalizeDate);
    hideModal('filters');
  };
  const onExport = () => {
    onGetExport(filters);
  };

  const showDetails = ({ merchantId, merchantName }: any) => {
    showModal({
      componentProps: {
        id: merchantId,
      },
      modalHeading: _t("merchant_details_for_{merchant}", {
        merchant: merchantName,
      }),
      component: Info,
    });
  };

  const isMobile = window.matchMedia('(max-width: 671px)').matches;

  const actionMenuItems = [
    {
      title: _t("export_CSV"),
      onClick: onExport,
      icon: <DocumentExport16 />,
    }
  ];

  const showFilters = () => {
    showModal({
      modalHeading: _t("filters"),
      isShowScale: false,
      componentProps: {
        showSubmit: false,
        formItemsConfig: searchFormConfig,
        formProps: {
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: filterInitialValues,
        },
      },
      component: FormBuilder,
    }, 'filters');
  
  }

  return (
    <Card
      title={_t("merchants_balance")}
      extra={
        <>
          {!isMobile 
            ? <Button
                kind="tertiary"
                onClick={onExport}
                renderIcon={DocumentExport16}
                disabled={isLoadingExport}
                >
                  {_t("export_CSV")}
              </Button>
            : <DotsMenu items={actionMenuItems}/>
          }
        </>
      }
    >
      {!isMobile 
      ? <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
          }}
        />
      : <>
          <Button
            kind="ghost"
            style={{ color: "#252C32" }}
            onClick={() => showFilters()}
            renderIcon={Filter16}
            >
              {_t("filters")}
          </Button>
        </>
    }
      <Accordion className={"mv-3"}>
        <AccordionItem title={_t("summary")}>
          <Table
            columns={columnsSummary}
            isLoading={isLoading}
            data={totalData || []}
          />
        </AccordionItem>
      </Accordion>
      <Table
        columns={columns}
        isLoading={isLoading}
        data={data?.result || []}
        isPagination
        cellProps={{
          showDetails,
        }}
      />
    </Card>
  );
};

export default MerchantsBalance;
