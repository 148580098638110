import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_GATEWAY_WHITE_LIST,
  API_CREATE_GATEWAY_WHITE_LIST,
  API_UPDATE_GATEWAY_WHITE_LIST,
  API_DELETE_GATEWAY_WHITE_LIST,
  API_GET_GATEWAY_WHITE_LIST_BY_ID,
} from "system/api/apiUrls";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";

const getGateWayWhiteList = "getGateWayWhiteList";
const getGateWayWhiteListById = "getGateWayWhiteListById";

export const useGetGateWayWhiteList = (pgwId: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery([getGateWayWhiteList, pgwId], () =>
    apiRequest({
      url: generatePath(API_GET_GATEWAY_WHITE_LIST, { pgwId: `${pgwId}` }),
      method: "get",
    })
  );
};

export const useGetGateWayWhiteListById = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery([getGateWayWhiteListById, id], () =>
    apiRequest({
      url: generatePath(API_GET_GATEWAY_WHITE_LIST_BY_ID, { id: `${id}` }),
      method: "get",
    })
  );
};

export const useCreateGateWayWhiteList = (pgwId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_CREATE_GATEWAY_WHITE_LIST, { pgwId: `${pgwId}` }),
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getGateWayWhiteList);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useUpdateGateWayWhiteList = (pgwId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_GATEWAY_WHITE_LIST, {
          id: data.id,
          pgwId: `${pgwId}`,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getGateWayWhiteList);
        queryClient.invalidateQueries(getGateWayWhiteListById);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteGateWayWhiteList = (pgwId: number) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_GATEWAY_WHITE_LIST, {
          id: data.id,
          pgwId: `${pgwId}`,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getGateWayWhiteList);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
