import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal as CarbonModal } from "carbon-components-react";
import { ModalTypes, ModalParams } from "./types";
import { Scale20 } from "components/atoms/IconsCreateFactory"
import classNames from "classnames";

import "./../../styles/modules/modal.scss"

const Modal: React.FC<ModalTypes> = forwardRef(({ hideModal }, ref) => {
  const [fullScreen, setFullScreen] = useState<any>({});
  const [visible, setVisible] = useState<any>({});
  const [params, setParams] = useState<{ [key: string]: ModalParams }>({});
  const [focus, setFocus] = useState("default");
  /*
  const size = {
    sm: { width: "30vw" },
    m: { width: "50vw" },
    l: { width: "80vw" },
  };
*/
  const onFullScreen = (name: string) =>
    setFullScreen({ ...fullScreen, [name]: !fullScreen?.[name] });
  useImperativeHandle(ref, () => ({
    modalOpen(name: string, param: any) {
      setParams({ ...params, [name]: param });
      setFocus(name);
      name === 'filter'
        ? setVisible({ ...visible, [name]: true, ['filters']: false })
        : setVisible({ ...visible, [name]: true });
    },
    modalUpdate(name: string, param: any) {
      setParams({ ...params, [name]: { ...params[name], ...param } });
    },
    modalClose(name: any) {
      const modalParams = { ...params };
      delete modalParams[name];
      setParams(modalParams);
      name === 'filter'
        ? setVisible({ ...visible, [name]: false, ['filters']: true })
        : setVisible({ ...visible, [name]: false });
      const modifiedFullScreen = { ...fullScreen };
      delete modifiedFullScreen[name];
      setFullScreen(modifiedFullScreen);
    },
  }));
  const modalTitle = (modalHeading: any, modalName: string, isShowScale: boolean = true) => (
    <div className={`bx--modal-header--${modalName}`}>
      <>{modalHeading}</>
      { isShowScale &&
        <Scale20
          onClick={() => onFullScreen(modalName)}
          className={"bx--modal-header__heading__scale"}
        />
      }
    </div>
  );

  return (
    <>
      {Object.keys(visible).map((name, idx) => {
        const {
          component: Content,
          componentProps,
          body,
          style,
          title,
          modalHeading,
          modalLabel,
          passiveModal = true,
          size = "lg",
          footer: FooterContent,
          footerProps,
          isShowScale,
          smallModal = false,
          className = ''
        } = params?.[name] || {};
        const ownStyles = {
          minWidth: "320px",
          zIndex: name === focus ? 9001 : 9000,
          ...style,
        };

        return (
          <CarbonModal
            key={name}
            title={title}
            open={visible?.[name]}
            style={{ ...ownStyles }}
            onRequestClose={() => hideModal(name)}
            size={size}
            modalHeading={modalTitle(modalHeading, name, isShowScale)}
            modalLabel={modalLabel}
            passiveModal={passiveModal}
            className={classNames(
              name === "mobile-menu" && "bx--modal--mobile--menu",
              !!fullScreen?.[name] && "bx--modal--full-screen",
              name !== focus && "bx--modal--focused",
              smallModal && "bx-xxs-modal",
              className && className
            )}
          >
            <div className={"bx--modal-content__body"}>
              {Content ? <Content {...componentProps} /> : body}
            </div>
            {FooterContent && (
              <div className={"bx--modal-content__footer"}>
                <FooterContent {...footerProps} />
              </div>
            )}
          </CarbonModal>
        );
      })}
    </>
  );
});

export default Modal;
