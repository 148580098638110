import React, { useEffect, useMemo, useRef, useState } from "react";
import { RadioButton } from "carbon-components-react";
import { DateRangePicker, defaultStaticRanges, DefinedRange, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { format, parseISO } from "date-fns";
import { useOutsideClick } from "system/helpers/hooks"; // theme css file

const DateRangeMobileModal = ({
  value,
  onChange,
  name,
  fromName,
  toName,
  onFormValueChange,
  dateFormat,
}: any) => {
  const checkDate = (date: string) => {
    if (!date) {
      return new Date();
    }
    const parsedDate: any = parseISO(date);
    return parsedDate != "Invalid Date" ? parsedDate : new Date();
  };

  const handlerChange = (val: any) => {
    const from = val["selection"].startDate; //&& format(val["selection"].startDate, "yyyy-MM-dd");
    const to = val["selection"].endDate; //&& format(val["selection"].endDate, "yyyy-MM-dd");
    onChange(format(from, dateFormat), `${name}.${fromName}`);
    onChange(format(to, dateFormat), `${name}.${toName}`);
  };


  const handlerChangeStartDate = (val: any) => {
    const from = val["selection"].startDate; //&& format(val["selection"].startDate, "yyyy-MM-dd");
    onChange(format(from, dateFormat), `${name}.${fromName}`);
  };

  const handlerChangeEndDate = (val: any) => {
    const to = val["selection"].endDate; //&& format(val["selection"].endDate, "yyyy-MM-dd");
    onChange(format(to, dateFormat), `${name}.${toName}`);
  };

  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);

  const data = useMemo(() => {
    return {
      startDate: checkDate(value?.[fromName]),
      endDate: checkDate(value?.[toName]),
    };
  }, [value, fromName, toName]);


  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);

  useEffect(() => {
    setShowStart(false);
    setShowEnd(false);
  }, [clickOutSide]);

  const [type, setType] = useState("created");
  
  useEffect(() => {
    onFormValueChange("searchFrom", type);
  }, [type]);

  const renderStaticRangeLabel = () => (
    <div className="date-range__title">
      Time Period:
    </div>
  );  
 
  return (
    <div className={"date-range__body"} ref={ref}>
      <div className={"date-range__body__period"}>
        <div className="date-range__section">
          <p className="date-range__title">Show period since:</p>
          <div>
            <RadioButton
              onClick={() => setType("created")}
              checked={type === "created"}
              name={"created"}
              labelText={"Transaction was created"}
            />
            <RadioButton
              onClick={() => setType("updated")}
              checked={type === "updated"}
              name={"updated"}
              labelText={"Last updated"}
            />
          </div>
        </div>
      </div>
      <DateRangePicker
        className="date-range__section"
        onChange={(item) => {
          handlerChange(item)
        }}
        months={1}
        moveRangeOnFirstSelection={false}
        renderStaticRangeLabel={renderStaticRangeLabel}
        ariaLabels={{}}
        inputRanges={[]}
        ranges={[
          {
            startDate: data?.startDate,
            endDate: data?.endDate,
            key: "selection",
          },
        ]}
        staticRanges={[
          {
            hasCustomRendering: true,
            range: () => ({}),
            isSelected() {
              return false;
            },
          },
          ...defaultStaticRanges,
        ]}
        direction="horizontal"
      // direction="vertical"
      />
      <div className="date-range__section">
        <div className="date-range__title">Custom Period:</div>
        <div 
          className="date__mobile-container"
          onClick={() => {
            setShowEnd(false);
            setShowStart(true);
            }}
          >
          <div>Start Date:</div>
          <div>
            {showStart && 
              <Calendar
                displayMode="dateRange"
                ranges={[
                  {
                    startDate: data?.startDate,
                    endDate: data?.endDate,
                    key: "selection",
                  },
                ]}
                className="date__mobile-calendar"
                showMonthAndYearPickers={false}
                weekdayDisplayFormat={'EEEEE'}
                onChange={(item) => {
                  handlerChangeStartDate({
                    "selection" : {
                      startDate: item,
                      key: "selection",
                    }
                  });
                }}
              />}
              {`${format(data?.startDate, dateFormat)}`}
          </div>
        </div>
        <div
          className="date__mobile-container"
          onClick={() => {
          setShowStart(false)
          setShowEnd(true)
          }}
        >
          <div>End Date:</div>
          <div>
            {`${format(data?.endDate,dateFormat)}`}
            </div>
          {showEnd && 
            <Calendar
              displayMode="dateRange"
              ranges={[
                {
                  startDate: data?.startDate,
                  endDate: data?.endDate,
                  key: "selection",
                },
              ]}
              className="date__mobile-calendar"
              showMonthAndYearPickers={false}
              weekdayDisplayFormat={'EEEEE'}
              onChange={(item) => {
                  handlerChangeEndDate({
                    "selection" : {
                      endDate: item,
                      key: "selection",
                    }
                  });
                }}
            />}
        </div>
      </div>
      {/* <div className={"date-range__body__period"}>
            <RadioButton
              onClick={() => setType("created")}
              checked={type === "created"}
              name={"created"}
              labelText={"Transaction was created"}
            />
            <RadioButton
              onClick={() => setType("updated")}
              checked={type === "updated"}
              name={"updated"}
              labelText={"Last updated"}
            />
          </div> */}
    </div>
  );
};

export default DateRangeMobileModal;
