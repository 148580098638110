import { Button } from "carbon-components-react";
import Input from "../Input";
import { useTrans } from "system/translations/hooks";
import { EditableTitleProps } from "./types";
import { Close16, Save16 } from "../IconsCreateFactory";

const EditableTitle: React.FC<EditableTitleProps> = ({ title, changedTitle, onTitleChange, onSave }) => {
  const { _t } = useTrans();
  return (
    <div className="bx--search-wrapper tree-view-item__change-title">
      <Input 
        autoFocus={true} 
        invalid={changedTitle.length === 0} 
        value={changedTitle} 
        onChange={(e) => onTitleChange(e)}
      />
      <Button
        style={{ color: "#000" }}
        className={"bx--search-button"}
        type={"submit"}
        hasIconOnly={true}
        iconDescription={changedTitle !== title ? _t("save") : _t("cancel")}
        renderIcon={changedTitle !== title ? Save16 : Close16}
        onClick={onSave}
      />
    </div>
  );
};

export default EditableTitle;
