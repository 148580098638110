import Card from "components/atoms/Card";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { useTableColumns } from "modules/settings/constantHooks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16, Copy16, Edit16, Security16, TrashCan16 } from "components/atoms/IconsCreateFactory"
import {
  useGetSetting,
  useDeleteSetting,
  useUpdateSettingCronJob,
} from "modules/settings/apiHooks";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { DotsMenuItem } from "components/atoms/DotsMenu/types";
import { useSearchFormConfig } from "modules/settings/constantHooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFEFilter, useFilters } from "system/helpers/hooks";

const SettingInfo = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { onConfirm } = useConfirmModal();
  const { data = [], isLoading } = useGetSetting(routeParams?.settingId);
  const { mutate: onDelete } = useDeleteSetting();
  const { mutate: onUpdateCronJob } = useUpdateSettingCronJob();
  const columns: any = useTableColumns(data, {
    settingId: routeParams?.settingId,
  });

  const isPaymentGWs = routeParams?.settingId === "paymentGWs";

  const createLink = ({ id, allData }: any) => {
    const linkId =
      routeParams?.settingId === "credentials" ? allData?.type : id;
    let link = generatePath(APP_ROUTES.settings.update, {
      settingId: routeParams?.settingId,
      id: linkId,
    });
    
    return link + `?paramId=${allData.id}`;
  };

  const onUpdate = ({ id, allData }: any) => {
    history.push(createLink({ id, allData }), history.location);
  };
  const onDeleteHandler = ({ id }: any) => {
    onDelete({ id, settingId: routeParams?.settingId });
  };
  const onCronJob = ({ id }: any) => {
    onUpdateCronJob(id);
  };
  const onGateWayWhiteList = ({ id }: any) => {
    let link = generatePath(APP_ROUTES.gateWay.whitelist.index, {
      pgwId: id,
    });
    history.push(link, history.location);
 };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({
      onOk: () => onDelete({ id, settingId: routeParams?.settingId }),
    });
  };

  const onDuplicate = ({id}: any) => {    
    const link = generatePath(APP_ROUTES.settings.duplicate, {
      id,
      settingId: routeParams?.settingId
    })
    history.push(link, history.location);
  }
  const actionMenuItems: DotsMenuItem[] = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: createLink,
      icon: <Edit16 /> 
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];
  if (isPaymentGWs) {
    actionMenuItems.push(
      {
      title: _t("ip_whitelisting"),
      onClick: onGateWayWhiteList,
      getLink: ({id}: any) => generatePath(APP_ROUTES.gateWay.whitelist.index, {
        pgwId: id,
      }),
      icon: <Security16 />,
      accessTo: "ROLE_MODIFY_GATEWAY_IP",
    },
      {
      title: _t("duplicate"),
      onClick: onDuplicate,
      icon: <Copy16 />,
    },
    );
  }
  const disallowCreate = ["cronJobs", "paymentGWs"];
  const showCreate = !disallowCreate.includes(routeParams?.settingId);

  const searchFormConfig = useSearchFormConfig()

  const {filters, setFilters} = useFilters({})

  const filteredData = useFEFilter(data, filters);
  
  const onFilterSubmit = ( data: any ) => {
    setFilters(data)
  }

  const isMobile = window.matchMedia('(max-width: 671px)').matches;

  
  return (
    <Card
      title={routeParams?.settingId}
      extra={
        <>
          {!isMobile && showCreate && (
            <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={generatePath(APP_ROUTES.settings.create, {
                settingId: routeParams?.settingId,
              })}
              title={_t("create")}
            />
          )}
          {isMobile && showCreate && (
            <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={generatePath(APP_ROUTES.settings.create, {
                settingId: routeParams?.settingId,
              })}
            />
          )}
        </>
      }
    >
      <FormBuilder
        showSubmit={false}
        formItemsConfig={searchFormConfig}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
        }}
      />
      <Table
        columns={columns || []}
        isLoading={isLoading}
        data={filteredData}
        totalRecords={filteredData.length}
        isPagination
        cellProps={{
          actionMenuItems,
          onCronJob,
          onUpdate,
          createLink
        }}
      />
    </Card>
  );
};

export default SettingInfo;
