import {
  FormikType,
  HeaderLinkType,
  ListPaginationItem,
  ListSort,
  ListSortItem,
  SidebarMenuType,
  UseConstantsResult,
  UseFiltersResult,
  UseFormikHelperResult,
  UseListPaginationType,
  UseListSortType,
  UserStatusesType,
} from "system/helpers/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "lodash.isequal";
import { filterArrayByValues, getValueByPath, getValues, parseNestedData } from "./helperFunctions";
import { useTrans } from "../translations/hooks";
import { APP_ROUTES } from "../router/constants";
import { useLocation } from "react-router-dom";
import {
  Account20,
  ArrowsHorizontal20,
  ChartColumn20,
  ChartPie20,
  Globe20,
  IbmSecurity20,
  Settings20,
  Template20,
  Time20,
  Touch_120,
  Unknown20,
  UserMultiple20,
  Account16,
  ArrowsHorizontal16,
  IbmSecurity16,
  UserMultiple16,
  ChangeCatalog16,
  WarningOther20,
  Network_220,
} from "components/atoms/IconsCreateFactory"
import { defaultSelectedPage, defaultPageSize } from "modules/table/constants";
import { useToast } from "modules/toast/hooks";
import { useModal } from "modules/modal/hooks";
import { isEmpty } from "lodash";

export const useFormikHelper = (formik: FormikType): UseFormikHelperResult => {
  const {
    errors,
    touched,
    status,
    setFieldTouched,
    setStatus,
    setFieldValue,
    isSubmitting,
  }: FormikType = formik;
  const setFieldFromArrayTouchedOnSubmit = useCallback((name: any) => {
    isSubmitting && getValueByPath(touched,name) == undefined && setFieldTouched(name)
  }, [isSubmitting, touched])

  const error = (name: string) =>{
    if (name.includes('.')) {
      setFieldFromArrayTouchedOnSubmit(name)
      return getValueByPath(touched,name) && getValueByPath(errors,name)
    }
   return (touched?.[name] && errors?.[name]) || (touched?.[name] && status?.[name])
}


  const onChange = (name: string, value: any) => {
    setFieldValue(name, value);
    setStatus({ ...status, [name]: "" });
  };
  const onBlur = (name: string) => {
    setFieldTouched(name);
    setStatus({ ...status, [name]: "" });
  };
  return { error, onChange, onBlur };
};

export const useFilters = (
  initialFilters: any,
  flatObject: boolean = false,
  specialKeys: string[] = [],
  isMobile: boolean = false
): UseFiltersResult => {
  const [filtersValues, setFiltersNewFilters] = useState(initialFilters);
  const setFilters = (allFilters: any) => {
    let newFilters;
    if (isMobile && specialKeys.length) {
      const specialNewFilters = getValues(allFilters, specialKeys)
      const specialOldFilters = getValues(filtersValues, specialKeys)            
      newFilters = isEqual(specialNewFilters, specialOldFilters)
        ? isEqual(allFilters, filtersValues)
          ? filtersValues
          : allFilters
        : isEmpty(specialNewFilters)
          ? { ...allFilters, ...specialOldFilters }
          : { ...allFilters, ...specialNewFilters }
    } else {
      newFilters = isEqual(allFilters, filtersValues)
        ? filtersValues
        : allFilters
    }
    setFiltersNewFilters(newFilters);
  };
  const filters = flatObject ? parseNestedData(filtersValues) : filtersValues;
  const filtersInArray = Object.values(filters).map((item, idx) => ({
    id: Object.keys(filters)[idx],
    value: item,
  }));
  return { filters, setFilters, filtersInArray };
};

export const useFEFilter = (data: any, filters: any) =>
  useMemo(() => filterArrayByValues(data, filters), [data, filters]);

const defaultInitialPagination: ListPaginationItem = {
  page: defaultSelectedPage,
  perPage: defaultPageSize,
};

const defaultInitialSort: ListSort = {};

export const useListSort = (
  initialParams: ListSort = defaultInitialSort,
  singleFieldSort = true
): UseListSortType => {
  const [sort, setOnSort] = useState<ListSort>(initialParams);
  const setSort = (val: ListSortItem[]) => {
    if (val.length) {
      const sortFieldVal = {
        sortField: val[0].id,
        sortDir: val[0].desc ? "DESC" : "ASC",
      };
      setOnSort(sortFieldVal);
    } else {
      if (!isEmpty(sort) && initialParams !== sort) {
        setOnSort(defaultInitialSort);
      }
    }
  };
  return { sort, setSort };
};

export const useListPagination = (
  initialParams: ListPaginationItem = defaultInitialPagination
): UseListPaginationType => {
  const [pagination, setPagin] = useState<ListPaginationItem>({
    ...initialParams,
  });
  const setPagination = (values: any) => {
    const page = values.page;
    const perPage = values.perPage;
    setPagin({ page, perPage });
  };

  return { pagination, setPagination };
};

const useTableChange = ({
  initialFilter = {},
  initialSort = {},
  initialPagination = {},
}: any) => {
  const { pagination, setPagination } = useListPagination(initialPagination);
  const { sort, setSort } = useListSort(initialSort);
  const { filters, setFilters, filtersInArray } = useFilters(initialFilter);

  return {
    pagination,
    setPagination,
    sort,
    setSort,
    filters,
    setFilters,
    filtersInArray,
  };
};

export const useConstants = (): UseConstantsResult => {
  const { _t } = useTrans();
  const userStatuses: UserStatusesType = [
    { value: true, label: _t("active") },
    { value: false, label: _t("deactivated") },
  ];
  const headerLinks: HeaderLinkType = [
    /*    { link: "#", title: _t("api_documentation") },
    { link: "#", title: _t("checkout_integration") },*/
  ];
  const sidebarMenu: SidebarMenuType = [
    {
      link: APP_ROUTES.transactions.index,
      title: _t("transactions"),
      icon: ArrowsHorizontal20,
      accessTo: "ROLE_VIEW_TRANSACTION",
      presentInMobileMenu: true,
      mobileIcon: ArrowsHorizontal16,
    },
    {
      link: APP_ROUTES.transactions.update,
      title: _t("change_transactions_status"),
      icon: Unknown20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_CHANGE_TRANSACTION_STATUS"],
    },
    {
      link: APP_ROUTES.paymentInstrumentManagement.index,
      title: _t("payment_instrument_management"),
      icon: ChangeCatalog16,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_EXTERNAL_CUSTOMER_IDENTIFIERS", "ROLE_MODIFY_EXTERNAL_CUSTOMER_IDENTIFIERS"],
    },
    {
      link: APP_ROUTES.users.index,
      title: _t("users"),
      icon: UserMultiple20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_USERS"],
      presentInMobileMenu: true,
      mobileIcon: UserMultiple16,
    },
    {
      link: APP_ROUTES.roles.index,
      title: _t("roles"),
      icon: IbmSecurity20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_ROLES"],
      presentInMobileMenu: true,
      mobileIcon: IbmSecurity16,
    },
    {
      link: APP_ROUTES.merchants.index,
      title: _t("merchants"),
      icon: Account20,
      accessTo: "ROLE_VIEW_MERCHANTS",
      presentInMobileMenu: true,
      mobileIcon: Account16,
    },
    {
      link: APP_ROUTES.merchantsBalance,
      title: _t("merchants_balance"),
      icon: ChartPie20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_MERCHANT_BALANCE"],
    },
    {
      link: "",
      title: _t("merchants_balance_v2"),
      icon: ChartPie20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_MERCHANT_BALANCE_V2", "ROLE_MODIFY_MERCHANT_BALANCE_V2"],
      submenu: [
        {
          link: APP_ROUTES.merchantsBalanceV2.index,
          title: _t("balance"),
        },
        {
          link: APP_ROUTES.merchantsBalanceV2.merchantsFees.index,
          title: _t("merchants_fees"),
          accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2"
        },
        {
          link: APP_ROUTES.merchantsBalanceV2.merchantConfiguration.index,
          title: _t("merchant_configuration"),
          accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2"
        },
        {
          link: APP_ROUTES.merchantsBalanceV2.balanceHistory,
          title: _t("balance_history"),
          accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2"
        },
      ],
    },
    {
      link: "",
      title: _t("payment_orchestration"),
      icon: Network_220,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION","ROLE_VIEW_ORCHESTRATOR_CONFIGURATION"],
      submenu: [
        {
          link: APP_ROUTES.paymentOrchestration.routingRules.index,
          title: _t("routing_rules"),
        },
        {
          link: APP_ROUTES.paymentOrchestration.providerConstraints.index,
          title: _t("provider_constraints"),
        },
        {
          link: APP_ROUTES.paymentOrchestration.coverageReport.index,
          title: _t("coverage_report"),
        },
      ],
    },
    {
      link: APP_ROUTES.analytics,
      title: _t("analytics"),
      icon: ChartColumn20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_ANALYTICS"],
    },
    {
      link: APP_ROUTES.actionLog,
      title: _t("action_log"),
      icon: Touch_120,
      accessTo: "ROLE_SUPERADMIN",
    },
    {
      link: APP_ROUTES.requestHistory,
      title: _t("history"),
      icon: Time20,
      accessTo: "ROLE_VIEW_HISTORY",
    },
    {
      link: "",
      title: _t("app_settings"),
      icon: Template20,
      accessTo: ["ROLE_MODIFY_SETTINGS", "ROLE_SUPERADMIN"],
      submenu: [
        {
          link: APP_ROUTES.settings.index,
          title: _t("settings"),
          accessTo: "ROLE_MODIFY_SETTINGS",
        },
        {
          link: APP_ROUTES.credentials.index,
          title: _t("credentials"),
          accessTo: "ROLE_SUPERADMIN",
        },
      ],
    },
    {
      link: "",
      title: _t("status_reasons"),
      icon: WarningOther20,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
      submenu: [
        {
          link: APP_ROUTES.statusReasons.index,
          title: _t("status_reasons"),
        },
        {
          link: APP_ROUTES.statusReasonsConnection.index,
          title: _t("status_connection"),
        },
      ],
    },
    {
      link: APP_ROUTES.translations.index,
      title: _t("translations"),
      icon: Globe20,
      accessTo: [
        "ROLE_MODIFY_TRANSLATIONS",
        "ROLE_EXTERNAL_PAYMENT_PROPERTIES_SET_UP",
      ],
    },
    {
      link: "",
      title: _t("monitoring_settings"),
      icon: Settings20,
      accessTo: ["ROLE_MODIFY_MONITORING"],
      submenu: [
        {
          link: APP_ROUTES.monitoring.index,
          title: _t("monitoring_rules"),
          accessTo: "ROLE_MODIFY_MONITORING",
        },
        {
          link: APP_ROUTES.pendingTransactionsMonitoring.index,
          title: _t("pending_transactions_monitoring"),
          accessTo: "ROLE_MODIFY_MONITORING",
        },
        {
          link: APP_ROUTES.monitoringMerchant.index,
          title: _t("monitoring_merchant"),
          accessTo: "ROLE_MODIFY_MONITORING",
        },
        {
          link: APP_ROUTES.alerts.index,
          title: _t("alerts"),
          accessTo: "ROLE_MODIFY_MONITORING",
        },
        {
          link: APP_ROUTES.merchantSuccessRateMonitoring.index,
          title: _t("merchant_success_rate_monitoring"),
          accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
        },
        {
          link: APP_ROUTES.merchantPendingTransactionsMonitoring.index,
          title: _t("merchant_pending_transactions_monitoring"),
          accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
        },
      ],
    },
  ];
  return { userStatuses, sidebarMenu, headerLinks };
};

export const useOutsideClick = (ref: any): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [clickOutSide, setClick] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClick(true);
      } else {
        setClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return [clickOutSide, setClick];
};

export const useOutsideClickWithoutModal = (ref: any) => {
  const [clickOutSide, setClick] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const dateRangeBody = document.querySelector('.date-range__body')
      const modal = document.querySelector('.bx--modal')
        const isModalClick = modal?.className.includes('is-visible')
        const isAdvancedModal = modal?.className.includes('advancedModal')
        const isModalCloseIcon = event.target.contains(document.querySelector('.bx--modal-close__icon'))
      if (!dateRangeBody && ref.current && !ref.current.contains(event.target) && !isModalClick || isAdvancedModal && !isModalCloseIcon ) {
        setClick(true);
      } else {
        setClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return [clickOutSide];
};

export const useGetQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useSuccessToast = () => {
  const toastHandler = useToast();
  const { _t } = useTrans();
  const defaultMessage = _t("success");
  const apiSuccessMessage = (text: string = defaultMessage): void => {
    const toastMessage = (msg: string) =>
      toastHandler.addToast({
        caption: msg,
        kind: "success",
      });
    toastMessage(text);
  };
  return { apiSuccessMessage };
};

export const useHandleWindowResize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowSize(window.innerWidth));
  }, []);

  return windowSize;
};

export const useFormFilters = (
  initialValues: any,
  searchFormConfig: any,
  component: any,
  normalizeReqData: any = (data:any) => data
) => {
  const { _t } = useTrans()
  const { showModal, hideModal } = useModal();

  const isMobile = window.matchMedia('(max-width: 671px)').matches;
  const mobileModalSearchFormConfig = searchFormConfig.filter((searchItem: any) => searchItem.openModal || searchItem.name === '');
  const mobileSearchFormConfig = searchFormConfig.filter((searchItem: any) => !searchItem.openModal && searchItem.name !== '');
  const specialKeys = mobileSearchFormConfig.map((x: any) => x.name) as string[]
  const { filters, setFilters } = useFilters(initialValues, false, specialKeys, isMobile);
  const [filtersMobile, setFiltersMobile] = useState({})

  const onFilterSubmit = async (data: any) => {
    const normalizeDate = normalizeReqData(data, initialValues)    
    setFiltersMobile(normalizeDate);
    isEmpty(data)
      ? setFilters({})
      : setFilters(normalizeDate)
    hideModal('filters');
  };
  
  const showFilters = () => {
    showModal({
      modalHeading: _t("filters"),
      isShowScale: false,
      componentProps: {
        showSubmit: false,
        showModal: true,
        formItemsConfig: mobileModalSearchFormConfig,
        formProps: {
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues,
          values: filtersMobile,
        },
      },
      component,
    }, 'filters');
  }
  return {
    isMobile,
    showFilters,
    mobileSearchFormConfig,
    filters,
    setFilters,
    filtersMobile
  }
}



export const useGetAnalyticBarColors = (
  depositCurrencies: string[],
  withdrawalCurrencies: string[],
  barColors: any,
) => {
  let barColorsAll: any = {}

  depositCurrencies.forEach((currency: string) => {
    barColorsAll[currency] = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`
  } )

  withdrawalCurrencies.forEach((currency: string) => {
    barColorsAll[currency] = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`
  } )

  barColorsAll = {...barColorsAll, ...barColors}

  return { barColorsAll }
}

export const useFEListSort = (data: any, sort: any) => {
  if (isEmpty(sort) || !data.length) {
    return data
  }
  const sortData = [...data]
  const {sortField, sortDir} = sort;
  
    if (typeof sortData[0][sortField] === 'string') {
      sortData.sort((a: any, b: any) => a[sortField].localeCompare(b[sortField]));
    } else if (typeof sortData[0][sortField] === 'number') {
      sortData.sort((a: any, b: any) => a[sortField] - b[sortField]);
    } 

    if (sortDir === 'DESC') {
      sortData.reverse();
    }
  
    return sortData;
}

export const parseFormData = (values: any) => {
  const resultData = new FormData();
  let startKey = "";

  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = parsDataKey === "" ? key : parsDataKey + `.${key}`;
      if (
        typeof values[key] === "object" &&
        !Array.isArray(values[key]) &&
        Object.prototype.toString.call(values[key]) !== "[object File]"
      ) {
        parseQ(values[key], resKey);
      } else if (typeof values[key] === "boolean") {
        if (!!values[key]) {
          resultData.append(resKey, "1");
        } else {
          resultData.append(resKey, "0");
        }
      } else if (Array.isArray(values[key])) {
        values[key].forEach((item: any, idx: any) => {
          if (
            typeof item === "object" &&
            !Array.isArray(item) &&
            Object.prototype.toString.call(item) !== "[object File]"
          ) {
            parseQ(item, resKey + `[${idx}]` + parsDataKey);
          } else {
            resultData.append(resKey + `[${idx}]`, item);
          }
        });
      } else {
        resultData.append(resKey, values[key]);
      }
    }
  };

  parseQ(values, startKey);
  
  return resultData;
};

export const useGetSaveFileOnDevice = () => {
  const saveFile = ({data, type, fileName} : { data: any, type: string,fileName: string }) => {
    const blob = new Blob([data], { type });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return { saveFile }
}


export const useGetErrorToaster = () => {
  const toastHandler = useToast();

  const showErrorToastMessage = (msg: string) =>
      toastHandler.addToast({
        caption: msg,
        kind: "error",
      }); 

  return { showErrorToastMessage }
}