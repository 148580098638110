import * as Yup from "yup";
import { TransType } from "system/translations/types";

export const createEditValidation = (_t: TransType, params: any) => {
  const createSchema = Yup.object().shape({
    merchantId: Yup.object().required(_t("required")),
    paymentGatewayCode: Yup.object().required(_t("required")),
  })

  const baseSchema = Yup.object().shape({
    rollingReserveAuto: Yup.boolean().required(_t("required")),
    rollingReserveReturnAuto: Yup.boolean().required(_t("required")),
    rollingReserveRate: Yup.number().nullable().min(0).when(['rollingReserveAuto', 'rollingReserveReturnAuto'], {
      is: (rollingReserveAuto: any, rollingReserveReturnAuto: any) => !!rollingReserveAuto || !!rollingReserveReturnAuto,
      then: (schema) => Yup.number().nullable().typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    rollingReservePeriod: Yup.number().nullable().min(0).when(['rollingReserveAuto', 'rollingReserveReturnAuto'], {
      is: (rollingReserveAuto: any, rollingReserveReturnAuto: any) => !!rollingReserveAuto || !!rollingReserveReturnAuto,
      then: (schema) => Yup.number().nullable().typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    rollingReserveStrategyValidFrom: Yup.string().required(_t("required")),
    
    onHoldAuto: Yup.boolean().required(_t("required")),
    onHoldReturnAuto: Yup.boolean().required(_t("required")),
    onHoldStrategyValidFrom: Yup.string().required(_t("required")),
    onHoldPeriod: Yup.number().nullable().min(0).when(['onHoldAuto', 'onHoldReturnAuto'], {
      is: (onHoldAuto: any, onHoldReturnAuto: any) => !!onHoldAuto || !!onHoldReturnAuto,
      then: (schema) => Yup.number().typeError(_t("must_be_a_number")).required(_t("required"))
    }),
    
    ratesApplication: Yup.string().required(_t("required")),
    rateCorrelation: Yup.number().nullable().typeError(_t("must_be_a_number")),
    ratesStrategyValidFrom: Yup.string().required(_t("required")),
    convertCurrency: Yup.string().nullable().when("activeConvertCurrency", {
      is: (activeConvertCurrency: any) => !!activeConvertCurrency,
      then: (schema) => Yup.string().nullable().required(_t("required"))
    }),
    parentPaymentGatewayCode: Yup.object().nullable().when("enableParentGatewayLinkage", {
      is: (enableParentGatewayLinkage: any) => !!enableParentGatewayLinkage,
      then: (schema) => Yup.object().nullable().required(_t("required"))
    })
  }, [['rollingReserveAuto', 'rollingReserveReturnAuto'],['onHoldAuto', 'onHoldReturnAuto']]);

  return params.isEdit ? baseSchema : createSchema.concat(baseSchema)
};
