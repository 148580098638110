import React from "react";
import { ArrowRight16 } from "components/atoms/IconsCreateFactory"
import { useTrans } from "system/translations/hooks";
import { StructuredListData } from "./types";
import { getPreparedData } from "system/helpers/helperFunctions";

const StructuredList: React.FC<StructuredListData> = ({data = {}}) => {
  const {_t} = useTrans()
  
   const preparedData = {
     "opening_available_balance": getPreparedData(data, "openingAvailableBalance"),
     "opening_total_balance": getPreparedData(data, "openingTotalBalance"),
     "available_balance": getPreparedData(data, "availableBalance"),
     "total_balance": getPreparedData(data, "totalBalance"),
     "on_hold": getPreparedData(data, "onHoldAmount"),
     "roling_reserve": getPreparedData(data,"rollingReserveAmount"),
   }
  return (
    <div className="structured-list">
      {Object.entries(preparedData).map((item: any) => {
      return (
      <><div className="structured-list--row">
          <div className="structured-list--row__title">{_t(item[0])}</div>
          <div className="structured-list--row__value">{item[1]}</div>
        </div><div className="structured-list--border"></div></>
      )
    })}
    </div>
  );
};

export default StructuredList;
