import { Add16, CloseOutline16, Copy16, Delete16, Edit16 } from "components/atoms/IconsCreateFactory"
import ButtonLink from "components/atoms/ButtonLink";
import Card from "components/atoms/Card";
import _ from "lodash";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useDeletePaymentOrchestrationItem, useDuplicatePaymentOrchestrationItem, useGetPaymentOrchestration, useUpdatePaymentOrchestrationItem } from "modules/routingRules/apiHooks";
import Table from "modules/table";
import { generatePath, useHistory } from "react-router-dom";
import { getUrl } from "system/helpers/helperFunctions";
import { useFilters } from "system/helpers/hooks";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import { useSearchFormConfig, useTableColumns } from "./constantHooks";
import { PaymentOrchestrationItemType } from "./types";


const RoutingRules = () => {
  const { _t } = useTrans()
  const columns = useTableColumns()
  const history = useHistory();
  const { mutate: onDelete } = useDeletePaymentOrchestrationItem();
  const { onConfirm } = useConfirmModal();
  const searchFormConfig = useSearchFormConfig()
  const { filters, setFilters } = useFilters({})
  const { data = [], isLoading } = useGetPaymentOrchestration(filters)
  const { mutate: onUpdateItem } = useUpdatePaymentOrchestrationItem()
  const { mutate: onDuplicateItem } = useDuplicatePaymentOrchestrationItem()

  const onDeleteWithConfirm = ({ id }: any) => {
    const ruleToDuplicate = data.find((rule: PaymentOrchestrationItemType) => rule.id === id)
    onConfirm({
      onOk: () => onDelete({ id }),
      bodyText: _t("delete_{ruleName}_for_{merchantName}_{paymentMethodName}",{
        ruleName: ruleToDuplicate?.ruleName || '',
        merchantName: ruleToDuplicate?.merchant.name || '',
        paymentMethodName: ruleToDuplicate?.paymentMethod || '',
      }),
      onOkText: _t("delete"),
      onOkKind: "danger",
      renderIcon: Delete16,
    });
  };

  const onFilterSubmit = (data: any) => {
    setFilters(data);
  }

  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.paymentOrchestration.routingRules.update, {
      id,
    });
    history.push(link, history.location);
  };

  const onActionUpdate = (data: any) => {
    !data?.active
      ? onConfirm({
        onOk: () => {
          onUpdateItem(data);
        },
        modalHeading: _t("deactivating_of_{rule}_header_text", { rule: data.ruleName }),
        bodyText: _t("deactivating_of_rule_body_text"),
        onOkText: _t("deactivate"),
        onOkKind: "danger",
        renderIcon: CloseOutline16,
        isShowScale: false,
      })
      : onUpdateItem(data);
  }

  const onDuplicateWithConfirm = ({ id }: any) => {
    const ruleToDuplicate = data.find((rule: PaymentOrchestrationItemType) => rule.id === id)
    onConfirm({ onOk: () => {
      onDuplicateItem(ruleToDuplicate)
    },
    bodyText: _t("duplicate_{ruleName}_for_{merchantName}_{paymentMethodName}", {
      ruleName: ruleToDuplicate?.ruleName || '',
      merchantName: ruleToDuplicate?.merchant.name || '',
      paymentMethodName: ruleToDuplicate?.paymentMethod || '',
    })
  });
  }; 

  const actionMenuItems = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.paymentOrchestration.routingRules.update, id),
      icon: <Edit16 />,
    },
    {
      title: _t("duplicate_rule"),
      onClick: onDuplicateWithConfirm,
      icon: <Copy16 />,
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];


  return (
    <Card 
      title={_t("routing_rules")}
      extra={
        <RoleWrapper accessTo={"ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION"}>
          <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.paymentOrchestration.routingRules.create}
              title={_t("create_rule_set")}
            />
          </RoleWrapper>
        }
      >
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          onSubmit: onFilterSubmit,
          initialValues: {},
        }}
      />
      <Table
        totalRecords={data?.length || 0}
        columns={columns}
        data={data || []}
        isPagination
        isLoading={isLoading}
        cellProps={{ actionMenuItems, onUpdate, onActionUpdate }}
      />
    </Card>

  )
}

export default RoutingRules