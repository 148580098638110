import { eachMonthOfInterval, format, getMonth, isBefore, isFirstDayOfMonth, isLastDayOfMonth } from "date-fns";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { MerchantBalanceDetailsPeriodData, MerchantsBalancePeriodsToCloseData, TransactionsType, UseModuleDataRes } from "./types";
import config from "system/config";
import { useGetPaymentGatewaysByMerchant } from "modules/merchantConfiguration/apiHooks";
import { useGetMerchantBalanceLastClosedPeriod } from "./apiHooks";
import { useModal } from "modules/modal/hooks";
import { useTrans } from "system/translations/hooks";
import { Download16, Checkmark16 } from "components/atoms/IconsCreateFactory"
import { Button } from "carbon-components-react";
import ConfirmModalButtons from "components/atoms/ConfirmModalButtons";
import NotClosedPeriod from "components/molecules/NotClosedPeriod";
import PeriodsToClose from "components/molecules/PeriodsToClose";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useGetMerchantsNameList } from "modules/merchants/apiHooks";

export const useModuleData = (merchantId: number, currency: string): UseModuleDataRes => {
  const { data: paymentGateways = [] } = useGetPaymentGatewaysByMerchant(merchantId);
  const { data: lastClosedPeriodData } = useGetMerchantBalanceLastClosedPeriod(merchantId, currency)  
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  
  const gatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "value",
    valueKey: "label",
  });
  
 const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });

  let lastDayOfClosedPeriod, firstDayOfOpenedPeriod

  if (lastClosedPeriodData?.dateTo) {
      lastDayOfClosedPeriod = new Date(lastClosedPeriodData?.dateTo)
      firstDayOfOpenedPeriod = new Date(lastDayOfClosedPeriod);
      firstDayOfOpenedPeriod.setDate(lastDayOfClosedPeriod.getDate() + 1);
  } else {
    firstDayOfOpenedPeriod = undefined
  }

  return { gatewaysOptions , firstDayOfOpenedPeriod , merchantsOptions};
};

export const useGetPeriodsToCloseData = (data: MerchantBalanceDetailsPeriodData | undefined) => {

  const getMonthPeriodsToClose = (dateFrom: string, dateTo: string) => {
    return isFirstDayOfMonth(new Date(dateFrom)) && isLastDayOfMonth( new Date(dateTo))
  }
  
  const periodsToCloseData: MerchantsBalancePeriodsToCloseData[] | undefined = data?.singlePeriodResponses
  .filter(item => !item.totalGatesBalance.closed && getMonthPeriodsToClose(item.totalGatesBalance.dateFrom, item.totalGatesBalance.dateTo) && item)
  .map(item => ({...item.totalGatesBalance, monthName: config.monthNames[getMonth(new Date(item.totalGatesBalance.dateFrom))]}))

  return periodsToCloseData || []

}

export const useGetActionsToClosePeriods = ({
  periodsToCloseData,
  firstDayOfOpenedPeriod,
  filters,
  setFilters,
  onClose,
}: {
  periodsToCloseData: MerchantsBalancePeriodsToCloseData[],
  firstDayOfOpenedPeriod: any
  filters: any ,
  setFilters: (val: any) => any,
  onClose: any,
}) => {
  const { showModal, hideModal } = useModal();
  const { _t } = useTrans();

  const openClosedPeriod = () => {
    const isEmptyDataToClose = periodsToCloseData.length > 0
    showModal(
      {
        isShowScale: false,
        component: PeriodsToClose,
        size: 'xs',
        smallModal: true,
        componentProps: {
          data: periodsToCloseData || []
        },
        footer: isEmptyDataToClose ? ConfirmModalButtons : Button,
        footerProps: isEmptyDataToClose 
          ? {
            onOkText: _t('close_{period}',{
              period: periodsToCloseData?.[0]?.monthName || ''
            }),
            onCancel: () => hideModal(),
            onCancelText: _t('cancel'),
            onOk: () => closePeriod(),
            className: 'small-modal-full-width-buttons'
          }
          : {
            onClick: () => hideModal(),
            kind: "tertiary",
            children: "Close"
          }
      });
  }

  const closePeriod = () => {
    const isItNotFirstPeriodToClose = firstDayOfOpenedPeriod && isBefore(firstDayOfOpenedPeriod, new Date(periodsToCloseData?.[0].dateFrom) )
    const data: string[] = isItNotFirstPeriodToClose
      ? eachMonthOfInterval({
        start: firstDayOfOpenedPeriod,
        end: new Date(periodsToCloseData?.[0].dateFrom)
      }).map((month: any) => config.monthNames[getMonth(month)])
      : [periodsToCloseData?.[0].monthName]
      isItNotFirstPeriodToClose && data.length >= 2 && data.pop()
    showModal(
      {
        isShowScale: false,
        component: NotClosedPeriod,
        size: 'xs',
        smallModal: true,
        componentProps: {
          data: data,
          titleText: isItNotFirstPeriodToClose ? _t('previously_unclosed_periods') : _t('are_you_sure_to_close_period'),
          secondaryText: isItNotFirstPeriodToClose ? _t("promt_to_close_previous_period") : _t('action_cannot_be_updone'),
          isClosePeriod: !isItNotFirstPeriodToClose,
        },
        footer: ConfirmModalButtons,
        footerProps: isItNotFirstPeriodToClose 
          ? {
              onOkText: _t('get_previous_periods'),
              onCancel: () => hideModal(),
              onOk: () => {
                setFilters({...filters, fromDate: format(new Date(firstDayOfOpenedPeriod), 'yyyy-MM-dd')})
                hideModal()
              },
              onOkKind: "primary",
              className: 'small-modal-full-width-buttons',
              renderIcon: Download16
            }
          : {
              onOkText: _t('close_period'),
              onCancel: () => {hideModal(); openClosedPeriod()},
              onOk: () => {onClose(); hideModal()},
              onOkKind: "primary",
              className: 'small-modal-full-width-buttons',
              renderIcon: Checkmark16
            }
      });
  }

  return { openClosedPeriod }
}

export const useGetHandleSubmitWithConfirmationPage =() => {
  const { onConfirm } = useConfirmModal()
  const { hideModal } = useModal();
  const { _t } = useTrans()

  const handleClick = (form: any) => {
    if (!form.isValid) {
      return 
    }
    const operationTypeValue: TransactionsType = form.values.operationType || 'balanceAdjustment'
    const  adjustmentTypeValue = operationTypeValue !== 'balanceAdjustment' 
      ? form.values.adjustmentType
      : form.initialValues.amount > 0
        ? 'receivedFromMerchant'
        : 'transferToMerchant'
    const transactionType = config.transactionTypesManualTransaction.find((item: any) => item.value === operationTypeValue)?.label
    const adjustmentType = config.adjustmentType[operationTypeValue].find((item: any) => item.value === adjustmentTypeValue)?.label
    const amountChangeText = +form.initialValues.amount !== +form.values.amount 
      ? _t('amount_from_{valueFrom}_to_{valueTo}', {
            valueFrom: `${form.initialValues.amount} ${form.initialValues.currency}`,
            valueTo: `${form.values.amount} ${form.values.currency}`
          })
      : ''
    const commentChangeText = form.initialValues.comment !== form.values.comment 
      ? _t('comment_from_{valueFrom}_to_{valueTo}', {
            valueFrom: form.initialValues.comment,
            valueTo: form.values.comment
          })
      : ''
    const isAreChangesToSave = !!amountChangeText || !!commentChangeText
    const bodyText = isAreChangesToSave 
    ? `${_t('do_you_want_make_changes_in')} ${transactionType} - ${adjustmentType} 
${amountChangeText}
${commentChangeText}`
    : _t('no_changes_to_save_try_again')
    
    onConfirm({
      onOk: () => isAreChangesToSave ? form.submitForm() : hideModal(),
      onOkKind: "primary",
      bodyText: bodyText
    })
  }

  return { handleClick }
}
