import React from "react";
import { useHistory } from "react-router-dom";
import {
  Add16,
  Edit16,
  TrashCan16,
} from "components/atoms/IconsCreateFactory"
import Card from "components/atoms/Card";
import ButtonLink from "components/atoms/ButtonLink";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { APP_ROUTES } from "system/router/constants";
import { useTrans } from "system/translations/hooks";
import { useFEFilter, useFilters } from "system/helpers/hooks";
import { useTransData } from "modules/translations/hooks";
import {
  useDeleteTranslations,
  useGetTranslations,
} from "modules/translations/apiHooks";
import { useConfirmModal } from "modules/modal/predefinedModals";
import {
  useSearchFormConfig,
  useTableColumns,
} from "modules/translations/constantHooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { getUrl } from "system/helpers/helperFunctions";


const Translations = () => {
  const { _t } = useTrans();
  const history = useHistory();
  const { onConfirm } = useConfirmModal();
  const { data, isLoading } = useGetTranslations();
  const { mutate: onDelete } = useDeleteTranslations();
  const { filters, setFilters } = useFilters({});
  const columns = useTableColumns(data?.[0]?.languages);
  const searchFormConfig = useSearchFormConfig({
    languages: data?.[0]?.languages,
  });
  const transData = useTransData(data);
  const filteredData = useFEFilter(transData, filters);
  const onFilterSubmit = async (data: any) => {
    setFilters({ [data.filterField]: data.key });
  };
  const onUpdate = ({ id, allData }: any) => {
    history.push(getUrl(APP_ROUTES.translations.update, id ), history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };
  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.translations.update, id),
      icon: <Edit16 /> 
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];

  const isMobile = window.matchMedia('(max-width: 671px)').matches;

  return (
    <Card
      title={_t("translations")}
      extra={
        <>
          {/*       <Button kind="tertiary" renderIcon={DocumentImport16}>
            {_t("import")}
          </Button>
          <Button kind="tertiary" renderIcon={DocumentExport16}>
            {_t("export_CSV")}
          </Button>*/}
          <RoleWrapper accessTo={"ROLE_MODIFY_TRANSLATIONS"}>
            {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.translations.create}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.translations.create}
              title={_t("create_translation")}
            />
        }
          </RoleWrapper>
        </>
      }
    >
      <FormBuilder
        formItemsConfig={searchFormConfig}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: { filterField: "key" },
        }}
        showSubmit={false}
      />
      <Table
        columns={columns}
        data={filteredData || []}
        totalRecords={filteredData.length}
        isPagination
        isLoading={isLoading}
        cellProps={{ actionMenuItems }}
      />
    </Card>
  );
};

export default Translations;
