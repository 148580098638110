import React from "react";
import { User20, ChevronDown16 } from "../IconsCreateFactory";
import {HeaderMenuContentProps} from "./types";

const HeaderMenuContent: React.FC<HeaderMenuContentProps> = ({
  name,
}) => {
        return <>
            <User20/>
            <span className={'header-menu-content--name'}>{name}</span>
            <div className={'header-menu-content--chevron'}>
                <ChevronDown16/>
            </div>
        </>
    }

export default HeaderMenuContent;
